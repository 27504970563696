// @flow

import React, {Component} from 'react';

import {Button} from 'react-foundation';

import TitledModal from '../general-components/titled-modal';
import Radio from '../general-components/radio';
import LabelledRawInput from '../labelled-input/labelled-raw-input';
import {checkEmail} from '../user/user-validate';
import siteSvc from './site-service';

import './transfer-site-modal.css';

import type {SiteType, SiteOrgType} from '../types';

type UIEventType = {
  target: {
    value: string
  }
};

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onSuccess: Function,
  site: SiteType,
  partner: ?SiteOrgType[]
};

type StateType = {
  currentStep: number,
  emailToInvite: string,
  keepCurrentRelationships: boolean,
  emailInvalid: boolean,
  emailFocusCount: number,
  canSubmit: boolean,
  errors: string[],
  busy: boolean
};

const STARTING_STATE = {
  currentStep: 1,
  emailToInvite: '',
  keepCurrentRelationships: false,
  emailInvalid: false,
  emailFocusCount: 0,
  canSubmit: false,
  errors: [],
  busy: false
};

export default class TransferSiteModal extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      ...STARTING_STATE
    };
  }

  onKeepRelationshipsChanged = (value: string) => {
    this.setState({
      keepCurrentRelationships: value === 'Yes'
    });
  };

  onEmailBlur = () => {
    this.setState({
      emailFocusCount: this.state.emailFocusCount + 1
    });
  };

  onEmailChanged = (event: UIEventType) => {
    const email = event.target.value;

    const errors = checkEmail(email);

    this.setState({
      emailToInvite: email,
      errors,
      emailInvalid: errors.length > 0,
      canSubmit: errors.length === 0 && email.length > 0
    });
  };

  doCancel = () => {
    const {onRequestClose} = this.props;
    this.setState({
      ...STARTING_STATE
    });
    onRequestClose();
  };

  goToStep = (step: number) => {
    this.setState({
      currentStep: step
    });
  };

  doTransfer = async () => {
    const {onSuccess, site} = this.props;
    const {emailToInvite, keepCurrentRelationships} = this.state;

    this.setState({
      busy: true
    });

    const result = await siteSvc.transferSiteOwnership(
      site.id,
      emailToInvite,
      keepCurrentRelationships
    );

    if (result.success) {
      this.setState({
        ...STARTING_STATE
      });
      onSuccess();
    } else {
      this.setState({
        busy: false,
        currentStep: 1,
        errors: [result.message],
        emailInvalid: true,
        canSubmit: false
      });
    }
  };

  render() {
    const {isOpen, partner, owner, site} = this.props;
    const {
      currentStep,
      emailToInvite,
      emailInvalid,
      emailFocusCount,
      errors,
      busy,
      keepCurrentRelationships,
      canSubmit
    } = this.state;

    const siteName = site && site.name ? site.name : 'UNKNOWN';
    const siteOwnerName = owner && owner.name ? owner.name : 'UNKNOWN';

    const partnersListTodisplay = partner.map((org)=>{
      return <li>{org.name}</li>;
    });

    return (
      <TitledModal
        title="Transfer Site & System Ownership"
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.doCancel}
        className="transfer-site-modal minor"
      >
        {currentStep === 1 ? (
          <div className="grid-x grid-padding-x step-1">
            <div className="cell small-12 instructions">
              <div className="step-description">
                Enter the email address of an Admin at the organization you are transferring ownership of the <strong>{siteName}</strong> site and systems to:
              </div>
            </div>
            <div className="cell small-12 input-cell">
              <LabelledRawInput
                label="EMAIL"
                autoFocus
                type="text"
                value={emailToInvite}
                onChange={this.onEmailChanged}
                onBlur={this.onEmailBlur}
                disabled={busy}
                isInvalid={emailFocusCount >= 1 && emailInvalid}
                maxLength={50}
              />
            </div>
            <div className="cell small-12 error-box">
              {emailFocusCount >= 1 ?
                errors.map((message, i) => (
                  <div key={i} className="text-alert">
                    {message}
                  </div>
                )) :
                null}
            </div>
            {partner.length > 0 ? (
              <div className="cell small-12 keep-access-cell">
                <div>
                  <div className="keep-access-message">
                    Do you want site's access partners to continue to have access to the site?
                    <div className="display-partner-list">
                      <strong>
                        <ul>
                          {partnersListTodisplay}
                        </ul>
                      </strong>
                    </div>
                  </div>
                  <fieldset className="keep-access-radio">
                    <Radio
                      label="Keep access partners."
                      name="keepRelationships"
                      value="Yes"
                      className="keep-rel-radio keep-rel-yes"
                      checked={keepCurrentRelationships}
                      onChange={this.onKeepRelationshipsChanged}
                    />
                    <Radio
                      label="Remove access partners."
                      name="keepRelationships"
                      value="No"
                      className="keep-rel-radio keep-rel-no"
                      checked={!keepCurrentRelationships}
                      onChange={this.onKeepRelationshipsChanged}
                    />
                  </fieldset>
                </div>              
            </div>) : null}
            <div className="cell small-12 divider-cell" />
            <div className="cell small-6 medium-5 medium-offset-1">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.doCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-6 medium-5">
              <Button
                disabled={!canSubmit || busy}
                isExpanded
                isHollow
                className="text-uppercase next-btn"
                color="black"
                onClick={this.goToStep.bind(this, 2)}
              >
                Next
              </Button>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div className="grid-x grid-padding-x step-2">
            <div className="cell small-12 instructions">
              <p>
                 Are you sure you want to transfer ownership of <strong>{siteName}</strong> and its systems from{' '}
                 <strong>{siteOwnerName}</strong> to <strong>{emailToInvite}</strong>?
              </p>
            </div>
            <div className="cell small-12 instructions">
              <p>
              <strong>{siteOwnerName}</strong> will no longer own <strong>{siteName}</strong> or any of its systems.
              </p>
            </div>
            <div className="cell small-12 instructions">
              {partner.length > 0 ? (
                keepCurrentRelationships ? (
                  <p>
                    <strong>{emailToInvite}</strong> will keep <strong>{siteName}</strong>’s current access partners
                  </p>
                ) : (
                  <p>
                    <strong>{emailToInvite}</strong> will not keep <strong>{siteName}</strong>’s current access partners
                  </p>
                )
              ) : null}
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-4">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.doCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-4">
              <Button
                isExpanded
                isHollow
                disabled={busy}
                className="text-uppercase back-btn"
                color="black"
                onClick={this.goToStep.bind(this, 1)}
              >
                Back
              </Button>
            </div>
            <div className="cell small-4">
              <Button
                disabled={!canSubmit || busy}
                isExpanded
                isHollow
                className="text-uppercase ok-btn"
                color="black"
                onClick={this.doTransfer}
              >
                Transfer
              </Button>
            </div>
          </div>
        ) : (
          <div>We seem to have encountered an error</div>
        )}
      </TitledModal>
    );
  }
}

// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';
import {Button} from 'react-foundation';

import TitledModal from '../general-components/titled-modal';
import LabelledInput from '../labelled-input/labelled-input';

import siteSvc from './site-service';

import './add-site-step1-modal.css';

type PropsType = {
  isOpen: boolean,
  nameErrors: string[],
  onRequestClose: Function,
  onSuccess: Function,
  onNameError: Function,
  newSiteName: string
};

type StateType = {
  busy: boolean
};

export class AddSiteStep1Modal extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      busy: false
    };
  }

  checkName = async () => {
    const {newSiteName, onSuccess, onNameError} = this.props;
    this.setState({
      busy: true
    });
    const result = await siteSvc.checkSiteName(newSiteName);

    this.setState({
      busy: false
    });

    if (result.valid) {
      onSuccess();
    } else {
      onNameError(result.message);
    }
  };

  render() {
    const {isOpen, onRequestClose, nameErrors, newSiteName} = this.props;
    const {busy} = this.state;

    return (
      <TitledModal
        title="Add New Site"
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={onRequestClose}
        className="add-site-step-1-modal minor"
      >
        <div className="grid-x">
          <div className="cell small-12 instructions">
            <div className="text-uppercase">
              <strong>1. Step 1</strong>
            </div>
            <div>Enter a name for the new Site:</div>
          </div>
          <div className="cell small-12 inputs">
            <LabelledInput
              label="SITE NAME"
              path="forms.addSite.name"
              disabled={busy}
              isInvalid={nameErrors.length > 0}
              maxLength={30}
              autoFocus
            />
          </div>
          <div className="cell small-12 error-box">
            {nameErrors.map(error => (
              <div key={error} className="text-alert">
                {error}
              </div>
            ))}
          </div>
        </div>
        <div className="cell small-12 buttons">
          <div className="grid-x grid-padding-x">
            <div className="cell small-6 medium-4 medium-offset-2">
              <Button
                isHollow
                isExpanded
                className="add-site-step-1-cancel text-uppercase"
                onClick={onRequestClose}
                disabled={busy}
                color="black"
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-6 medium-4">
              <Button
                isHollow
                isExpanded
                className="add-site-step-1-cancel text-uppercase"
                onClick={this.checkName}
                disabled={!newSiteName || busy}
                color="black"
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </TitledModal>
    );
  }
}

export default watch(AddSiteStep1Modal, {
  newSiteName: 'forms.addSite.name'
});

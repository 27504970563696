// @flow

import React, {Component} from 'react';
import {dispatchSet, getPath} from 'redux-easy';

import {buildClassName} from '../../util/component-util';

import './button-switch.css';

export type ButtonSwitchOptionType = {
  value: number | string,
  display: string
};

type PropsType = {
  className?: ?string,
  disabled?: ?boolean,
  path: string,
  onChange?: Function,
  options: ButtonSwitchOptionType[]
};

export default class ButtonSwitch extends Component<PropsType> {
  handleChange = (optionClicked: ButtonSwitchOptionType) => {
    const {disabled, path, onChange} = this.props;
    const currentValue = getPath(path);

    if (!disabled && currentValue !== optionClicked.value) {
      dispatchSet(path, optionClicked.value);
      if (onChange) {
        setTimeout(onChange);
      }
    }
  };

  render() {
    const {className, disabled, path, options} = this.props;
    const value = getPath(path);
    const selectedOption = options.find(option => option.value === value);

    return (
      <div
        className={buildClassName(
          'button-switch',
          className,
          disabled ? 'is-disabled' : null
        )}
      >
        {options.map(option => (
          <div
            key={option.value}
            className={buildClassName(
              'button-switch-option',
              option === selectedOption ? 'selected' : 'not-selected'
            )}
            onClick={this.handleChange.bind(this, option)}
          >
            {option.display}
          </div>
        ))}
      </div>
    );
  }
}

// @flow

const PASSWORD_CHECKS = [
  {
    check: input => input.length < 8,
    message: 'Password must be at least 8 characters'
  },
  {
    check: input => !input.match(/[A-Z]/),
    message: 'Password must include at least one uppercase character'
  },
  {
    check: input => !input.match(/[a-z]/),
    message: 'Password must include at least one lowercase character'
  },
  {
    check: input => !input.match(/\d/),
    message: 'Password must include at least one number'
  },
  {
    check: input => !input.match(/[^A-Za-z\d\s]/),
    message: 'Password must include at least one special character'
  }
];

export function checkEmail(email: ?string): string[] {
  const errorResult = ['Invalid Email Address'];

  if (!email) {
    return [];
  }

  const [name, rest] = email.split('@');

  if (!name || !rest) {
    return errorResult;
  }

  const lastDot = rest.lastIndexOf('.');

  if (lastDot < 0) {
    return errorResult;
  }

  const domain = rest.substr(0, lastDot);
  const extension = rest.substr(lastDot + 1);

  if (!domain || !extension) {
    return errorResult;
  }

  const nameExp = /^\w+([.-]?\w+)*$/;
  const domainExp = /^\w+([.-]?\w+)*$/;
  const extensionExp = /^\w{2,}$/;

  if (
    !nameExp.test(name) ||
    !domainExp.test(domain) ||
    !extensionExp.test(extension)
  ) {
    return errorResult;
  }

  return [];
}

export function checkPassword(password: string): string[] {
  if (password === '') return [];

  if (typeof password === 'string') {
    return PASSWORD_CHECKS.filter(rule => rule.check(password)).map(
      rule => rule.message
    );
  }

  return [`${typeof password} is not an expected input type.`];
}

export function cleanPhone(phone: ?string): ?string {
  const badChars = /([A-Za-z]+)|([%$*&^]+)/g;

  if (!phone || !badChars.test(phone)) {
    return phone;
  }

  return phone.replace(badChars, '');
}

export function checkPhone(phone: ?string): string[] {
  const phoneRegex = /\+1\s\(\d{3}\)\s\d{3}-\d{4}/;

  if (!phone || phoneRegex.test(phone)) {
    return [];
  }

  return ['Phone number is not valid'];
}

// @flow

import React, {Component} from 'react';
import {dispatch, dispatchSet, watch} from 'redux-easy';
import {Button} from 'react-foundation';

import TitledModal from '../general-components/titled-modal';
import LabelledInput from '../labelled-input/labelled-input';
import {checkPassword} from './user-validate';
import * as userSvc from './user-service';

import initialState from '../initial-state';

import './change-pw-modal.css';

import type {ChangePasswordFormType, UserType} from '../types';

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  changePassword: ChangePasswordFormType,
  user: UserType
};

type MyStateType = {
  errors: string[],
  pwsMatch: boolean,
  currentValid: boolean,
  valid: boolean,
  busy: boolean
};

const INITIAL_STATE = {
  errors: [],
  currentValid: false,
  pwsMatch: true,
  valid: false,
  busy: false
};

export class ChangePasswordModal extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      ...INITIAL_STATE
    };
  }

  validate = (inputs: ChangePasswordFormType) => {
    const errors = checkPassword(inputs.password);
    const pwsMatch = inputs.password === inputs.confirmPassword;
    const currentValid = Boolean(inputs.currentPassword);
    const valid = errors.length === 0;

    this.setState({
      errors,
      pwsMatch,
      valid,
      currentValid
    });
  };

  clearAndClose = () => {
    const {onRequestClose} = this.props;
    this.setState({
      ...INITIAL_STATE
    });
    dispatchSet('forms.changePassword', initialState.forms.changePassword);
    onRequestClose();
  };

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    this.validate(nextProps.changePassword);
  }

  componentDidMount() {
    if (this.props.changePassword.password) {
      this.validate(this.props.changePassword);
    }
  }

  onChangePw = async () => {
    const {currentPassword, password} = this.props.changePassword;
    const {user} = this.props;

    this.setState({
      busy: true
    });

    const result = await userSvc.changeUserPassword(
      user.id,
      currentPassword,
      password
    );
    this.setState({
      busy: false
    });
    if (result.successful) {
      dispatch('addToast', {
        type: 'success',
        title: 'Success!',
        message: 'Your password has been updated.'
      });

      dispatch('clearChangePassword');
      this.clearAndClose();
    } else {
      this.setState({
        errors: [result.message],
        currentValid: false
      });
    }
  };

  render() {
    const {errors, pwsMatch, valid, currentValid, busy} = this.state;
    const {isOpen} = this.props;

    const labelWidth = 151;

    return (
      <TitledModal
        title="Change Password"
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.clearAndClose}
        className="change-pw-modal minor"
        contentClassName="change-pw-modal-content grid-x"
      >
        <div className="cell small-12 instructions">
          <p>
            Min. 8 characters, at least one lowercase and one uppercase letter,
            at least one number and at least one special character.
          </p>
          <p>
            If you don’t remember your current password contact Customer
            Support.
          </p>
        </div>
        <div className="cell small-12">
          <LabelledInput
            autoFocus
            className="curr-pw-input"
            label="CURRENT PASSWORD"
            type="password"
            disabled={busy}
            isInvalid={!currentValid}
            path="forms.changePassword.currentPassword"
            labelWidth={labelWidth}
            maxLength={100}
          />
        </div>
        <div className="cell small-12">
          <LabelledInput
            label="NEW PASSWORD"
            type="password"
            disabled={busy}
            isInvalid={!valid}
            path="forms.changePassword.password"
            labelWidth={labelWidth}
            maxLength={100}
          />
        </div>
        <div className="cell small-12 text-left">
          <LabelledInput
            label="CONFIRM NEW PASSWORD"
            type="password"
            disabled={busy}
            isInvalid={!pwsMatch}
            path="forms.changePassword.confirmPassword"
            labelWidth={labelWidth}
            maxLength={100}
          />
        </div>
        <div className="cell small-12 error-box text-alert">
          {errors.map(error => (
            <div key={error}>{error}</div>
          ))}
        </div>
        <div className="cell small-12 btn-container">
          <div className="grid-x grid-padding-x">
            <div className="cell medium-4 medium-offset-2 small-6">
              <Button
                disabled={busy}
                onClick={this.clearAndClose}
                color="black"
                isExpanded
                isHollow
                className="text-uppercase pw-cancel-btn"
              >
                Cancel
              </Button>
            </div>
            <div className="cell medium-4 small-6">
              <Button
                disabled={busy || !valid || !currentValid || !pwsMatch}
                onClick={this.onChangePw}
                color="black"
                isExpanded
                isHollow
                className="text-uppercase pw-ok-btn"
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </TitledModal>
    );
  }
}

export default watch(ChangePasswordModal, {
  changePassword: 'forms.changePassword',
  user: 'user'
});

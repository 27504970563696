// @flow

import React, {Component} from 'react';

import {Callout} from 'react-foundation';

import {buildClassName} from '../util/component-util';

import './collapsible-grid-container.css';

type PropsType = {
  className?: ?string
};

export default class CollapsibleGridContainer extends Component<PropsType> {
  render() {
    // ESLint and Flow really don't like me using the 'children'
    // property.
    /* eslint-disable react/prop-types */
    // $FlowFixMe -- props.children is provided by React
    const {children} = this.props;

    const {className} = this.props;

    const myClassName = buildClassName('collapsible-grid-container', className);

    return <Callout className={myClassName}>{children}</Callout>;
  }
}

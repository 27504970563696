// @flow

import React, {Component} from 'react';

import {buildClassName} from '../util/component-util';

import './titled-panel-section-header.css';

type PropsType = {
  title: string,
  titleClassName?: ?string,
  className?: ?string
};

export default class TitledPanelSectionHeader extends Component<PropsType> {
  render() {
    const {title, titleClassName, className} = this.props;

    return (
      <div className={buildClassName('titled-panel-section-header', className)}>
        <span
          className={buildClassName(
            'titled-panel-section-header-title',
            'text-uppercase',
            titleClassName
          )}
        >
          {title}
        </span>
      </div>
    );
  }
}

// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import exclamationIcon from '@fortawesome/fontawesome-free-solid/faExclamationTriangle';

import './admin-bar.css';

import type {SessionInfoType, UserType} from '../types';

type PropsType = {
  sessionInfo: SessionInfoType,
  user: UserType
};

class AdminBar extends Component<PropsType> {
  render() {
    const {user} = this.props;

    const orgName = user.organization ? user.organization.name : '';

    const {
      userLoggedIn,
      userIsOrgAdmin,
      userHasTempOrgRelationship
    } = this.props.sessionInfo;

    const shouldShow =
      userLoggedIn && userIsOrgAdmin && userHasTempOrgRelationship;

    return shouldShow ? (
      <div className="AdminBar">
        <div className="grid-x">
          <div className="cell small-12">
            <div className="admin-message">
              <FontAwesomeIcon icon={exclamationIcon} />{' '}
              <span>
                <strong>WARNING:</strong> You are temporarily acting as an
                administrator for <strong>{orgName}</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default watch(AdminBar, {
  sessionInfo: '',
  user: ''
});

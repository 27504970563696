// @flow

import React, { Component } from 'react';
import { dispatch, watch } from 'redux-easy';
import { Button } from 'react-foundation';

import { checkEmail, checkPhone } from './user-validate';
import ButtonSwitch from '../general-components/controls/button-switch';
import LabelledCell from '../general-components/labelled-cell';
import GeneralPage from '../general-components/general-page';
import TitledPanel from '../general-components/titled-panel';
import YesOrNo from '../general-components/yes-or-no';
import UserInfoPanel from './user-info-panel';
import UserRolePanel from './user-role-panel';
import ChangePasswordModal from './change-pw-modal';
import UserNotificationDelayPreferencePanel from './user-notification-delay-preferences-panel';
import UserEmailPreferencePanel from './user-email-preferences-panel';
import UserSmsPreferencePanel from './user-sms-preferences-panel';
import * as orgService from '../organization/organization-service';
import * as userService from './user-service';

import { changeRoute } from '../route';

import './edit-profile.css';

import type {
  EditOwnAccountType,
    UserOrgType,
    UserType,
    UserPreferencesType
} from '../types';

type PropsType = {
  hasOrg: boolean,
  isOrgAdmin: boolean,
  user: UserType,
  userOrg: UserOrgType,
  currentData: EditOwnAccountType,
  userPreferences: UserPreferencesType
};

type MyStateType = {
  busy: boolean,
  isValid: boolean,
  passwordModalOpen: boolean,
  leaveOrgConfirmOpen: boolean,
  deleteUserOpen: Boolean,
  emailErrors: string[],
  firstNameErrors: string[],
  lastNameErrors: string[],
  phoneErrors: string[]
};

const TEMP_OPTIONS = [
  {
    value: 'C',
    display: 'ºC'
  },
  {
    value: 'F',
    display: 'ºF'
  }
];

export class EditProfile extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      busy: false,
      isValid: true,
      passwordModalOpen: false,
      leaveOrgConfirmOpen: false,
      deleteUserOpen: false,
      preferencesChanged: false,
      emailErrors: [],
      firstNameErrors: [],
      lastNameErrors: [],
      phoneErrors: []
    };
  }

  async UNSAFE_componentWillMount() {
    await userService.updateUser(this.props.user.id);
  }

  onDelete = async () => {
    this.setState({
      deleteUserOpen: false,
      busy: true
    })
    await userService.deleteUser(this.props.user.id);
    this.setState({
      busy: false
    })
  };

  onLeaveOrg = async () => {
    const { userOrg, user } = this.props;

    this.setState({
      leaveOrgConfirmOpen: false
    });

    dispatch('clearCreateOrg');

    await orgService.removeUserFromOrg(userOrg.id, user.id);
    await userService.updateUser(user.id);
    changeRoute('create-org');
  };

  togglePasswordModal = () => {
    this.setState({
      passwordModalOpen: !this.state.passwordModalOpen
    });
  };

  toggleDeleteConfirm = () => {
    this.setState({
      deleteUserOpen: !this.state.deleteUserOpen
    });
  };

  toggleLeaveOrgConfirm = () => {
    this.setState({
      leaveOrgConfirmOpen: !this.state.leaveOrgConfirmOpen
    });
  };

  validate = (current: EditOwnAccountType) => {
    const newBase = current.base;

    const firstNameErrors = !newBase.firstName ?
      ['First name is required'] :
      [];
    const lastNameErrors = !newBase.lastName ? ['Last name is required'] : [];

    const emailErrors = checkEmail(current.base.email);
    if (!current.base.email) {
      emailErrors.push('Email is required');
    }

    const phoneErrors = !newBase.phone ?
      ['Phone is required'] :
      checkPhone(newBase.phone);

    const isValid =
      emailErrors.length === 0 &&
      firstNameErrors.length === 0 &&
      lastNameErrors.length === 0 &&
      phoneErrors.length === 0;

    this.setState({
      emailErrors,
      isValid,
      firstNameErrors,
      lastNameErrors,
      phoneErrors
    });
  };

  componentDidMount() {
    const { currentData } = this.props;
    this.validate(currentData);
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    const { currentData } = nextProps;
    this.validate(currentData);
  }

  onLogout = async () => {
    await userService.logout();
  };

  savePreferences = async () => {
    const { user, userPreferences } = this.props;
    await userService.saveUserPreferences(user, userPreferences);
    await userService.updateUser(user.id);
  };

  saveSmsPreferences = async (isSmsEnabled) => {
    const { user, userPreferences } = this.props;
    const userSmsPreferences = {...userPreferences, notifications: {...userPreferences.notifications, sms: {...userPreferences.notifications.sms, enabled: isSmsEnabled}}};
    await userService.saveUserPreferences(user, userSmsPreferences);
    await userService.updateUser(user.id);
  };

  saveEmail = async () => {
    const { user, currentData } = this.props;
    await userService.saveUserEmail(user.id, currentData.base.email);
    await userService.updateUser(user.id);
  };

  saveRoles = async () => {
    const { user, userOrg, currentData } = this.props;
    const orgData = currentData.organization;
    const roles = [];
    if (orgData.isSpectator) {
      roles.push('Spectator');
    }
    if (orgData.isServicer) {
      roles.push('Servicer');
    }
    if (orgData.isAdmin) {
      roles.push('Administrator');
    }

    await orgService.updateUserRoles(userOrg.id, user.id, roles);
    await userService.updateUser(user.id);
  };

  saveBaseInformation = async () => {
    const { user, currentData } = this.props;
    const { isValid } = this.state;
    if (isValid) {
      await userService.updateUserProfileInfo(user.id, currentData.base);

      await userService.updateUser(user.id);
    }
  };

  render() {
    const { user, userOrg, hasOrg, isOrgAdmin } = this.props;
    const {
      busy,
      emailErrors,
      firstNameErrors,
      lastNameErrors,
      phoneErrors,
      passwordModalOpen,
      leaveOrgConfirmOpen,
      deleteUserOpen
    } = this.state;

    const orgName = userOrg ? userOrg.name : '';

    return (
      <GeneralPage
        title="Your Profile"
        className="edit-profile"
        contentClassName="edit-profile-content grid-x"
      >
        <div className="cell small-12 name-header">
          {user.firstName} {user.lastName}
        </div>
        {hasOrg ? (
          <div className="cell small-12 org-name-header">{userOrg.name}</div>
        ) : null}
        <div className="cell small-12 button-grid">
          <div className="grid-x grid-padding-x">
            <div className="cell small-12 medium-4 large-2 medium-offset-6 ">
              {hasOrg ? (
                <Button
                  className="leave-org-btn text-uppercase"
                  onClick={this.toggleLeaveOrgConfirm}
                  isHollow
                  isExpanded
                  disabled={busy}
                >
                  Leave Company
                </Button>
              ) : null}
            </div>
            <div className="cell small-12 medium-4 large-2 ">
              <Button
                className="leave-org-btn text-uppercase"
                onClick={this.toggleDeleteConfirm}
                isHollow
                isExpanded
                disabled={busy}
              >
                Delete My Account
              </Button>
            </div>
            <div className="cell small-12 medium-4 large-2">
              <Button
                className="logout-btn text-uppercase"
                onClick={this.onLogout}
                isHollow
                isExpanded
                disabled={busy}
              >
                Log Out
              </Button>
            </div>
          </div>
        </div>
        <div className="cell small-12 profile-panel">
          <UserInfoPanel
            disabled={busy}
            basePath="forms.editProfile.base"
            emailErrors={emailErrors}
            firstNameErrors={firstNameErrors}
            lastNameErrors={lastNameErrors}
            phoneErrors={phoneErrors}
            onChange={this.saveBaseInformation}
            onEmailChange={this.saveEmail}
          />
        </div>
        <div className="cell small-12 profile-panel">
          <UserRolePanel
            disabled={busy || !isOrgAdmin}
            basePath="forms.editProfile.organization"
            onChange={this.saveRoles}
          />
        </div>
        <div className="cell small-12 profile-panel">
          <TitledPanel
            title="Display Preferences"
            className="user-display-panel"
            contentClassName="grid-x grid-padding-x"
          >
            <div className="cell small-12">
              <LabelledCell label="Temperature Units">
                <ButtonSwitch
                  className="user-temp-units-switch"
                  disabled={busy}
                  path="userPreferences.temperatureDisplayUnit"
                  onChange={this.savePreferences}
                  options={TEMP_OPTIONS}
                />
              </LabelledCell>
            </div>
          </TitledPanel>
        </div>
        <div className="cell small-12 profile-panel">
          <UserEmailPreferencePanel
            disabled={busy}
            onChange={this.savePreferences}
          />
        </div>
        <div className="cell small-12 profile-panel">
          <UserSmsPreferencePanel
            disabled={busy}
            changePhoneSuccess={this.savePreferences}
            onChange={this.savePreferences}
            onToggle={this.saveSmsPreferences}
          />
        </div>
        <div className="cell small-12 profile-panel">
          <UserNotificationDelayPreferencePanel
            onChange={this.savePreferences}
          />
        </div>
        <div className="cell small-12 profile-panel">
          <TitledPanel
            title="Password"
            className="user-pw-panel"
            contentClassName="grid-x grid-x-padding"
          >
            <div className="cell small-12">
              <Button
                className="change-pw-btn text-uppercase"
                onClick={this.togglePasswordModal}
                isHollow
                disabled={busy}
                color="little-blue"
              >
                Change
              </Button>
            </div>
          </TitledPanel>
        </div>
        <ChangePasswordModal
          isOpen={passwordModalOpen}
          onRequestClose={this.togglePasswordModal}
        />
        <YesOrNo
          isOpen={leaveOrgConfirmOpen}
          title="Leave Company"
          message={
            <React.Fragment>
              <strong>Are you sure you want to leave {orgName}? You will lose access to your company's systems.</strong> This action cannot be undone.
            </React.Fragment>
          }
          yesText={`Leave`}
          noText="Cancel"
          onYes={this.onLeaveOrg}
          onNo={this.toggleLeaveOrgConfirm}
          className="leave-org-confirm"
        />
        <YesOrNo
          isOpen={deleteUserOpen}
          title="Delete Your Account"
          message={`Are you sure you want to delete your account? You will lose access to your company's systems.  This action cannot be undone.`}
          yesText="Delete"
          noText="Cancel"
          onYes={this.onDelete}
          onNo={this.toggleDeleteConfirm}
          className="delete-confirm"
        />
      </GeneralPage>
    );
  }
}

export default watch(EditProfile, {
  currentData: 'forms.editProfile',
  hasOrg: 'sessionInfo.userHasOrg',
  isOrgAdmin: 'sessionInfo.userIsOrgAdmin',
  user: '',
  userOrg: 'user.organization',
  userPreferences: ''
});

// @flow

import React, {Component} from 'react';

import {Button} from 'react-foundation';

import TitledModal from '../general-components/titled-modal';
import Radio from '../general-components/radio';
import LabelledRawInput from '../labelled-input/labelled-raw-input';
import {checkEmail} from '../user/user-validate';
import sysSvc from './system-service';

import './transfer-owner-modal.css';

import type {SystemOrgRelType} from '../types';

type UIEventType = {
  target: {
    value: string
  }
};

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onTransferSuccess: Function,
  systemId: string,
  systemName?: ?string,
  partner: ?SystemOrgRelType
};

type StateType = {
  currentStep: number,
  emailToInvite: string,
  emailFocusCount: number,
  keepCurrentRelationships: boolean,
  emailInvalid: boolean,
  canSubmit: boolean,
  errors: string[],
  busy: boolean
};

const STARTING_STATE = {
  currentStep: 1,
  emailToInvite: '',
  emailFocusCount: 0,
  keepCurrentRelationships: false,
  emailInvalid: false,
  canSubmit: false,
  errors: [],
  busy: false
};

class TransferOwnerModal extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      ...STARTING_STATE
    };
  }

  clearAndClose = () => {
    const {onRequestClose} = this.props;
    this.setState({
      ...STARTING_STATE
    });
    onRequestClose();
  };

  onKeepRelationshipsChanged = (value: string) => {
    this.setState({
      keepCurrentRelationships: value === 'Yes'
    });
  };

  onEmailChanged = (event: UIEventType) => {
    const email = event.target.value;

    const errors = checkEmail(email);

    this.setState({
      emailToInvite: email,
      errors,
      emailInvalid: errors.length > 0,
      canSubmit: errors.length === 0 && email.length > 0
    });
  };

  onEmailBlur = () => {
    this.setState({
      emailFocusCount: this.state.emailFocusCount + 1
    });
  };

  goToStep = (step: number) => {
    this.setState({
      currentStep: step
    });
  };

  doTransfer = async () => {
    const {onTransferSuccess, systemId} = this.props;
    const {emailToInvite, keepCurrentRelationships} = this.state;

    this.setState({
      busy: true
    });

    const result = await sysSvc.inviteOrgToSystem(
      systemId,
      emailToInvite,
      'Owner',
      keepCurrentRelationships
    );

    if (result.success) {
      this.setState({
        ...STARTING_STATE
      });
      onTransferSuccess();
    } else {
      this.setState({
        busy: false,
        currentStep: 1,
        errors: [result.message],
        emailInvalid: true,
        canSubmit: false
      });
    }
  };

  render() {
    const {isOpen, partner, owner, systemName} = this.props;
    const {
      currentStep,
      emailToInvite,
      emailInvalid,
      errors,
      busy,
      keepCurrentRelationships,
      canSubmit,
      emailFocusCount
    } = this.state;

    const displaySysName = systemName || 'No System Name';
    const systemOwnerName = owner && owner.name ? owner.name : 'UNKNOWN';

     
const partnersListTodisplay = partner.map((org)=>{
  return <li>{org.name}</li>;
});

    return (
      <TitledModal
        title="Transfer System Ownership"
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.clearAndClose}
        className="transfer-owner-modal minor"
      >
        {currentStep === 1 ? (
          <div className="grid-x grid-padding-x step-1">
            <div className="cell small-12 instructions">
              <div className="step-description">
              Enter the email address of an Admin at the organization you are transferring ownership of system <strong>{systemName}</strong> to:
              </div>
            </div>
            <div className="cell small-12 input-cell">
              <LabelledRawInput
                label="EMAIL"
                autoFocus
                className="email-input email-to-invite"
                type="text"
                value={emailToInvite}
                onChange={this.onEmailChanged}
                onBlur={this.onEmailBlur}
                disabled={busy}
                isInvalid={emailFocusCount >= 1 && emailInvalid}
                maxLength={50}
              />
            </div>
            <div className="cell small-12 error-box">
              {emailFocusCount >= 1 ?
                errors.map((message, i) => (
                  <div key={i} className="text-alert">
                    {message}
                  </div>
                )) :
                null}
            </div>
            {partner.length > 0 ? (
             <div className="cell small-12 keep-access-cell">
                <div className="keep-access-message">
                  Do you want system's access partners to continue to have access to the system?
                  <div className="display-partner-list">
                    <strong>
                      <ul>
                        {partnersListTodisplay}
                      </ul>
                    </strong>
                  </div>
                </div>
                <fieldset className="keep-access-radio">
                  <Radio
                    label="Keep access partners."
                    name="keepRelationships"
                    value="Yes"
                    className="keep-rel-radio"
                    inputClassName="keep-rel-yes"
                    checked={keepCurrentRelationships}
                    onChange={this.onKeepRelationshipsChanged}
                  />
                  <Radio
                    label="Remove access partners."
                    name="keepRelationships"
                    value="No"
                    className="keep-rel-radio"
                    inputClassName="keep-rel-no"
                    checked={!keepCurrentRelationships}
                    onChange={this.onKeepRelationshipsChanged}
                  />
                </fieldset>
             </div>
            ) : null}
            <div className="cell small-12 divider-cell" />
            <div className="cell small-6 medium-5 medium-offset-1">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.clearAndClose}
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-6 medium-5">
              <Button
                disabled={!canSubmit || busy}
                isExpanded
                isHollow
                className="text-uppercase next-btn"
                color="black"
                onClick={this.goToStep.bind(this, 2)}
              >
                Next
              </Button>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div className="grid-x grid-padding-x step-2">
            <div className="cell small-12 instructions">
              <p>
                 Are you sure you want to transfer ownership of system <strong>{displaySysName}</strong> {' '}
                 to <strong>{emailToInvite}</strong>?
              </p>
            </div>
            <div className="cell small-12 instructions">
              <p>
              <strong>{systemOwnerName}</strong> will no longer own or have access to system <strong>{displaySysName}</strong>.
              </p>
            </div>
            <div className="cell small-12 instructions">
              {partner.length > 0 ? (
                keepCurrentRelationships ? (
                  <p>
                    All system access partners will retain access to <strong>{displaySysName}</strong>.
                  </p>
                ) : (
                  <p>
                    All system access partners will no longer have access to <strong>{displaySysName}</strong>.
                  </p>
                )
              ) : null}
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-4">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.clearAndClose}
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-4">
              <Button
                isExpanded
                isHollow
                disabled={busy}
                className="text-uppercase back-btn"
                color="black"
                onClick={this.goToStep.bind(this, 1)}
              >
                Back
              </Button>
            </div>
            <div className="cell small-4">
              <Button
                disabled={!canSubmit || busy}
                isExpanded
                isHollow
                className="text-uppercase ok-btn"
                color="black"
                onClick={this.doTransfer}
              >
                TRANSFER
              </Button>
            </div>
          </div>
        ) : (
          <div>We seem to have encountered an error</div>
        )}
      </TitledModal>
    );
  }
}

export default TransferOwnerModal;

// @flow

import React, { Component } from 'react';
import { watch } from 'redux-easy';

import { Button } from 'react-foundation';
import { sortBy } from 'lodash';

import TitledPanel from '../general-components/titled-panel';
import SiteSystemRow from './site-system-row';
import AddSystemModal from './add-system-modal';
import RemoveSystemModal from './remove-system-modal';

import systemSvc from '../systems/system-service';

import './site-systems.css';
import CollapsibleGridContainer from '../general-components/collapsible-grid-container';
import CollapsibleGrid from '../general-components/collapsible-grid';

import type { SiteType, SystemType } from '../types';
import type { ColumnDescriptorType } from '../general-components/collapsible-grid';


type PropsType = {
  site: SiteType,
  siteSystems: SystemType[],
  allSystems: SystemType[],
  onChange: Function,
  disabled: boolean
};

type MyStateType = {
  addSystemOpen: boolean
};

const COLUMNS: ColumnDescriptorType[] = [
  {
    label: 'System Name',
    size: 3,
    className: 'name-header',
    dataClassName: 'system-name'
  },
  {
    label: 'System Owner',
    size: 3,
    className: 'owner-header',
    dataClassName: 'system-owner'
  },
  {
    label: 'System Access Partners',
    size: 4,
    className: 'partner-header',
    dataClassName: 'system-partner'
  },
  {
    label: '',
    size: 2,
    className: 'edit-btn-header',
    dataClassName: 'edit-btn'
  }
];

export class SiteSystems extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      addSystemOpen: false,
      removeModalOpen: false
    };
  }

  openAddSystem = () => {
    this.setState({
      addSystemOpen: true
    });
  };

  cancelAddSystem = () => {
    this.setState({
      addSystemOpen: false
    });
  };

  somethingChanged = () => {
    const { onChange } = this.props;

    this.setState({
      addSystemOpen: false,
      removeModalOpen: false,
      systemToRemove: {}
    });

    this.refreshSystems();

    onChange();
  };

  openRemoveModal = (system) => {
    this.setState({
      removeModalOpen: true,
      systemToRemove: system

    });
  };

  cancelRemoveModal = () => {
    this.setState({
      removeModalOpen: false,
      systemToRemove: {}
    });
  };

  refreshSystems = () => {
    systemSvc.refreshSystems();
  };

  componentDidMount() {
    this.refreshSystems();
  }

  render() {
    let eligibleSystems = [];
    const { site, siteSystems, allSystems, disabled, userSiteOrg, userIsOrgAdmin } = this.props;
    const { addSystemOpen, removeModalOpen, systemToRemove } = this.state;

    const siteSystemsCount = siteSystems && siteSystems.length ? siteSystems.length : 0 ;
    eligibleSystems = allSystems && allSystems.length ? sortBy(allSystems.filter(system => !Boolean(system.site)), 'name') : [];
    const addSystemDisabled = disabled || siteSystemsCount === 64 || !userIsOrgAdmin || eligibleSystems.length === 0;
    const removeSystemDisabled = disabled || !userIsOrgAdmin ;

    const siteOrgs = site && site.organizations ? site.organizations : [];

    const ownerOrg = siteOrgs.find(org => org.associationType === 'Owner');
    const partnerOrg = siteOrgs.filter(org => org.associationType === 'Partner');

    return (
      <TitledPanel title="Site Systems" className="site-systems-panel">
        <AddSystemModal
          isOpen={addSystemOpen}
          onRequestClose={this.cancelAddSystem}
          onSuccess={this.somethingChanged}
          site={site}
          eligibleSystems={eligibleSystems}
          owner={ownerOrg}
          partner={partnerOrg}
          userSiteOrg={userSiteOrg}
        />
        <RemoveSystemModal
          isOpen={removeModalOpen}
          onRequestClose={this.cancelRemoveModal}
          onSuccess={this.somethingChanged}
          site={site}
          system={systemToRemove}
          partner={partnerOrg}
          owner={ownerOrg}
        />
        <CollapsibleGridContainer className="system-list">
          <div className="grid-x grid-padding-x">
            <div className="cell small-12 medium-4">
              <Button
                className="text-uppercase"
                isExpanded
                isHollow
                disabled={addSystemDisabled}
                onClick={this.openAddSystem}
                color="little-blue"
              >
                Add System
              </Button>
            </div>
            <div className="cell small-12 medium-6">When a system is part of a site each system will update its owner and access
              partner settings to match the site owner and site access partner settings.</div>
            <div className="cell small-12 medium-2 system-count text-uppercase">
              {siteSystems ? siteSystems.length : 0} systems
            </div>
          </div>
          {siteSystemsCount > 0 ? 
          <React.Fragment>
            <div className="collapsible-grid-divider" />
            <CollapsibleGrid className="site-systems-grid" columns={COLUMNS}>
              {siteSystems.map((siteSystem) => (
                <SiteSystemRow
                  key={siteSystem.id}
                  site={site}
                  siteSystem={siteSystem}
                  disabled={removeSystemDisabled}
                  openRemoveModal={this.openRemoveModal}
                  columns={COLUMNS}
                />
              ))}
            </CollapsibleGrid>
          </React.Fragment> : null }
        </CollapsibleGridContainer>
      </TitledPanel>
    );
  }
}

export default watch(SiteSystems, {
  allSystems: 'systems'
});

// @flow

import React, { Component } from 'react';
import TitledModal from '../general-components/titled-modal';
import Radio from '../general-components/radio';
import {Button} from 'react-foundation';
import siteSvc from './site-service';

import type { SiteType, SiteOrgType } from '../types';

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onSuccess: Function,
  site: SiteType,
  owner: ?SiteOrgType,
  partner: ?SiteOrgType
};

type MyStateType = {
  busy: boolean,
  currentStep: number
};

const STARTING_STATE = {
  busy: false,
  currentStep: 1,
  partnerToswapOwnership: -3
};

export default class SwapSiteModal extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      ...STARTING_STATE
    };
  }

  goToStep = (step: number) => {
    this.setState({
      currentStep: step
    });
  };

  doCancel = () => {
    const {onRequestClose} = this.props;
    this.setState({
      ...STARTING_STATE
    });
    onRequestClose();
  };

  doSwapOwner = async () => {
    const { site, onSuccess, partner } = this.props;
    this.setState({
      busy: true
    });
    let currentPartnerToswapOwnership = partner && partner.length === 1 ? partner[0].id : this.state.partnerToswapOwnership;
    await siteSvc.swapSiteOwnership(site.id, { inviteeOrgId: currentPartnerToswapOwnership });

    this.setState({
      ...STARTING_STATE
    });

    onSuccess();
  };

  onKeepRelationshipsChanged = (value) => {
    this.setState({
      partnerToswapOwnership: value
    });
  };

  render() {
    const { busy, currentStep, partnerToswapOwnership } = this.state;
    const { isOpen, partner, owner, site } = this.props;
    const singlePartner = partner && partner.length === 1 ? true : false;
    let currentPartnerToswapOwnership = singlePartner ? partner[0].id : this.state.partnerToswapOwnership;
    const partnerOrg = partner && partner.length > 0 && 
    currentPartnerToswapOwnership !== -3 ? partner.find(org => org.id === currentPartnerToswapOwnership) : undefined;

    const partnerOrgName = partnerOrg  ? partnerOrg.name : 'UNKNOWN';

    const ownerOrgName = owner ? owner.name : 'UNKNOWN';

    const partnersListTodisplay = partner.map((org) =>
      <li><Radio
        label={org.name}
        name="keepRelationships"
        value={org.id}
        className="keep-rel-radio"
        inputClassName="keep-rel-yes"
        labelClassName="partner-radio"
        checked={partnerToswapOwnership === org.id}
        onChange={this.onKeepRelationshipsChanged}
      /></li>
    );
    const swapMessage = (
      <div className="edit-site-owner-panel">
        Select one of the <strong>{site.name}</strong> site's access partners to swap site ownership with:
        <div className="partner-radio-list-section">
          <ul className="partner-radio-list">
            {partnersListTodisplay}
          </ul>
        </div>
      </div>

    );

    return (
      <TitledModal
        title="Swap Site & System Ownership"
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.doCancel}
        className="edit-site-owner-panel minor"
      >
        {currentStep === 1 && !singlePartner ? (
          <div className="grid-x grid-padding-x step-1">
            {swapMessage}
            <div className="cell small-12 divider-cell" />
            <div className="cell small-6 medium-5 medium-offset-1">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.doCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-6 medium-5">
              <Button
                disabled={partnerToswapOwnership === -3  || busy}
                isExpanded
                isHollow
                className="text-uppercase next-btn"
                color="black"
                onClick={this.goToStep.bind(this, 2)}
              >
                Next
              </Button>
            </div>
          </div>
        ) : (currentStep === 2 || singlePartner) ? (
          <div className="grid-x grid-padding-x step-2">
            <div className="cell small-12 instructions">
              <p>
              <strong>{ownerOrgName}</strong> is requesting <strong>{partnerOrgName}</strong> take ownership of the <strong>{site.name}</strong> site and its systems.
              </p>
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-12 instructions">
            <p className="instruction-list"><strong>{partnerOrgName}</strong> will become the new site owner.</p>
            <p className="instruction-list"><strong>{ownerOrgName}</strong> will become a site access partner.</p>
            <p className="instruction-list" >The site’s systems will update their owners and access partners to match.</p>
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-12 instructions">
              <p>
                Are you sure you want to swap site and system ownership?
              </p>
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-4">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.doCancel}
              >
                Cancel
              </Button>
            </div>
            {!singlePartner ?
            <div className="cell small-4">
              <Button
                isExpanded
                isHollow
                disabled={busy}
                className="text-uppercase back-btn"
                color="black"
                onClick={this.goToStep.bind(this, 1)}
              >
                Back
              </Button>
            </div> : null }
            <div className="cell small-4">
              <Button
                disabled={busy}
                isExpanded
                isHollow
                className="text-uppercase ok-btn"
                color="black"
                onClick={this.doSwapOwner}
              >
                Swap
              </Button>
            </div>
          </div>
        ) : (
          <div>We seem to have encountered an error</div>
        )}
      </TitledModal>
    );
  }
}

// @flow

import React, {Component} from 'react';
import {dispatch, watch, dispatchSet} from 'redux-easy';
import {Button} from 'react-foundation';

import RegisterSystemModal from './register-system-modal';
import SystemsSiteGroup from './systems-site-group';
import GeneralPage from '../general-components/general-page';
import GeneralPageEmptyMessage from '../general-components/general-page-empty-message';
import Select from '../general-components/select';

import {changeRoute} from '../route';
import {buildCondensedAddress} from '../util/component-util';
import sysSvc from './system-service';
import {sortSystems} from './systems-util';
import './systems.css';

import type {
  SystemType,
  SiteSystemsType,
  UserType,
  UserPreferencesType,
  SystemsFilterNameType
} from '../types';

type PropsType = {
  systems: SystemType[],
  // ESLint doesn't recognize when the prop is used in `getDerivedStateFromProps`
  // eslint-disable-next-line react/no-unused-prop-types
  systemsBySite: SiteSystemsType,
  user: UserType,
  userPreferences: UserPreferencesType,
  unreadNotifications: number,
  userIsOrgAdmin: boolean,
  userIsOrgServicer: boolean,
  shouldShowNotificationToast: boolean,
  // ESLint doesn't recognize when the prop is used in `getDerivedStateFromProps`
  // eslint-disable-next-line react/no-unused-prop-types
  systemsFilterName: SystemsFilterNameType
};

type MyStateType = {
  registerSystemOpen: boolean,
  systemsToShow: SiteSystemsType,
  systemsToShowCount: number
};

const FILTER_OPTIONS = [
  {
    value: 'all',
    display: 'All Systems'
  },
  {
    value: 'alarms',
    display: 'Systems - Active Alarms'
  },
  {
    value: 'errors',
    display: 'Systems - Active Errors'
  },
  {
    value: 'alarmsanderrors',
    display: 'Systems - Active Alarms and/or Errors'
  },
  {
    value: 'disconnected',
    display: 'Systems - Disconnected'
  },
  {
    value: 'owner',
    display: 'Systems - Owned'
  },
  {
    value: 'access',
    display: 'Systems - Access To'
  }
];

const SITE_BASIC_FILTER_OPTIONS = [
  {
    value: 'all',
    display: 'All Sites'
  },
  {
    value: 'noSiteAssigned',
    display: 'NO SITE ASSIGNED'
  },
  
];

// $FlowFixMe - Not all filters take a user object because not all filters need one
export const SYSTEM_FILTERS = {
  all: system => system,
  alarms: system =>
    system.isConnected && system.alarmCount && system.alarmCount > 0,
  errors: system =>
    system.isConnected && system.errorCount && system.errorCount > 0,
  alarmsanderrors: system =>
    system.isConnected &&
    ((system.alarmCount && system.alarmCount > 0) ||
      (system.errorCount && system.errorCount > 0)),
  disconnected: system => !system.isConnected,
  owner: (system, user) => {
    if (user.organization) {
      const userOrgId = user.organization.id;
      const userSystemOrg = system.organizations.find(
        org => org.id === userOrgId
      );
      return userSystemOrg && userSystemOrg.type === 'Owner';
    }

    return false;
  },
  access: (system, user) => {
    if (user.organization) {
      const userOrgId = user.organization.id;
      const userSystemOrg = system.organizations.find(
        org => org.id === userOrgId
      );
      return userSystemOrg && userSystemOrg.type === 'Partner';
    }

    return false;
  }
};

function buildFilter(systemsFilterName, user) {
  const myFilter = SYSTEM_FILTERS[systemsFilterName] || (() => true);

  return function(system) {
    // $FlowFixMe - Some filters use the 'user' object and some don't
    return myFilter(system, user);
  };
}

class Systems extends Component<PropsType, MyStateType> {
  cancelPollToken: any;

  constructor() {
    super();
    this.cancelPollToken = null;
    this.state = {
      registerSystemOpen: false,
      systemsToShow: {
        siteSystems: [],
        siteFilterOptions: [],
        
      },
      systemsToShowCount: 0
    };
  }

  static getDerivedStateFromProps(props: PropsType) {
    const {systemsBySite, systemsFilterName, sitesFilterName, user} = props;
    const {siteSystems, noSiteSystems} = systemsBySite;
    
    const myFilter = buildFilter(systemsFilterName, user);

    const filterSiteSystems = siteSystems
      .map(siteWithSystems => ({
        site: siteWithSystems.site,
        systems: sortSystems(siteWithSystems.systems.filter(myFilter))
      }))
      .filter(siteWithSystems => siteWithSystems.systems.length > 0 && 
        (String(siteWithSystems.site.id) === String(sitesFilterName) || sitesFilterName === 'all'));

      const siteFilterList = siteSystems.map(siteSystem => {return { value: siteSystem.site.id, display : siteSystem.site.name}});
     const siteFilterOptions = [ ...SITE_BASIC_FILTER_OPTIONS, ...siteFilterList ]

    const filterNoSiteSystems = ['noSiteAssigned', 'all'].includes(sitesFilterName) ? sortSystems(noSiteSystems.filter(myFilter)) : [];

    const filterSiteSystemsCount = filterSiteSystems.reduce(
      (total, siteWithSystems) => total + siteWithSystems.systems.length,
      0
    );
    return {
      systemsToShow: {
        siteSystems: filterSiteSystems,
        noSiteSystems: filterNoSiteSystems
      },
      siteFilterOptions: siteFilterOptions,
      systemsToShowCount: filterSiteSystemsCount + filterNoSiteSystems.length
    };
  }

  componentDidMount() {
    sysSvc.startPolling();
    this.evalUnreadNotificationToast();
  }

  componentDidUpdate() {
    this.evalUnreadNotificationToast();
  }

  componentWillUnmount() {
    sysSvc.stopPolling();
  }

  goToNotifications = () => {
    changeRoute('notifications');
  };

  evalUnreadNotificationToast() {
    const {unreadNotifications, shouldShowNotificationToast} = this.props;
    if (unreadNotifications === 0) {
      dispatchSet('ui.shouldShowNotificationToast', true);
    } else if (shouldShowNotificationToast) {
      const message = (
        <span>
          You have <strong>{unreadNotifications} new</strong> notifications!
          <br />
          Go to <a onClick={this.goToNotifications}>your notifications.</a>
        </span>
      );
      dispatch('addToast', {
        type: 'notification',
        message
      });
      dispatchSet('ui.shouldShowNotificationToast', false);
    }
  }

  registerSystem = () => {
    this.setState({
      registerSystemOpen: true
    });
  };

  closeRegisterSystem = () => {
    this.setState({
      registerSystemOpen: false
    });
  };

  registerSystemSuccess = () => {
    this.setState({
      registerSystemOpen: false
    });

    sysSvc.refreshSystems();
  };

  render() {
    const {
      systems,
      user,
      userIsOrgAdmin,
      userIsOrgServicer,
      userPreferences
    } = this.props;
    const {registerSystemOpen, systemsToShow, systemsToShowCount, siteFilterOptions} = this.state;
    const {siteSystems, noSiteSystems} = systemsToShow;
    if (!user.authenticated) return changeRoute('login');

    const s = systems.length === 1 ? '' : 's';

    const message = user.organization
      ? `Welcome to the ${user.organization.name} Dashboard`
      : 'Welcome to your Dashboard';

    const subTextMessage =
      systemsToShowCount === systems.length
        ? `Total of ${systems.length} system${s}`
        : `Showing ${systemsToShowCount} of ${systems.length} system${s}`;

    return (
      <GeneralPage
        title={`Hello ${user.firstName},`}
        pageMessage={message}
        subText={subTextMessage}
        className="systems"
        contentClassName="systems-content grid-x grid-padding-x grid-margin-x"
      >
        <RegisterSystemModal
          isOpen={registerSystemOpen}
          onRequestClose={this.closeRegisterSystem}
          onSuccess={this.registerSystemSuccess}
        />
        <div className="cell large-4 medium-6 small-12">
          <div className="grid-x">
            <div className="cell large-10 large-offset-0 medium-12 small-12">
              <Select
                className="system-filter"
                disabled={systems.length === 0}
                path="ui.sitesFilterName"
                options={siteFilterOptions}
              />
            </div>
          </div>
        </div>        
        <div className="cell large-4  medium-6 small-12">
          <div className="grid-x">
            <div className="cell large-10 large-offset-1 medium-12 small-12">
              <Select
                className="system-filter"
                disabled={systems.length === 0}
                path="ui.systemsFilterName"
                options={FILTER_OPTIONS}
              />
            </div>
          </div>
        </div>
        <div className="cell large-4 medium-6 small-12">
          <div className="grid-x">
            <div className="cell large-10 large-offset-2 medium-12 small-12">
              <Button
                isHollow
                isExpanded
                onClick={this.registerSystem}
                className="text-uppercase"
              >
                Register New System
              </Button>
            </div>
          </div>
        </div>
        {systems.length === 0 ? (
          <div className="cell small-12">
            <GeneralPageEmptyMessage>
              <div>You do not have any systems in your company.</div>
              <div>
                Go to Register New System to add a system to the Dashboard.
              </div>
            </GeneralPageEmptyMessage>
          </div>
        ) : null}
        {systems.length > 0 && systemsToShowCount === 0 ? (
          <div className="cell small-12">
            <GeneralPageEmptyMessage>
              <div>No Systems Match This Filter.</div>
            </GeneralPageEmptyMessage>
          </div>
        ) : null}
        { siteSystems.length > 0
          ? siteSystems.map(siteGroup => (
              <div className="cell small-12" key={siteGroup.site.id}>
                <SystemsSiteGroup
                  siteName={siteGroup.site.name}
                  siteAddress={buildCondensedAddress(siteGroup.site)}
                  siteId={siteGroup.site.id}
                  systems={siteGroup.systems}
                  userIsOrgAdmin={userIsOrgAdmin}
                  userIsOrgServicer={userIsOrgServicer}
                  temperatureDisplayUnit={
                    userPreferences.temperatureDisplayUnit
                  }
                />
              </div>
            ))
          : null}
        {noSiteSystems.length > 0 ? (
          <div className="cell small-12">
            <SystemsSiteGroup
              siteName="No Site Assigned"
              siteAddress="SYSTEMS NOT ASSIGNED TO ANY SITE"
              systems={noSiteSystems}
              userIsOrgAdmin={userIsOrgAdmin}
              userIsOrgServicer={userIsOrgServicer}
              temperatureDisplayUnit={userPreferences.temperatureDisplayUnit}
            />
          </div>
        ) : null}
      </GeneralPage>
    );
  }
}

export default watch(Systems, {
  shouldShowNotificationToast: 'ui.shouldShowNotificationToast',
  systems: 'systems',
  systemsBySite: 'systemsBySite',
  systemsFilterName: 'ui.systemsFilterName',
  sitesFilterName: 'ui.sitesFilterName',
  unreadNotifications: 'unreadNotifications',
  user: 'user',
  userIsOrgAdmin: 'sessionInfo.userIsOrgAdmin',
  userIsOrgServicer: 'sessionInfo.userIsOrgServicer',
  userPreferences: 'userPreferences'
});

// @flow

import React, {Component} from 'react';

import {buildClassName} from '../util/component-util';

import './radio.css';

type PropsType = {
  name: string,
  checked: boolean,
  value: string,
  label?: any,
  labelClassName?: string,
  inputClassName?: string,
  disabled?: boolean,
  className?: ?string,
  onChange?: Function,
  onClick?: Function
};

export default class Radio extends Component<PropsType> {
  onInternalClick = () => {
    const {disabled, onChange, onClick, value} = this.props;

    if (!disabled) {
      if (onChange) {
        onChange(value);
      }
      if (onClick) {
        onClick(value);
      }
    }
  };

  render() {
    const {
      name,
      label,
      value,
      checked,
      labelClassName,
      inputClassName,
      disabled,
      className
    } = this.props;

    const topClass = buildClassName('prism-radio', className);

    const labelClass = buildClassName(
      'prism-radio-label',
      labelClassName,
      disabled ? 'disabled' : null,
      !label ? 'empty-label' : null
    );

    const inputClass = buildClassName('prism-radio-input', inputClassName);

    return (
      <div className={topClass}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          className={inputClass}
          disabled={disabled}
          onClick={this.onInternalClick}
          onChange={() => {}}
        />{' '}
        <label
          className={labelClass}
          htmlFor={name}
          onClick={this.onInternalClick}
        >
          {label}
        </label>
      </div>
    );
  }
}

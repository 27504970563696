// @flow

const tabs = {};

function close(name: string) {
  const theTab = tabs[name];
  if (theTab && typeof theTab.close === 'function') {
    theTab.close();
  }
  delete tabs[name];
}

function open(url: string, name: string) {
  close(name);
  tabs[name] = window.open(url, name);
}

function closeAll() {
  Object.keys(tabs).forEach(tabName => close(tabName));
}

function initialize() {
  window.addEventListener('beforeunload', closeAll);
  window.addEventListener('unload', closeAll);
}

export default {
  initialize,
  open,
  close,
  closeAll
};

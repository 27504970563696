// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';

import {changeRoute} from '../route';

import './notification-icon.css';
import notificationIcon from './images/notifications-icon.svg';

type PropsType = {
  unreadNotifications: number
};

class NotificationIcon extends Component<PropsType> {
  goToNotifications = () => {
    changeRoute('notifications');
  };

  render() {
    const {unreadNotifications} = this.props;

    return (
      <a
        title="Notifications"
        className="notification-link icon-only"
        onClick={this.goToNotifications}
      >
        <span className="badged-icon-container">
          <img src={notificationIcon} alt="Notifications" />
          {unreadNotifications > 0 ? (
            <div className="notification-badge">{unreadNotifications}</div>
          ) : null}
        </span>
      </a>
    );
  }
}

export default watch(NotificationIcon, {
  unreadNotifications: ''
});

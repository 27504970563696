// @flow

import React, {Component} from 'react';
import {get} from 'lodash';

import {Button} from 'react-foundation';
import CollapsibleGridRow from '../general-components/collapsible-grid-row';


import type {SiteType, SystemType} from '../types';

type PropsType = {
  site: SiteType,
  siteSystem: SystemType,
  onChange: Function,
  disabled: boolean
};

type MyStateType = {
  removeModalOpen: boolean
};

export default class SiteSystemRow extends Component<PropsType, MyStateType> {
  constructor() {
    super();
  }

  render() {
    const {key, site, siteSystem, disabled, openRemoveModal, columns } = this.props;
    let partnerOrgName = '';
    const systemName = get(siteSystem, 'name', 'Unknown');
    const ownerOrg = siteSystem.organizations.find(
      org => org.type === 'Owner'
    );
    const partnerOrg = siteSystem.organizations.filter(
      org => org.type === 'Partner'
    );

    const ownerOrgName = get(ownerOrg, 'name', '-');
    if (Array.isArray(partnerOrg) && partnerOrg.length > 0) {
      for (let partner of partnerOrg) {
        partnerOrgName = partner.name + ';' + partnerOrgName;
      }
    } else {
      partnerOrgName = '<no access>';
    }
     partnerOrgName = partnerOrgName.length > 42 ? partnerOrgName.substring(0,41).concat('...') : partnerOrgName;

     const cells = [
      systemName,
      ownerOrgName,
      partnerOrgName,
      <Button
      className="text-uppercase"
      isExpanded
      isHollow
      disabled={disabled}
      onClick={() => openRemoveModal(siteSystem)}
      color="little-blue"
    >
      Remove System
    </Button>
    ];

    return (
      <CollapsibleGridRow
        key={key}
        className="manage-system-item"
        columns={columns}
        cells={cells}
      />
    );
  }
}

// @flow

import React, {Component} from 'react';

import LabelledInput from './labelled-input';
import PhoneInputBase from '../general-components/phone-input-base';

type PropsType = {
  path: string,
  label: string
};

export default class LabelledPhoneInput extends Component<PropsType> {
  render() {
    return <LabelledInput {...this.props} inputComponent={PhoneInputBase} />;
  }
}

import React, {Component} from 'react';
import { watch } from 'redux-easy';

import CheckBox from '../general-components/check-box';
import Select from '../general-components/select';
import TitledPanel from '../general-components/titled-panel';
import TitledPanelSectionHeader from '../general-components/titled-panel-section-header';
import LabelledCell from '../general-components/labelled-cell';

import {DAY_OPTIONS, HOUR_OPTIONS, TIMEZONE_OPTIONS} from '../util/option-values';

import './user-email-preferences-panel.css';

class UserEmailPreferencePanel extends Component {
  constructor() {
    super();
  }
  internalOnChange = () => {
    const {onChange} = this.props;
      setTimeout(() => {
        onChange();
      });
  };

  render() {
    const {disabled, userPreferences} = this.props;
    const {notifications} = userPreferences;
    const emailEnabled = notifications.email.enabled;

    const dailySummaryEnabled = notifications.email.dailySummary.enabled;
    const weeklySummaryEnabled = notifications.email.weeklySummary.enabled;

    return (
      <TitledPanel
        title="Email Preferences"
        className="user-email-preferences-panel"
        contentClassName="grid-x grid-padding-x"
      >
        <div className="cell small-12">
          <LabelledCell label="Email notifications are sent when alarms and errors are raised and cleared. Optional daily and weekly summary emails are also available.">
            <CheckBox
              className="enable-email-check"
              path="userPreferences.notifications.email.enabled"
              name="email-enabled"
              disabled={disabled}
              label="Enable Email Notifications"
              styleName="switch"
              onChange={this.internalOnChange}
            />
          </LabelledCell>
        </div>
        {emailEnabled ? (
          <div className="cell small-12 email-details">
            <div className="grid-x grid-padding-x email-preferences-section">
              <div className="cell small-12">
                <TitledPanelSectionHeader
                  title="Actions"
                  className="delay-header"
                />
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCell label="">
                  <CheckBox
                    path="userPreferences.notifications.email.dailySummary.enabled"
                    name="daily-summary-enabled"
                    disabled={disabled}
                    label="Daily Summary"
                    styleName="dark-blue-square"
                    onChange={this.internalOnChange}
                  />
                </LabelledCell>
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCell label="Starting Hour">
                  <Select
                    path="userPreferences.notifications.email.dailySummary.startingHour"
                    disabled={disabled || !dailySummaryEnabled}
                    valueIsNumber
                    onChange={this.internalOnChange}
                    options={HOUR_OPTIONS}
                  />
                </LabelledCell>
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCell label="Timezone">
                  <Select
                    path="userPreferences.notifications.email.summary.timezone"
                    disabled={disabled || !(dailySummaryEnabled || weeklySummaryEnabled)}
                    onChange={this.internalOnChange}
                    options={TIMEZONE_OPTIONS}
                  />
                </LabelledCell>
              </div>
              <div className="cell large-4 medium-12 small-12">
                <LabelledCell label="">
                  <CheckBox
                    path="userPreferences.notifications.email.weeklySummary.enabled"
                    name="weekly-summary-enabled"
                    disabled={disabled}
                    label="Weekly Summary"
                    styleName="dark-blue-square"
                    onChange={this.internalOnChange}
                  />
                </LabelledCell>
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCell label="Starting Hour">
                  <Select
                    path="userPreferences.notifications.email.weeklySummary.startingHour"
                    disabled={disabled || !weeklySummaryEnabled}
                    onChange={this.internalOnChange}
                    valueIsNumber
                    options={HOUR_OPTIONS}
                  />
                </LabelledCell>
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCell label="Starting Day">
                  <Select
                    path="userPreferences.notifications.email.weeklySummary.startingDay"
                    disabled={disabled || !weeklySummaryEnabled}
                    onChange={this.internalOnChange}
                    valueIsNumber
                    options={DAY_OPTIONS}
                  />
                </LabelledCell>
              </div>
            </div>
          </div>
        ) : null}
      </TitledPanel>
    );
  }
}

export default watch(UserEmailPreferencePanel, {
  userPreferences: ''
});

// @flow

import {dispatch, dispatchSet} from 'redux-easy';
import {fetchJson, systemUrl} from '../util/rest-util';
import tabMngr from '../system-detail/system-tab-manager';
import browserInfo from '../util/browser-util';
import {changeRoute} from '../route';

import type {
  QuestionableSystemOrgRelNameType,
  SystemOrgRelNameType,
  SystemOrgRelType,
  SystemType,
  SystemInvitationType,
  UserOrgType
} from '../types';

const POLL_INTERVAL = 3000;
let cancelToken = null;

async function refreshSystems() {
  const res = await fetchJson(systemUrl('systems/'));
  if (res.status === 200) {
    const systems = await res.json();
    dispatch('setSystems', systems);
  } else {
    dispatch('addErrorToast', 'Encountered error while retrieving systems.');
  }
}

async function startPolling() {
  if (!cancelToken) {
    await refreshSystems();
    cancelToken = setInterval(refreshSystems, POLL_INTERVAL);
  } else {
    stopPolling();
    await startPolling();
  }
}

function getSystemUrl(system: SystemType): string {
  const proxyUrl = process.env.REACT_APP_PROXY_URL || 'no.url.found';

  let systemUrl;
  if (system.wecFirmwareVersion) {
    systemUrl = `${proxyUrl}wec-cdn/${system.id}/${
      system.wecFirmwareVersion
    }/system.html`;
  } else {
    systemUrl = `${proxyUrl}proxy/${system.id}/system.html`;
  }

  return systemUrl;
}

function stopPolling() {
  if (cancelToken) {
    clearInterval(cancelToken);
    cancelToken = null;
  }
}

async function updateSystemsForSelectedSite(siteId: number) {
  const res = await fetchJson(systemUrl('systems/'));
  if (res.status === 200) {
    const systems = await res.json();
    dispatch('setSystems', systems);
    const siteSystems = systems.filter(
      system => system.site && system.site.id === siteId
    );
    dispatchSet('selectedSiteSystems', siteSystems);
  } else {
    dispatch(
      'addErrorToast',
      'Encountered error while retrieving systems for this site.'
    );
  }
}

async function updateSystems(systems) {
  const url = systemUrl('systems/');
  const options = {
    method: 'PATCH',
    body: JSON.stringify(systems)
  };

  const result = await fetchJson(url, options);

  if (result.status !== 200) {
    dispatch(
      'addErrorToast',
      'Unable to reorder.  If this issue persists, please contact support'
    );
    return Promise.reject();
  }
}

export type ClaimSystemResultType = {
  successful: boolean,
  message: string
};

async function claimSystem(
  pin: string,
  captchaToken: string
): Promise<ClaimSystemResultType> {
  const data = {
    registrationCode: pin,
    captchaToken
  };
  const options = {
    method: 'POST',
    body: JSON.stringify(data)
  };

  try {
    const res = await fetchJson(systemUrl('systems/register'), options);
    const {status} = res;
    if (status === 200) {
      dispatch('addToast', {
        type: 'success',
        title: 'Success!',
        message: 'Registration Successful!'
      });
      return {
        successful: true,
        message: ''
      };
    } else if (status === 400) {
      const body = await res.json();
      return {
        successful: false,
        message: body.message
      };
    } else if (status !== 423) {
      dispatch(
        'addErrorToast',
        'Encountered unexpected error while attempting to register a system.'
      );
    }
  } catch (e) {
    dispatch(
      'addErrorToast',
      'Encountered unexpected error while attempting to register a system.'
    );
  }

  return {
    successful: false,
    message: ''
  };
}

async function updateSelectedSystemInvites(systemId: string) {
  const res = await fetchJson(systemUrl(`systems/${systemId}/invites`));
  if (res.status === 200) {
    const invites = await res.json();
    dispatchSet('selectedSystemInvitations', invites);
  } else {
    dispatch(
      'addErrorToast',
      'Encountered error while refreshing system invitation information.'
    );
  }
}

function clearSelectedSystemInvites() {
  dispatchSet('selectedSystemInvitations', []);
}

async function updateSelectedSystem(systemId: string) {
  const res = await fetchJson(systemUrl(`systems/${systemId}`));
  if (res.status === 200) {
    const systemInfo = await res.json();
    dispatch('setSelectedSystem', systemInfo);
  } else {
    dispatch(
      'addErrorToast',
      'Encountered error while refreshing system information.'
    );
  }
}

async function swapSystemOwnership(systemId, inviteeOrg) {
  const options = {
    method: 'POST',
    body: JSON.stringify(inviteeOrg)
  };

  const url = systemUrl(`systems/${systemId}/swapOwnership`);

  const result = await fetchJson(url, options);

  if (result.status === 200) {
    return {success: true};
  } else if (result.status === 400) {
    const payload = await result.json();
    return {
      success: false,
      message: payload.message
    };
  }

  return {
    success: false,
    message: 'Unable to perform swap operation'
  };
}

async function unregisterSystem(systemId) {
  const options = {
    method: 'DELETE'
  };

  const url = systemUrl(`systems/${systemId}/unRegister/`);

  const result = await fetchJson(url, options);

  if (result.status === 200) {
    return {success: true};
  } else if (result.status === 400) {
    const payload = await result.json();
    return {
      success: false,
      message: payload.message
    };
  }

  return {
    success: false,
    message: 'Unable to unregister system'
  };
}


async function inviteOrgToSystem(
  systemId: string,
  emailToInvite: string,
  associationType: SystemOrgRelNameType,
  keepExistingRelationships?: ?boolean
) {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      associationType,
      emailToInvite,
      keepExistingRelationships: Boolean(keepExistingRelationships)
    })
  };

  const url = systemUrl(`systems/${systemId}/inviteOrganization`);

  const result = await fetchJson(url, options);

  if (result.status === 200) {
    const message = `A notification email has been sent to ${emailToInvite}`;
    dispatch('addToast', {
      type: 'success',
      title: 'Success!',
      message
    });
    return {
      success: true,
      message
    };
  } else if (result.status === 400) {
    const resultBody = await result.json();
    return {
      success: false,
      message: resultBody.message
    };
  }

  return {
    success: false,
    message: 'Unable to send invitation'
  };
}

async function cancelSystemInvite(systemId: string, inviteId: number) {
  const options = {
    method: 'DELETE'
  };

  const url = systemUrl(`systems/${systemId}/invites/${inviteId}`);

  const result = await fetchJson(url, options);

  if (result.status !== 200) {
    dispatch('addToast', {
      type: 'warning',
      title: 'Warning!',
      message: 'Unable to cancel this invitation!'
    });
  }
}

async function removeOrgFromSystem(systemId: string, orgId: number) {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      orgIdToRemove: orgId
    })
  };

  const url = systemUrl(`systems/${systemId}/removeOrganization`);

  const result = await fetchJson(url, options);

  if (result.status !== 204) {
    dispatch('addToast', {
      type: 'warning',
      title: 'Warning!',
      message: 'Unable to remove organization from system!'
    });
  }
}

type OrgInfoType = {
  userRelType: QuestionableSystemOrgRelNameType,
  owner: ?SystemOrgRelType,
  partner: ?SystemOrgRelType,
  ownerInvite: ?SystemInvitationType,
  partnerInvite: ?SystemInvitationType
};

function getOrgInfo(
  system: SystemType,
  userOrg: ?UserOrgType,
  invites: SystemInvitationType[]
): OrgInfoType {
  const {organizations: orgs} = system;

  let userRel;
  if (userOrg) {
    // $FlowFixMe - I checked to make sure that userOrg was passed one line above
    userRel = orgs.find(rel => rel.id === userOrg.id);
  }
  const userRelType = userRel ? userRel.type : 'unknown';

  const owner = orgs.find(rel => rel.type === 'Owner');
  const partner = orgs.filter(rel => rel.type === 'Partner');

  const ownerInvite = invites.find(i => i.associationType === 'Owner');
  const partnerInvite = invites.filter(i => i.associationType === 'Partner');

  return {
    userRelType,
    owner,
    partner,
    ownerInvite,
    partnerInvite
  };
}

function goToSystem(system: SystemType) {
  dispatch('setSelectedSystem', system);

  if (browserInfo.isMobileSafari) {
    const systemUrl = getSystemUrl(system);
    const target = `s${system.id}`;

    tabMngr.open(systemUrl, target);
  } else {
    changeRoute('system-detail');
  }
}

export default {
  cancelSystemInvite,
  claimSystem,
  clearSelectedSystemInvites,
  getOrgInfo,
  getSystemUrl,
  goToSystem,
  inviteOrgToSystem,
  swapSystemOwnership,
  unregisterSystem,
  refreshSystems,
  startPolling,
  stopPolling,
  removeOrgFromSystem,
  updateSelectedSystem,
  updateSelectedSystemInvites,
  updateSystemsForSelectedSite,
  updateSystems
};

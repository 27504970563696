// @flow

import {get} from 'lodash';

import {getDate} from '../util/moment-util';

import connectionLostIcon from './images/connection-lost.png';
import connectionRestoredIcon from './images/connection-restored.png';
import infoIcon from './images/info.png';
import roleAddedIcon from './images/role-added.png';
import roleRemovedIcon from './images/role-removed.png';
import siteCreatedIcon from './images/add-new-site.png';
import siteRemovedIcon from './images/remove-site.png';
import swapSiteAccIcon from './images/swap-site-ownership-accepted.png';
import swapSysAccIcon from './images/swap-sys-ownership-req-accepted.svg';
import swapSysReqIcon from './images/swap-sys-ownership-req.svg';
import swapSysExpIcon from './images/swap-sys-ownership-req-expired.svg';
import systemAddedToSiteIcon from './images/add-system-to-site.png';
import systemRemovedFromSiteIcon from './images/remove-system-from-site.png';
import systemRegisteredIcon from './images/system-registered.svg';
import systemAlarmIcon from './images/alarm-notifications.svg';
import systemErrorIcon from './images/error-notifications.svg';
import transferSiteAccIcon from './images/transfer-site-ownership-accepted.png';
import transferSysReqIcon from './images/transfer-sys-ownership-req.svg';
import transferSysAccIcon from './images/transfer-sys-ownership-req-accepted.svg';
import transferSysExpIcon from './images/transfer-sys-ownership-req-expired.svg';
import userInvitedToOrgIcon from './images/invite-user.svg';
import userInviteAcceptedIcon from './images/invitation-accepted.svg';
import userInviteExpiredIcon from './images/invitation-expired.svg';
import userLeaveOrgIcon from './images/leave-organization.png';

import type {NotificationType, SystemType, SiteType} from '../types';

type NotificationItemStarterType = {
  icon: any,
  barColor: string
};

export type NotificationItemDisplayType = NotificationItemStarterType & {
  id: number,
  type: string,
  isNew: boolean,
  hasActions: boolean,
  done: boolean,
  displayDate: string,
  displayTime: string,
  title: string,
  message: string,
  systemId?: ?string,
  systemAvailable: boolean,
  systemName?: ?string,
  systemUnit?: ?string,
  system?: ?SystemType,
  site?: ?SiteType
};

type NotificationItemStarterMapType = {
  [key: string]: NotificationItemStarterType
};

const SYSTEM_EVENT_NOTIFICATION_MAP: NotificationItemStarterMapType = {
  disconnected: {
    icon: connectionLostIcon,
    barColor: '#f14a36'
  },
  connected: {
    icon: connectionRestoredIcon,
    barColor: '#7dbfff'
  },
  error: {
    icon: systemErrorIcon,
    barColor: '#ffde3c'
  },
  alarm: {
    icon: systemAlarmIcon,
    barColor: '#f14a36'
  }
};

const NOTIFICATION_MAP: NotificationItemStarterMapType = {
  'user-invited-to-org': {
    icon: userInvitedToOrgIcon,
    barColor: '#7dbfff'
  },
  'user-reinvited-to-org': {
    icon: userInvitedToOrgIcon,
    barColor: '#7dbfff'
  },
  'invitation-to-join-org': {
    icon: userInvitedToOrgIcon,
    barColor: '#7dbfff'
  },
  'user-accepted-invite-to-org': {
    icon: userInviteAcceptedIcon,
    barColor: '#7dbfff'
  },
  'invitation-to-join-org-accepted': {
    icon: userInviteAcceptedIcon,
    barColor: '#7dbfff'
  },
  'transfer-ownership-request': {
    icon: transferSysReqIcon,
    barColor: '#7dbfff'
  },
  'transfer-ownership-accepted': {
    icon: transferSysAccIcon,
    barColor: '#7dbfff'
  },
  'swap-ownership-request': {
    icon: swapSysReqIcon,
    barColor: '#7dbfff'
  },
  'swap-ownership-accepted': {
    icon: swapSysAccIcon,
    barColor: '#7dbfff'
  },
  'site-created': {
    icon: siteCreatedIcon,
    barColor: '#7dbfff'
  },
  'site-swap-completed': {
    icon: swapSiteAccIcon,
    barColor: '#7dbfff'
  },
  'site-transfer-completed': {
    icon: transferSiteAccIcon,
    barColor: '#7dbfff'
  },
  'site-removed': {
    icon: siteRemovedIcon,
    barColor: '#f14a36'
  },
  'system-added-to-site': {
    icon: systemAddedToSiteIcon,
    barColor: '#7dbfff'
  },
  'system-removed-from-site': {
    icon: systemRemovedFromSiteIcon,
    barColor: '#7dbfff'
  },
  'system-registered': {
    icon: systemRegisteredIcon,
    barColor: '#7dbfff'
  },
  'role-added': {
    icon: roleAddedIcon,
    barColor: '#7dbfff'
  },
  'role-removed': {
    icon: roleRemovedIcon,
    barColor: '#f14a36'
  },
  'user-left-organization': {
    icon: userLeaveOrgIcon,
    barColor: '#f14a36'
  },
  'user-invited-to-org-expired': {
    icon: userInviteExpiredIcon,
    barColor: '#7dbfff'
  },
  'invitation-to-join-expired': {
    icon: userInviteExpiredIcon,
    barColor: '#7dbfff'
  },
  'transfer-ownership-expired': {
    icon: transferSysExpIcon,
    barColor: '#7dbfff'
  },
  'swap-ownership-expired': {
    icon: swapSysExpIcon,
    barColor: '#7dbfff'
  }
};

const UNKNOWN_NOTIFICATION: NotificationItemStarterType = {
  icon: infoIcon,
  barColor: '#8e73c9'
};

function getBaseDisplayForSystemEvent(
  input: NotificationType
): NotificationItemStarterType {
  const eventData = get(input, 'data', {});

  if (eventData.source === 'prism' && eventData.class === 'connect') {
    if (eventData.action === 'set') {
      return {
        ...SYSTEM_EVENT_NOTIFICATION_MAP.connected
      };
    }

    return {
      ...SYSTEM_EVENT_NOTIFICATION_MAP.disconnected
    };
  } else if (eventData.class === 'error') {
    return {
      ...SYSTEM_EVENT_NOTIFICATION_MAP.error
    };
  } else if (eventData.class === 'alarm') {
    return {
      ...SYSTEM_EVENT_NOTIFICATION_MAP.alarm
    };
  }

  return {
    ...UNKNOWN_NOTIFICATION
  };
}

function getBaseDisplayForNonSystemEvent(
  input: NotificationType
): NotificationItemStarterType {
  const result = NOTIFICATION_MAP[input.type] || UNKNOWN_NOTIFICATION;

  return {
    ...result
  };
}

export function buildNotificationItemDisplay(
  input: NotificationType,
  lastViewedNotifications: Date,
  systems: SystemType[]
): NotificationItemDisplayType {
  const starter =
    input.type === 'system-notification' ?
      getBaseDisplayForSystemEvent(input) :
      getBaseDisplayForNonSystemEvent(input);

  const created = getDate(input.created);

  const eventData = get(input, 'data', {});

  const displayDate = created.format('MM/DD/YYYY');
  const displayTime = created.format('h:mm a');

  const result: NotificationItemDisplayType = {
    ...starter,
    id: input.id,
    type: input.type,
    isNew: new Date(input.created) > new Date(lastViewedNotifications),
    hasActions:
      Array.isArray(input.actions) && input.actions.length > 0 && !input.done,
    done: Boolean(input.done),
    displayDate,
    displayTime,
    title: input.title,
    message: input.message,
    systemAvailable: false
  };

  if (input.systemId) {
    const mySystem = systems.find(sys => sys.id === input.systemId);
    result.systemId = input.systemId;
    result.systemAvailable = Boolean(mySystem);

    if (mySystem) {
      result.system = mySystem;
      result.site = mySystem.site;
      result.systemName = mySystem.name || 'NO NAME';

      result.systemUnit = eventData.unitName;
    }
  }

  return result;
}

// @flow

import React, { Component } from 'react';
import TitledModal from '../general-components/titled-modal';
import Radio from '../general-components/radio';
import {Button} from 'react-foundation';
import sysSvc from './system-service';


const STARTING_STATE = {
  busy: false,
  currentStep: 1,
  partnerToswapOwnership: -3
};

export default class SwapSystemModal extends Component {
  constructor() {
    super();
    this.state = {
      ...STARTING_STATE
    };
  }

  goToStep = (step) => {
    this.setState({
      currentStep: step
    });
  };

  doCancel = () => {
    const {onRequestClose} = this.props;
    this.setState({
      ...STARTING_STATE
    });
    onRequestClose();
  };

  doSwapOwner = async () => {
    const {system, partner, onSuccess} = this.props;
    this.setState({
      busy: true
    });

    let currentPartnerToswapOwnership = partner && partner.length === 1 ? partner[0].id : this.state.partnerToswapOwnership;
    const result = await sysSvc.swapSystemOwnership(system.id, {inviteeOrgId : currentPartnerToswapOwnership});

    this.setState({
      ...STARTING_STATE
    });

    onSuccess(result);
  };

  onKeepRelationshipsChanged = (value) => {
    this.setState({
      partnerToswapOwnership: value
    });
  };

  render() {
    const { busy, currentStep, partnerToswapOwnership } = this.state;
    const { isOpen, partner, owner, system } = this.props;
    const singlePartner = partner && partner.length === 1 ? true : false;
    let currentPartnerToswapOwnership = singlePartner ? partner[0].id : this.state.partnerToswapOwnership;
    const partnerOrg = partner && partner.length > 0 && 
    currentPartnerToswapOwnership !== -3 ? partner.find(org => org.id === currentPartnerToswapOwnership) : undefined;

    const partnerOrgName = partnerOrg  ? partnerOrg.name : 'UNKNOWN';
    const systemName = system.name ? system.name: 'UNKNOWN';
    const ownerOrgName = owner ? owner.name : 'UNKNOWN';

    const partnersListTodisplay = partner.map((org) =>
      <li><Radio
        label={org.name}
        name="keepRelationships"
        value={org.id}
        className="keep-rel-radio"
        inputClassName="keep-rel-yes"
        labelClassName="partner-radio"
        checked={partnerToswapOwnership === org.id}
        onChange={this.onKeepRelationshipsChanged}
      /></li>
    );
    const swapMessage = (
      <div className="system-profile-owner-panel">
        <p className="instructions">Select one of the <strong>{systemName}</strong> system's access partners to swap system ownership with:</p>
        <div className="partner-radio-list-section">
          <ul className="partner-radio-list">
            {partnersListTodisplay}
          </ul>
        </div>
      </div>

    );

    return (
      <TitledModal
        title="Swap System Ownership"
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.doCancel}
        className="system-profile-owner-panel minor"
      >
        {currentStep === 1 && !singlePartner ? (
          <div className="grid-x grid-padding-x step-1">
            {swapMessage}
            <div className="cell small-12 divider-cell" />
            <div className="cell small-6 medium-5 medium-offset-1">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.doCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-6 medium-5">
              <Button
                disabled={partnerToswapOwnership === -3  || busy}
                isExpanded
                isHollow
                className="text-uppercase next-btn"
                color="black"
                onClick={this.goToStep.bind(this, 2)}
              >
                Next
              </Button>
            </div>
          </div>
        ) : (currentStep === 2 || singlePartner) ? (
          <div className="grid-x grid-padding-x step-2">
            <div className="cell small-12 instructions">
              <p>
              <strong>{ownerOrgName}</strong> is requesting <strong>{partnerOrgName}</strong> take ownership of the system <strong>{systemName}</strong>.
              </p>
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-12 instructions">
            <p className="instruction-list"><strong>{partnerOrgName}</strong> will become the new system owner.</p>
            <p className="instruction-list"><strong>{ownerOrgName}</strong> will become a system access partner.</p>
            <p className="instruction-list" >All other system access partners will remain access partners.</p>
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-12 instructions">
              <p>
              Are you sure you want to swap system ownership?
              </p>
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-4">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.doCancel}
              >
                Cancel
              </Button>
            </div>
            {!singlePartner ?
            <div className="cell small-4">
              <Button
                isExpanded
                isHollow
                disabled={busy}
                className="text-uppercase back-btn"
                color="black"
                onClick={this.goToStep.bind(this, 1)}
              >
                Back
              </Button>
            </div> : null }
            <div className="cell small-4">
              <Button
                disabled={busy}
                isExpanded
                isHollow
                className="text-uppercase ok-btn"
                color="black"
                onClick={this.doSwapOwner}
              >
                Ok
              </Button>
            </div>
          </div>
        ) : (
          <div>We seem to have encountered an error</div>
        )}
      </TitledModal>
    );
  }
}

// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';
import {Block, Breakpoints, TitleBar, TitleBarTitle} from 'react-foundation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import NotificationIcon from '../notification/notification-icon';
import * as userSvc from '../user/user-service';

import {changeRoute} from '../route';

import './top-nav.css';

import barsIcon from '@fortawesome/fontawesome-free-solid/faBars';

import logo from './images/intelligen-logo.svg';
import dashboardIcon from './images/dashboard-icon.svg';
import helpIcon from './images/help-icon.svg';
import logoutIcon from './images/logout-icon.svg';
import profileIcon from './images/userprofile-icon.svg';

type PropsType = {
  site: object,
  system: string,
  userLoggedIn: boolean
};

type ComponentStateType = {
  menuExpanded: boolean
};

class TopNav extends Component<PropsType, ComponentStateType> {
  state = {menuExpanded: false};

  toggleMenu() {
    this.setState({menuExpanded: !this.state.menuExpanded});
  }

  goToDashboard = () => {
    this.setState({menuExpanded: false});
    changeRoute('systems');
  };

  goToProfile = () => {
    this.setState({menuExpanded: false});
    changeRoute('profile');
  };

  doLogout = async () => {
    await userSvc.logout();
  };

  render() {
    const {menuExpanded} = this.state;
    const {site, system, userLoggedIn} = this.props;

    if (!userLoggedIn) {
      return null;
    }

    const siteName = site ? site.name : null;
    const siteSystem =
      siteName || system ?
        (siteName ? siteName + '/' : '') +
          (system ? system : 'unknown') :
        '';

    return (
      <div className="TopNav">
        <Block showOnlyFor={Breakpoints.SMALL}>
          <TitleBar className="grid-x top-nav-title-bar">
            <div className="top-nav-icons cell small-2">
              <a onClick={this.toggleMenu.bind(this)}>
                <div className="labeled-icon clearfix">
                  <FontAwesomeIcon
                    icon={barsIcon}
                    className="float-left"
                    size="2x"
                  />
                  <div className="text-uppercase text-left icon-label menu-label-txt">
                    Main Menu
                  </div>
                </div>
              </a>
            </div>
            <TitleBarTitle className="cell auto">
              <img className="float-center" src={logo} alt="Logo" />
            </TitleBarTitle>
            <div className="top-nav-icons cell small-2">
              <NotificationIcon />
            </div>
          </TitleBar>
          {menuExpanded ? (
            <div className="navbar-tray grid-x top-nav-icons">
              <div className="cell small-12 navbar-tray-item">
                <a
                  title="Dashboard"
                  className="dashboard-link"
                  onClick={this.goToDashboard}
                >
                  <div className="labeled-icon clearfix">
                    <div className="icon float-left">
                      <img src={dashboardIcon} alt="Dashboard" />
                    </div>
                    <div className="text-uppercase text-left icon-label">
                      Dashboard
                    </div>
                  </div>
                </a>
              </div>
              <div className="cell small-12 navbar-tray-item">
                <a
                  title="Profile"
                  className="profile-link"
                  onClick={this.goToProfile}
                >
                  <div className="labeled-icon clearfix">
                    <div className="icon float-left">
                      <img src={profileIcon} alt="Profile" />
                    </div>
                    <div className="text-uppercase text-left icon-label">
                      Profile
                    </div>
                  </div>
                </a>
              </div>
              <div className="cell small-12 navbar-tray-item">
                <a
                  title="Logout"
                  className="logout-link"
                  onClick={this.doLogout}
                >
                  <div className="labeled-icon clearfix">
                    <div className="icon float-left">
                      <img src={logoutIcon} alt="Logout" />
                    </div>
                    <div className="text-uppercase text-left icon-label">
                      Logout
                    </div>
                  </div>
                </a>
              </div>
              <div className="cell small-12 navbar-tray-item">
                <a
                  title="Support"
                  className="support-link"
                  href="https://www.intelligencontrols.com/homepage/resources"
                  target="_support"
                >
                  <div className="labeled-icon clearfix">
                    <div className="icon float-left">
                      <img src={helpIcon} alt="Support" />
                    </div>
                    <div className="text-uppercase text-left icon-label">
                      Support
                    </div>
                  </div>
                </a>
              </div>
            </div>
          ) : null}
        </Block>
        <Block showFor={Breakpoints.MEDIUM}>
          <TitleBar className="grid-x top-nav-title-bar">
            <div className="top-nav-icons left-set cell medium-5 text-left">
              <a
                title="Dashboard"
                className="dashboard-link"
                onClick={this.goToDashboard}
              >
                <div className="labeled-icon clearfix">
                  <div className="icon float-left">
                    <img src={dashboardIcon} alt="Dashboard" />
                  </div>
                  <div className="text-uppercase text-left icon-label">
                    Dashboard
                  </div>
                </div>
              </a>
              <div className="site-system">{siteSystem}</div>
            </div>
            <TitleBarTitle className="cell auto">
              <img className="float-center" src={logo} alt="Logo" />
            </TitleBarTitle>
            <div className="top-nav-icons right-set cell medium-5 text-right">
              <div className="icon-link">
                <a
                  title="Support"
                  className="support-link icon-only"
                  href="https://www.intelligencontrols.com/homepage/resources"
                  target="_support"
                >
                  <img src={helpIcon} alt="Support" />
                </a>
              </div>
              <div className="icon-link">
                <a
                  title="Profile"
                  className="profile-link icon-only"
                  onClick={this.goToProfile}
                >
                  <img src={profileIcon} alt="Profile" />
                </a>
              </div>
              <div className="icon-link">
                <NotificationIcon />
              </div>
              <div className="icon-link">
                <a
                  title="Logout"
                  onClick={this.doLogout}
                  className="logout-link icon-only"
                >
                  <img src={logoutIcon} alt="Logout" />
                </a>
              </div>
            </div>
          </TitleBar>
        </Block>
      </div>
    );
  }
}

export default watch(TopNav, {
  site: 'selectedSystem.site',
  system: 'selectedSystem.name',
  userLoggedIn: 'sessionInfo.userLoggedIn'
});

// @flow

import React, {Component} from 'react';

import LabelledInput from './labelled-input';
import BaseInput from './base-input';

import {cleanProps, buildClassName} from '../util/component-util';

type PropsType = {
  label: string,
  autoFocus?: ?boolean,
  value?: ?any,
  type?: ?string,
  disabled?: ?boolean,
  onChange?: ?Function,
  className?: ?string,
  inputClassName?: ?string
};

export default class LabelledRawInput extends Component<PropsType> {
  render() {
    const {className, label} = this.props;

    const otherProps = cleanProps(this.props, []);

    return (
      <LabelledInput
        className={buildClassName('labelled-raw-input', className)}
        label={label}
        {...otherProps}
        inputComponent={BaseInput}
      />
    );
  }
}

// @flow

import React, {Component} from 'react';
import {route, getHashParameters} from 'react-hash-route';
import {dispatch, watch} from 'redux-easy';
import {Button} from 'react-foundation';

import MarketingBox from '../general-components/marketing-box';
import MarketingBoxLeft from '../general-components/marketing-box-left';
import CheckBox from '../general-components/check-box';
import LabelledInput from '../labelled-input/labelled-input';
import LabelledPhoneInput from '../labelled-input/labelled-phone-input';
import CaptchaCatcher from '../captcha/captcha-catcher';

import {fetchJson, userUrl} from '../util/rest-util';
import {checkEmail, checkPassword, checkPhone} from '../user/user-validate';
import {different} from '../util/test-util';

import './create-account.css';

import type {CreateAccountFormType} from '../types';

type PropsType = {
  inputs: CreateAccountFormType
};

type MyStateType = {
  errors: string[],
  emailInvalid: boolean,
  confirmEmailInvalid: boolean,
  passwordInvalid: boolean,
  confirmPasswordInvalid: boolean,
  phoneInvalid: boolean,
  showCaptcha: boolean
};

function hasRequiredProps(inputs: CreateAccountFormType) {
  return Boolean(
    inputs.acceptEula &&
      inputs.firstName &&
      inputs.lastName &&
      inputs.phone &&
      inputs.email &&
      inputs.confirmEmail &&
      inputs.password &&
      inputs.confirmPassword
  );
}

export class CreateAccount extends Component<PropsType, MyStateType> {
  oldMsgs: string[] = [];
  constructor() {
    super();
    this.state = {
      errors: [],
      emailInvalid: false,
      confirmEmailInvalid: false,
      passwordInvalid: false,
      confirmPasswordInvalid: false,
      phoneInvalid: false,
      showCaptcha: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    this.validate(nextProps.inputs);
  }

  componentDidMount() {
    this.validate(this.props.inputs);
  }

  validate = (inputs: CreateAccountFormType) => {
    this.clearErrors();
    let newErrors = [];

    const emailErrors = checkEmail(inputs.email);
    if (emailErrors.length > 0) {
      this.setState({
        emailInvalid: true
      });
      newErrors = newErrors.concat(emailErrors);
    }

    const passwordErrors = checkPassword(inputs.password);
    if (passwordErrors.length > 0) {
      this.setState({
        passwordInvalid: true
      });
      newErrors = newErrors.concat(passwordErrors);
    }

    const phoneErrors = checkPhone(inputs.phone);
    if (phoneErrors.length > 0) {
      this.setState({
        phoneInvalid: true
      });
      newErrors = newErrors.concat(phoneErrors);
    }

    if (different(inputs.email, inputs.confirmEmail)) {
      newErrors.push("Emails don't match");
      this.setState({
        confirmEmailInvalid: true
      });
    }

    if (different(inputs.password, inputs.confirmPassword)) {
      newErrors.push("Passwords don't match");
      this.setState({
        confirmPasswordInvalid: true
      });
    }

    this.setState({
      errors: newErrors
    });
  };

  clearErrors = () => {
    this.setState({
      errors: [],
      emailInvalid: false,
      confirmEmailInvalid: false,
      passwordInvalid: false,
      confirmPasswordInvalid: false,
      phoneInvalid: false,
      showCaptcha: false
    });
  };

  onReadyToCreate = () => {
    this.setState({
      showCaptcha: true
    });
  };

  onCaptchaFail = (error: string) => {
    this.setState({
      showCaptcha: false
    });
    dispatch('addErrorToast', error);
  };

  onCreate = async (captchaToken: string) => {
    this.clearErrors();
    const [inviteReference] = getHashParameters();

    const payload = {
      ...this.props.inputs,
      inviteReference,
      captchaToken
    };
    const options = {
      method: 'POST',
      body: JSON.stringify(payload)
    };

    const genericError =
      'Unable to create this account.  If the problem persists, please contact support.';
    const errorToast = {
      type: 'error',
      title: 'Error',
      message: genericError
    };

    try {
      const res = await fetchJson(userUrl('users/'), options);
      const {status} = res;
      // Will get 201 when a new account is created.
      // Will get 200 when an existing, inactive account is overwritten.
      if (status === 200 || status === 201) {
        const createdUser = await res.json();
        dispatch('setCreatedUser', createdUser);
        route('created-account');
      } else if (status === 409) {
        // The email address is already in use by another user.
        this.setState({
          emailInvalid: true,
          confirmEmailInvalid: true
        });
        this.setState({
          errors: this.state.errors.concat('This Email is already in use.')
        });
      } else {
        dispatch('addToast', errorToast);
      }
    } catch (e) {
      dispatch('addToast', errorToast);
    }
  };

  render() {
    const {inputs} = this.props;
    const {
      errors,
      emailInvalid,
      confirmEmailInvalid,
      passwordInvalid,
      confirmPasswordInvalid,
      phoneInvalid,
      showCaptcha
    } = this.state;

    const createEnabled = errors.length === 0 && hasRequiredProps(inputs);

    const links = [
      {
        label: 'Create Account',
        route: 'create-account'
      },
      {
        label: 'Log In',
        route: 'login'
      }
    ];

    const labelWidth = 126;

    const eulaLink = (
      <a
        href="https://intelligen.heatcraftrpd.com/medias/intelliGen-EULA.pdf"
        target="eula"
      >
        <strong>EULA</strong>
      </a>
    );

    return (
      <MarketingBox className="create-account" links={links}>
        <CaptchaCatcher
          isOpen={showCaptcha}
          onFail={this.onCaptchaFail}
          onSuccess={this.onCreate}
        />
        <MarketingBoxLeft />
        <div className="marketing-spacer" />
        <div className="marketing-right">
          <h6 className="page-heading">
            <strong>Create New Account</strong>
          </h6>
          <div className="create-account-inputs">
            <LabelledInput
              label="FIRST NAME*"
              type="text"
              path="forms.createAccount.firstName"
              labelWidth={labelWidth}
              autoFocus
              maxLength={20}
            />
            <LabelledInput
              label="LAST NAME*"
              type="text"
              path="forms.createAccount.lastName"
              labelWidth={labelWidth}
              maxLength={20}
            />
            <LabelledPhoneInput
              label="PHONE*"
              type="text"
              path="forms.createAccount.phone"
              labelWidth={labelWidth}
              isInvalid={phoneInvalid}
            />
            <LabelledInput
              label="EMAIL*"
              isInvalid={emailInvalid}
              type="email"
              path="forms.createAccount.email"
              labelWidth={labelWidth}
              maxLength={50}
            />
            <LabelledInput
              label="CONFIRM EMAIL*"
              isInvalid={confirmEmailInvalid}
              type="email"
              path="forms.createAccount.confirmEmail"
              labelWidth={labelWidth}
              maxLength={50}
            />
            <LabelledInput
              label="PASSWORD*"
              isInvalid={passwordInvalid}
              type="password"
              path="forms.createAccount.password"
              labelWidth={labelWidth}
              maxLength={100}
            />
            <LabelledInput
              label="CONFIRM PASSWORD*"
              isInvalid={confirmPasswordInvalid}
              type="password"
              path="forms.createAccount.confirmPassword"
              labelWidth={labelWidth}
              maxLength={100}
            />
          </div>
          <div className="pw-instructions">
            Min. 8 characters, at least one lowercase and one uppercase letter,
            at least one number and at least one special character.
          </div>
          <div className="terms-and-such">
            <CheckBox
              className="accept-eula terms-check"
              name="accept-eula"
              path="forms.createAccount.acceptEula"
              label="Accept our"
              postLabel={eulaLink}
              postLabelClassName="create-account-cb-label"
              labelClassName="create-account-cb-label"
              styleName="light-blue-square"
            />
          </div>
          <div className="button-box">
            <Button
              className="create-btn text-uppercase"
              isHollow
              disabled={!createEnabled}
              onClick={this.onReadyToCreate}
              color="bright-blue"
            >
              Create New Account
            </Button>
          </div>
          <div className="error-box">
            {errors.length > 0 ?
              errors.map(error => (
                <div key={error} className="text-alert">
                  {error}
                </div>
              )) :
              null}
          </div>
        </div>
      </MarketingBox>
    );
  }
}

export default watch(CreateAccount, {
  inputs: 'forms.createAccount'
});

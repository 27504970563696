// @flow

import React, {Component} from 'react';

import CollapsibleGridContainer from '../general-components/collapsible-grid-container';
import CollapsibleGrid from '../general-components/collapsible-grid';
import ManageSystemItem from './manage-system-item';
import systemSvc from './system-service';
import { sortSystems } from './systems-util';
import './manage-systems-site-box.css';

// eslint-disable-next-line no-duplicate-imports
import type {ColumnDescriptorType} from '../general-components/collapsible-grid';
import type {UserType, SystemType} from '../types';
import {reorder} from './reorder';

type PropsType = {
  siteName: string,
  siteAddress: string,
  systems: SystemType[],
  user: UserType
};

const COLUMNS: ColumnDescriptorType[] = [
  {
    label: 'System Name',
    size: 3,
    className: 'name-header',
    dataClassName: 'system-name'
  },
  {
    label: 'System Owner',
    size: 2,
    className: 'owner-header',
    dataClassName: 'system-owner'
  },
  {
    label: 'System Access Partners',
    size: 4,
    className: 'partner-header',
    dataClassName: 'system-partner'
  },
  {
    label: '',
    size: 2,
    className: 'edit-btn-header',
    dataClassName: 'edit-btn'
  },
  {
    className: 'reorder-buttons',
    dataClassName: 'reorder-btns',
    label: '',
    size: 1
  }
];

export default class ManageSystemsSiteBox extends Component<PropsType> {
  state = {
    systems: []
  };

  componentDidMount() {
    const sortedSystems = sortSystems(this.props.systems);

    this.setState({systems: sortedSystems});
  }

  componentDidUpdate() {
    if(this.props.systems.length !== this.state.systems.length) {
      const sortedSystems = sortSystems(this.props.systems);

      this.setState({systems: sortedSystems});
    }
  }
  
  onMove(source, destination) {
    const reorderedSystemsList = reorder(
      this.state.systems,
      source,
      destination
    );
    this.setState({systems: reorderedSystemsList});

    const postBody = reorderedSystemsList.map((v, i) => {
      return {
        systemId: v.id,
        organizationId: v.sortOrgId,
        sortOrder: i
      };
    });

    this.updateSystems(postBody);
  }

  async updateSystems(systems) {
    await systemSvc.updateSystems(systems);
    await systemSvc.refreshSystems();
  }

  render() {
    const {siteName, siteAddress, user} = this.props;
    const s = this.state.systems.length === 1 ? '' : 's';

    return (
      <CollapsibleGridContainer className="manage-systems-site-box">
        <div className="grid-x">
          <div className="cell small-12 text-uppercase site-tag">Site</div>
          <div className="cell small-12 medium-8 site-name text-uppercase">
            {siteName}
          </div>
          <div className="cell small-12 medium-4 system-count text-uppercase">
            {this.state.systems.length} System{s}
          </div>
          <div className="cell small-12 site-address">{siteAddress}</div>
        </div>
        <div className="collapsible-grid-divider" />
        <CollapsibleGrid className="manage-systems-site-grid" columns={COLUMNS}>
          {this.state.systems.map((system, i) => (
            <ManageSystemItem
              key={system.id}
              columns={COLUMNS}
              system={system}
              user={user}
              onUp={() => this.onMove(i, i - 1)}
              onDown={() => this.onMove(i, i + 1)}
              systemsLength={this.state.systems.length}
              index={i}
            />
          ))}
        </CollapsibleGrid>
      </CollapsibleGridContainer>
    );
  }
}

// @flow

import React, {Component} from 'react';

import {Block, Breakpoints} from 'react-foundation';

import CollapsibleGridHeaderLabel from './collapsible-grid-header-label';
import {buildClassName} from '../util/component-util';

import './collapsible-grid.css';

export type ColumnDescriptorType = {
  label: string,
  size: number,
  largeSize?: number,
  className?: string,
  dataClassName?: string
};

type PropsType = {
  columns: ColumnDescriptorType[],
  className?: ?string
};

export default class CollapsibleGrid extends Component<PropsType> {
  render() {
    // ESLint and Flow really don't like me using the 'children'
    // property.
    /* eslint-disable react/prop-types */
    // $FlowFixMe -- props.children is provided by React
    const {children} = this.props;

    const {columns, className} = this.props;

    const myClassName = buildClassName('collapsible-grid', 'grid-x', className);

    return (
      <div className={myClassName}>
        <Block
          className="cell small-12 collapsible-grid-header-row"
          showFor={Breakpoints.MEDIUM}
        >
          <div className="collapsible-grid-header-row-container grid-x grid-padding-x">
            {columns.map((column, i) => (
              <div
                key={i}
                className={buildClassName(
                  'cell',
                  column.largeSize ? `large-${column.largeSize}` : null,
                  `medium-${column.size}`,
                  'text-uppercase',
                  'collapsible-grid-header',
                  column.className
                )}
              >
                <CollapsibleGridHeaderLabel>
                  {column.label}
                </CollapsibleGridHeaderLabel>
              </div>
            ))}
          </div>
        </Block>
        <div className="cell small-12 collapsible-grid-content">{children}</div>
      </div>
    );
  }
}

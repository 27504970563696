// @flow

import React, { Component } from 'react';

import { Button } from 'react-foundation';

import { getDate } from '../util/moment-util';
import TitledPanel from '../general-components/titled-panel';
import AddPartnerModal from './add-partner-modal';
import LabelledCell from '../general-components/labelled-cell';
import YesOrNo from '../general-components/yes-or-no';

import sysSvc from './system-service';

import { buildCondensedAddress } from '../util/component-util';

import type {
  SystemOrgRelType,
  SystemInvitationType,
  QuestionableSystemOrgRelNameType,
  SystemType
} from '../types';

import './system-profile-partner-panel.css';

type PropsType = {
  partner: ?SystemOrgRelType,
  partnerInvite: ?SystemInvitationType,
  ownerInvite: ?SystemInvitationType,
  system: SystemType,
  userRelType: QuestionableSystemOrgRelNameType,
  onInformationChanged: Function
};

type StateType = {
  addPartnerOpen: boolean,
  removePartnerOpen: boolean,
  removePartnerBusy: boolean,
  cancelInviteBusy: boolean,
  partnerToRemove: Object,
  invitedPartnerToCancel: Object
};

class SystemProfilePartnerPanel extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      addPartnerOpen: false,
      removePartnerOpen: false,
      removePartnerBusy: false,
      cancelInviteBusy: false,
      partnerToRemove: {},
      invitedPartnerToCancel: {}
    };
  }

  openAddPartnerConfirm = () => {
    this.setState({
      addPartnerOpen: true
    });
  };

  closeAddPartner = () => {
    this.setState({
      addPartnerOpen: false
    });
  };

  cancelInvite = async (event, invitedPartnerToCancel) => {
    const { system, onInformationChanged } = this.props;
    if (invitedPartnerToCancel && invitedPartnerToCancel.id) {
      this.setState({
        cancelInviteBusy: true
      });

      await sysSvc.cancelSystemInvite(system.id, invitedPartnerToCancel.id);

      this.setState({
        cancelInviteBusy: false
      });

      onInformationChanged();
    }
  };

  openRemovePartnerConfirm(event, partnerToRemove) {
    this.setState({
      partnerToRemove: partnerToRemove,
      removePartnerOpen: true
    });
  };

  removePartnerYes = async () => {
    const { system, onInformationChanged } = this.props;
    if (this.state.partnerToRemove.id) {
      this.setState({
        removePartnerBusy: true
      });
      await sysSvc.removeOrgFromSystem(system.id, this.state.partnerToRemove.id);
      this.setState({
        removePartnerOpen: false,
        removePartnerBusy: false,
        partnerToRemove: {}
      });
      onInformationChanged();
    }
  };

  removePartnerNo = () => {
    this.setState({
      removePartnerOpen: false,
      partnerToRemove: {}
    });
  };

  addPartnerDone = () => {
    const { onInformationChanged } = this.props;
    this.setState({
      addPartnerOpen: false
    });

    onInformationChanged();
  };

  render() {
    const {
      addPartnerOpen,
      removePartnerOpen,
      removePartnerBusy,
      cancelInviteBusy
    } = this.state;

    const {
      partner,
      partnerInvite,
      ownerInvite,
      system,
      userRelType
    } = this.props;

    const partnerToDisplay = partner ?
      partner :
      {
        id: -1,
        type: 'Partner',
        name: 'None',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        email: '',
        phone: '',
        website: ''
      };

    const userIsOwner = userRelType === 'Owner';

    const title = 'System Access Partners';
    let partnerNametoRemove = this.state.partnerToRemove.name ? this.state.partnerToRemove.name : 'unknown';
    let systemName = system.name ? system.name : 'unknown';
    const activeOwnerInvite = Boolean(ownerInvite);
    const partnerInviteCount = partnerInvite ? partnerInvite.length : 0;
    const partnersCount = partner ? partner.length : 0;
    const totalPartnersAndInvitations = partnersCount + partnerInviteCount;
    const invitePartnerDisabled = activeOwnerInvite || (totalPartnersAndInvitations >= 4) || Boolean(system.site);
    const removePartnerButtonDisabled = Boolean(system.site);
    const revokePartnerAccessMessage = (
      <div>
        <p>
          Are you sure you want to remove <strong>{partnerNametoRemove}</strong>'s system access to <strong>{systemName}</strong>?
        </p>
        <p>
          <strong>{partnerNametoRemove}</strong> will no longer have system access to system <strong>{systemName}</strong>.
        </p>
      </div>
    );

    return (
      <TitledPanel title={title} className="SystemProfilePartnerPanel">
        <AddPartnerModal
          isOpen={addPartnerOpen}
          onRequestClose={this.closeAddPartner}
          onSuccess={this.addPartnerDone}
          systemId={system.id}
        />
        <YesOrNo
          title="Remove Partner System Access"
          message={revokePartnerAccessMessage}
          isOpen={removePartnerOpen}
          disabled={removePartnerBusy}
          onYes={this.removePartnerYes}
          onNo={this.removePartnerNo}
          yesText="Yes"
        />
        <div className="grid-x grid-padding-x invite-partners">
          <div className="cell small-12 medium-4"> {userIsOwner ?
            <Button
              isExpanded
              isHollow
              color="little-blue"
              className="text-uppercase"
              onClick={this.openAddPartnerConfirm}
              disabled={invitePartnerDisabled}
            >
              Invite Partner
            </Button> : null}
          </div>
          <div className="cell small-12 medium-4"></div>
          <div className="cell small-12 medium-4 partner-count text-uppercase">
            {partner.length + partnerInvite.length} of 4 partners
          </div>
        </div>
        {partner.length > 0 || !userIsOwner ? partner.map((org) => {
          return (
            <div className="grid-x grid-padding-x bottom-part">
              <div className="cell small-12 medium-4">
                <LabelledCell label="Access Partner">
                  {org.name}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Email">
                  {org.email}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Website">
                  {org.website}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Address">
                  {buildCondensedAddress(org)}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Phone">
                  {org.phone}
                </LabelledCell>
              </div>
              {userIsOwner ? (
                <div className="cell small-12 medium-4">
                  <div className="partner-buttons">
                    <Button
                      isHollow
                      isExpanded
                      color="little-blue"
                      className="text-uppercase"
                      onClick={(event) => this.openRemovePartnerConfirm(event, org)}
                      disabled={removePartnerButtonDisabled || (ownerInvite && ownerInvite.swapRelationships && org.id === ownerInvite.inviteeOrgId)}
                    >
                      Remove Partner
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>)
        }) : null
        }
        {partnerInvite.length > 0 && partnerInvite.map((invite) => {
          return (
            <div className="grid-x grid-padding-x bottom-part">
              <div className="cell small-12 medium-4">
                <LabelledCell label="Access Partner">
                  {invite.inviteeOrgName}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Email">
                  {invite.inviteeEmail}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4"></div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Status">
                  Invitation to {invite.inviteeOrgName}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Expires On">
                  {invite.invitationExpires ? getDate(invite.invitationExpires).format('MM/DD/YYYY hh:mm a') : ''}
                </LabelledCell>
              </div>
              {userIsOwner ? (
                <div className="cell small-12 medium-4">
                  <div className='partner-buttons'>
                    <Button
                      isHollow
                      isExpanded
                      onClick={(event) => this.cancelInvite(event, invite)}
                      color="little-blue"
                      className="text-uppercase"
                      disabled={removePartnerButtonDisabled}
                    >
                      Cancel System Access Invitation
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>)
        })
        }
      </TitledPanel>
    );
  }
}

export default SystemProfilePartnerPanel;

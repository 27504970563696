// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';
import {Button} from 'react-foundation';

import GeneralPage from '../general-components/general-page';
import GeneralPageEmptyMessage from '../general-components/general-page-empty-message';
import ManageSystemsSiteBox from './manage-systems-site-box';
import RegisterSystemModal from './register-system-modal';

import {changeRoute} from '../route';
import {buildCondensedAddress} from '../util/component-util';
import sysSvc from './system-service';

import type {SystemType, UserType, SiteSystemsType} from '../types';

type PropsType = {
  systems: SystemType[],
  systemsBySite: SiteSystemsType,
  user: UserType
};

type MyStateType = {
  registerSystemOpen: boolean
};

class ManageSystems extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      registerSystemOpen: false
    };
  }

  componentDidMount() {
    sysSvc.refreshSystems();
  }

  openRegisterSystem = () => {
    this.setState({
      registerSystemOpen: true
    });
  };

  closeRegisterSystem = () => {
    this.setState({
      registerSystemOpen: false
    });
    sysSvc.refreshSystems();
  };

  render() {
    const {systems, systemsBySite, user} = this.props;
    const {siteSystems, noSiteSystems} = systemsBySite;
    const {registerSystemOpen} = this.state;
    if (!user.authenticated) return changeRoute('login');

    const s = systems.length === 1 ? '' : 's';

    return (
      <GeneralPage
        title="Manage Systems"
        className="manage-systems"
        contentClassName="manage-systems-content grid-x grid-padding-x grid-margin-x"
        subText={`Total of ${systems.length} system${s}`}
      >
        <RegisterSystemModal
          isOpen={registerSystemOpen}
          onRequestClose={this.closeRegisterSystem}
          onSuccess={this.closeRegisterSystem}
        />
        <div className="cell large-3 large-offset-9 medium-4 medium-offset-8 small-6 small-offset-6 btn-cell">
          <Button
            isHollow
            isExpanded
            className="text-uppercase"
            onClick={this.openRegisterSystem}
          >
            Register New System
          </Button>
        </div>
        {systems.length === 0 ? (
          <div className="cell small-12">
            <GeneralPageEmptyMessage>
              <div>
                You don’t have running systems associated with your organization
              </div>
              <div>
                Please go to Register System or ask another System’s admin to be
                invited.
              </div>
            </GeneralPageEmptyMessage>
          </div>
        ) : null}
        {siteSystems.length > 0 ?
          siteSystems.map(siteGroup => (
            <div className="cell small-12" key={siteGroup.site.id}>
              <ManageSystemsSiteBox
                siteName={siteGroup.site.name}
                siteAddress={buildCondensedAddress(siteGroup.site)}
                systems={siteGroup.systems}
                user={user}
              />
            </div>
          )) :
          null}
        {noSiteSystems.length > 0 ? (
          <div className="cell small-12">
            <ManageSystemsSiteBox
              siteName="No Site Assigned"
              siteAddress="SYSTEMS NOT ASSIGNED TO ANY SITE"
              systems={noSiteSystems}
              user={user}
            />
          </div>
        ) : null}
      </GeneralPage>
    );
  }
}

export default watch(ManageSystems, {
  systems: 'systems',
  systemsBySite: 'systemsBySite',
  user: 'user'
});

// @flow

import React, {Component} from 'react';

import InviteUserToSystemModal from './invite-user-to-system-modal';

import {buildClassName} from '../util/component-util';

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onSuccess: Function,
  systemId: string,
  className?: ?string
};

class AddPartnerModal extends Component<PropsType> {
  render() {
    const {isOpen, onRequestClose, onSuccess, systemId, className} = this.props;

    return (
      <InviteUserToSystemModal
        title="Grant System Access"
        isOpen={isOpen}
        relationship="Partner"
        text="Please type the email address of the company point of contact you wish to grant access to:"
        onRequestClose={onRequestClose}
        onInviteSuccess={onSuccess}
        systemId={systemId}
        className={buildClassName('add-partner-modal', className)}
      />
    );
  }
}

export default AddPartnerModal;

// @flow

import {logoutWithError} from '../user/user-service';

function envUrl(envName: string, path: string): string {
  const value = process.env[envName] || '';
  return value + path;
}

function fetchContentType(
  contentType: string,
  url: string,
  options: Object = {headers: {}},
  skipDefaultHandling: boolean = false
) {
  const opts = {
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': contentType
    }
  };
  return fetchGlobal(url, opts, skipDefaultHandling);
}

export function fetchGlobal(
  url: string,
  options: Object = {headers: {}},
  skipDefaultHandling: boolean = false
) {
  const defaultHeaders = {
    'Access-Control-Allow-Headers': 'ETag'
  };
  const defaultOptions = {
    credentials: 'include',
    method: 'GET'
  };
  const {headers} = options;
  const opts = {
    ...defaultOptions,
    ...options,
    headers: {...defaultHeaders, ...headers}
  };
  return fetch(url, opts).then(result => {
    if (!skipDefaultHandling) {
      let message = '';

      if (result.status === 403 || result.status === 401) {
        message =
          'Your session may have expired.  Please log back in to continue.';
      } else if (result.status === 423) {
        message =
          'Your account has been locked.  Please contact Customer Support at (800) 537-7775.';
      }

      if (message) {
        logoutWithError(message);
        return Promise.reject();
      }
    }
    return result;
  });
}

export function fetchJson(
  url: string,
  options?: Object,
  skipDefaultHandling: boolean = false
) {
  return fetchContentType(
    'application/json',
    url,
    options,
    skipDefaultHandling
  );
}

export function fetchText(
  url: string,
  options?: Object,
  skipDefaultHandling: boolean = false
) {
  return fetchContentType('text/plain', url, options, skipDefaultHandling);
}

export function systemUrl(path: string): string {
  return userUrl(path);
}

export function userUrl(path: string): string {
  return envUrl('REACT_APP_PRISM_URL', path);
}

// @flow

import {dispatch, dispatchSet} from 'redux-easy';
import {fetchJson, userUrl} from '../util/rest-util';

import type {NewOrgType, OrgType, UserOrgRoleType} from '../types';

async function refreshOrgStats(organizationId: number): Promise<void> {
  const options = {
    method: 'GET'
  };

  const url = userUrl(`organizations/${organizationId}/stats`);

  const result = await fetchJson(url, options);

  if (result.status === 200) {
    const stats = await result.json();
    dispatchSet('orgStats', stats);
  } else {
    dispatch(
      'addErrorToast',
      'Unable to retrieve company statistics. If this problem persists, please contact support.'
    );
  }
}

async function refreshOrgMemberList(organizationId: number): Promise<Response> {
  const options = {
    method: 'GET'
  };
  const url = userUrl(`organizations/${organizationId}/members`);

  const result = await fetchJson(url, options);

  if (result.status === 200) {
    const members = await result.json();

    dispatch('updateOrgMembers', members);
  } else {
    dispatch(
      'addErrorToast',
      'Unable to retrieve company members. If this problem persists, please contact support.'
    );
    dispatch('clearOrgMembers');
  }

  return result;
}

async function createOrg(newOrg: NewOrgType): Promise<OrgType> {
  const options = {
    method: 'POST',
    body: JSON.stringify(newOrg)
  };

  const url = userUrl('organizations/');

  const result = await fetchJson(url, options);

  if (result.status === 200) {
    return result.json();
  }

  dispatch(
    'addErrorToast',
    'Unable to create this company. If this problem persists, please contact support.'
  );

  return Promise.reject();
}

async function updateOrg(orgId: number, org: NewOrgType): Promise<OrgType> {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(org)
  };
  const url = userUrl(`organizations/${orgId}`);

  const result = await fetchJson(url, options);

  if (result.status === 200) {
    return result.json();
  }

  dispatch(
    'addErrorToast',
    'Unable to update company information.  If this problem persists, please contact support.'
  );

  return Promise.reject();
}

async function getOrg(orgId: number): Promise<OrgType> {
  const options = {
    method: 'GET'
  };

  const url = userUrl(`organizations/${orgId}`);

  const result = await fetchJson(url, options);

  if (result.status === 200) {
    return result.json();
  }

  dispatch(
    'addErrorToast',
    'Unable to retrieve company information.  If this problem persists, please contact support.'
  );

  return Promise.reject();
}

async function updateUserRoles(
  orgId: number,
  userId: number,
  roles: UserOrgRoleType[]
): Promise<void> {
  const options = {
    method: 'PUT',
    body: JSON.stringify(roles)
  };

  const url = userUrl(`organizations/${orgId}/members/${userId}/roles`);

  const result = await fetchJson(url, options);

  if (result.status === 409) {
    const json = await result.json();
    dispatch('addErrorToast', json.message);
  } else if (result.status !== 204) {
    dispatch(
      'addErrorToast',
      'Unable to update user roles.  If this problem persists, please contact support.'
    );

    return Promise.reject();
  }
}

async function removeUserFromOrg(orgId: number, userId: number): Promise<void> {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      userIdToRemove: userId
    })
  };
  const url = userUrl(`organizations/${orgId}/removeUser`);

  const result = await fetchJson(url, options);

  if (result.status === 409) {
    const json = await result.json();
    dispatch('addErrorToast', json.message);

    return Promise.reject();
  } else if (result.status !== 200) {
    dispatch(
      'addErrorToast',
      'Unable to remove user from org.  If this problem persists, please contact support.'
    );

    return Promise.reject();
  }
}

async function inviteUserToOrg(
  orgId: number,
  email: string,
  inviteAsSpectator: boolean,
  inviteAsServicer: boolean,
  inviteAsAdmin: boolean
): Promise<any> {
  const inviteRoleMap = {
    Spectator: inviteAsSpectator,
    Servicer: inviteAsServicer,
    Administrator: inviteAsAdmin
  };

  const inviteeRoles = Object.keys(inviteRoleMap).filter(
    key => inviteRoleMap[key]
  );

  const options = {
    method: 'POST',
    body: JSON.stringify({
      email,
      inviteeRoles
    })
  };

  const url = userUrl(`organizations/${orgId}/invitations`);

  const result = await fetchJson(url, options);

  const success = result.status === 200;
  const userFeedback = result.status === 400;

  let resultMessage = '';

  if (userFeedback) {
    const payload = await result.json();
    resultMessage = payload.message;
  }

  return {
    success,
    message: resultMessage
  };
}

async function refreshOrgInviteList(orgId: number): Promise<void> {
  const options = {
    method: 'GET'
  };

  const url = userUrl(`organizations/${orgId}/invitations`);

  const result = await fetchJson(url, options);

  if (result.status === 200) {
    const invites = await result.json();
    dispatchSet('orgInvitations', invites);
  } else {
    dispatch(
      'addErrorToast',
      'Encountered an issue while retrieving invitations for this organization'
    );
  }
}

export {
  createOrg,
  getOrg,
  inviteUserToOrg,
  refreshOrgMemberList,
  refreshOrgStats,
  refreshOrgInviteList,
  removeUserFromOrg,
  updateOrg,
  updateUserRoles
};

// @flow

import {dispatch, dispatchSet} from 'redux-easy';
import {fetchJson, systemUrl} from '../util/rest-util';

import sysService from '../systems/system-service';

import type {SiteFormType} from '../types';

async function refreshSites() {
  const res = await fetchJson(systemUrl('sites/'));
  if (res.status === 200) {
    const sites = await res.json();
    dispatchSet('sites', sites);
  } else {
    dispatch('addErrorToast', 'Encountered error while retrieving sites.');
  }
}

async function cancelSiteInvite(siteId: string, inviteId: number) {
  const options = {
    method: 'DELETE'
  };
  const url = systemUrl(`sites/${siteId}/invites/${inviteId}`);

  const result = await fetchJson(url, options);

  if (result.status !== 204) {
    dispatch('addToast', {
      type: 'warning',
      title: 'Warning!',
      message: 'Unable to cancel this invitation!'
    });
  }
}

async function getSiteInvites(siteId: number) {
  const res = await fetchJson(systemUrl(`sites/${siteId}/invites`));

  if (res.status === 200) {
    const invites = await res.json();
    dispatchSet('selectedSiteInvites', invites);
  } else {
    dispatch(
      'addErrorToast',
      'Encountered error while retrieving site invitations'
    );
  }
}

async function selectSite(siteId: number) {
  const errorMessage = 'Encountered error while retrieving site.';

  const res = await fetchJson(systemUrl('sites/'));
  if (res.status === 200) {
    const sites = await res.json();
    const selectedSite = sites.find(site => site.id === siteId);
    if (selectedSite) {
      dispatchSet('selectedSite', selectedSite);
      dispatchSet('forms.editSite', selectedSite);
      await sysService.updateSystemsForSelectedSite(siteId);
      await getSiteInvites(siteId);
    } else {
      dispatch('addErrorToast', errorMessage);
    }
  } else {
    dispatch('addErrorToast', errorMessage);
  }
}

type GrantSiteAccessType = {
  success: boolean,
  message: string
};

async function grantSiteAccess(
  siteId: number,
  email: string
): Promise<GrantSiteAccessType> {
  const res = await fetchJson(systemUrl(`sites/${siteId}/grantAccess`), {
    method: 'POST',
    body: JSON.stringify({
      emailToGrant: email
    })
  });

  if (res.status === 204) {
    return {
      success: true,
      message: ''
    };
  }

  const body = await res.json();
  const message = body.message ? body.message : 'Unexpected error occurred.';

  return {
    success: false,
    message
  };
}

async function removeSiteAccess(siteId: number, orgId: number): Promise<void> {
  const res = await fetchJson(systemUrl(`sites/${siteId}/removeAccess`), {
    method: 'POST',
    body: JSON.stringify({
      orgId: orgId
    })
  });

  if (res.status !== 204) {
    dispatch(
      'addErrorToast',
      'Encountered error while removing an access organization from this site.'
    );
  }
}

type CheckSiteNameResultType = {
  valid: boolean,
  message: string
};

async function checkSiteName(
  siteName: string
): Promise<CheckSiteNameResultType> {
  const res = await fetchJson(systemUrl('sites/nameCheck'), {
    method: 'POST',
    body: JSON.stringify({
      name: siteName
    })
  });

  if (res.status === 204) {
    return {
      valid: true,
      message: ''
    };
  } else if (res.status === 409 || res.status === 400) {
    const resultBody = await res.json();
    return {
      valid: false,
      message: resultBody.message
    };
  }

  dispatch('addErrorToast', 'Encountered error while checking site name.');
  return {
    valid: false,
    message: ''
  };
}

type CreateSiteResultType = {
  successful: boolean,
  nameErrorMessage: string,
  errorMessage: string
};

async function createSite(
  newSite: SiteFormType
): Promise<CreateSiteResultType> {
  const res = await fetchJson(systemUrl('sites/'), {
    method: 'POST',
    body: JSON.stringify(newSite)
  });

  if (res.status === 201) {
    return {
      successful: true,
      nameErrorMessage: '',
      errorMessage: ''
    };
  } else if (res.status === 409) {
    const resultBody = await res.json();
    return {
      successful: false,
      nameErrorMessage: resultBody.message,
      errorMessage: ''
    };
  } else if (res.status === 400) {
    const resultBody = await res.json();
    return {
      successful: false,
      nameErrorMessage: '',
      errorMessage: resultBody.message
    };
  }

  dispatch('addErrorToast', 'Encountered error while creating a new site.');

  return {
    successful: false,
    nameErrorMessage: '',
    errorMessage: ''
  };
}

async function deleteSite(siteId: number): Promise<void> {
  const res = await fetchJson(systemUrl(`sites/${siteId}`), {
    method: 'DELETE'
  });

  if (res.status !== 204) {
    dispatch(
      'addErrorToast',
      'Encountered error while attempting to remove this site.'
    );
  }
}

async function updateSite(siteId: number, input: SiteFormType): Promise<void> {
  const res = await fetchJson(systemUrl(`sites/${siteId}`), {
    method: 'PUT',
    body: JSON.stringify(input)
  });

  if (res.status === 200) {
    const resultSite = await res.json();
    dispatchSet('selectedSite', resultSite);
    dispatchSet('forms.editSite', resultSite);
  } else {
    dispatch(
      'addErrorToast',
      'Encountered error while attempting to update site.'
    );
  }
}

type TransferSiteResultType = {
  success: boolean,
  message: string
};

async function transferSiteOwnership(
  siteId: number,
  emailToInvite: string,
  keepExistingRelationships: boolean
): Promise<TransferSiteResultType> {
  const res = await fetchJson(systemUrl(`sites/${siteId}/transferOwnership`), {
    method: 'POST',
    body: JSON.stringify({
      emailToInvite,
      keepExistingRelationships
    })
  });

  if (res.status === 204) {
    return {
      success: true,
      message: ''
    };
  }

  const body = await res.json();
  const message =
    body && body.message ? body.message : 'Unknown Error Occurred';

  return {
    success: false,
    message
  };
}

async function swapSiteOwnership(siteId, inviteeOrg)  {
  const res = await fetchJson(systemUrl(`sites/${siteId}/swapOwnership`), {
    method: 'POST',
    body: JSON.stringify(inviteeOrg)
  });

  if (res.status !== 204) {
    dispatch(
      'addErrorToast',
      'Encountered error while attempting to swap site ownership'
    );
  }
}

async function removeSystem(
  siteId: number,
  systemId: string,
  preserveAccessRights: boolean
): Promise<void> {
  const res = await fetchJson(systemUrl(`sites/${siteId}/removeSystem`), {
    method: 'POST',
    body: JSON.stringify({
      systemId,
      preserveAccessRights
    })
  });

  if (res.status !== 204) {
    dispatch(
      'addErrorToast',
      'Encountered error while attempting to remove this system from the site.'
    );
  }
}

async function addSystems(siteId: number, systemIds: string[]): Promise<void> {
  const errorMessage =
    'Unable to add some systems to this site.  Please try again.';

  const requests = systemIds.map(sysId =>
    fetchJson(systemUrl(`sites/${siteId}/addSystem`), {
      method: 'POST',
      body: JSON.stringify({
        systemId: sysId
      })
    })
  );

  try {
    const results = await Promise.all(requests);
    if (results.some(result => result.status !== 204)) {
      dispatch('addErrorToast', errorMessage);
    }
  } catch (e) {
    dispatch('addErrorToast', errorMessage);
  }
}

export default {
  refreshSites,
  selectSite,
  checkSiteName,
  cancelSiteInvite,
  getSiteInvites,
  createSite,
  deleteSite,
  updateSite,
  grantSiteAccess,
  removeSiteAccess,
  transferSiteOwnership,
  swapSiteOwnership,
  addSystems,
  removeSystem
};

// @flow

import React, {Component} from 'react';
import {getHashParameters} from 'react-hash-route';
import {dispatch, watch} from 'redux-easy';
import {Button} from 'react-foundation';

import MarketingBox from '../general-components/marketing-box';

import LabelledInput from '../labelled-input/labelled-input';
import {checkPassword} from '../user/user-validate';
import {changeRoute} from '../route';
import type {UserType} from '../types';
import {fetchJson, userUrl} from '../util/rest-util';

import './password-change.css';

type PropsType = {
  user: UserType
};

type MyStateType = {
  busy: boolean,
  errors: string[],
  passwordsMatch: boolean
};

class PasswordChange extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      busy: false,
      errors: [],
      passwordsMatch: true
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.validate(nextProps.user);
  }

  onChangePassword = async () => {
    try {
      // Prepare the options for a REST call to change the password.
      const {user} = this.props;
      const [ml] = getHashParameters();

      const {password} = user;
      const body = {ml, password};
      const options = {
        method: 'POST',
        body: JSON.stringify(body)
      };

      // Make the REST call to change the password.
      const res = await fetchJson(userUrl('users/changePassword'), options);

      // Evaluate the response.
      const {status} = res;
      // istanbul ignore else
      if (status === 204) {
        dispatch('logout');
        changeRoute('login');
      } else {
        console.error('password-change: onChangePassword: status =', status);
        dispatch(
          'addErrorToast',
          'Unable to complete password change.  If you continue to experience problems, please contact support'
        );
      }
    } catch (e) {
      console.error('password-change: onChangePassword: e =', e);
      dispatch(
        'addErrorToast',
        'Unable to complete password change.  If you continue to experience problems, please contact support'
      );
    }
  };

  validate = user => {
    const {password, confirmPassword} = user;
    const errors = checkPassword(password);
    const passwordsMatch = password === confirmPassword;
    if (!passwordsMatch) {
      errors.push('Passwords do not match.');
    }

    this.setState({
      errors,
      passwordsMatch
    });
  };

  onKeyPress = event => {
    const isEnter = event.key === 'Enter' || event.keyCode === 13;
    if (isEnter) this.onChangePassword();
  };

  render() {
    const {user} = this.props;
    const {busy, errors, passwordsMatch} = this.state;

    const {password} = user;
    const changeEnabled =
      !busy && Boolean(password) && errors.length === 0 && passwordsMatch;

    const links = [
      {
        label: 'Log In',
        route: 'login'
      }
    ];

    const labelWidth = 122;

    return (
      <MarketingBox className="password-change" links={links}>
        <div className="marketing-left" />
        <div className="marketing-spacer" />
        <div className="marketing-right">
          <h4 className="change-desc">
            <strong>Change your password</strong>
          </h4>
          <div className="inputs">
            <LabelledInput
              autoFocus
              className="password"
              label="PASSWORD"
              type="password"
              path="user.password"
              isInvalid={errors.length > 0}
              labelWidth={labelWidth}
              maxLength={100}
            />
            <LabelledInput
              className="confirm-password"
              label="CONFIRM PASSWORD"
              onKeyPress={this.onKeyPress}
              type="password"
              path="user.confirmPassword"
              isInvalid={!passwordsMatch}
              labelWidth={labelWidth}
              maxLength={100}
            />
            <p className="pw-instructions">
              Min. 8 characters, at least one lowercase and one uppercase
              letter, at least one number and at least one special character.
            </p>
            <div className="error-box">
              {errors.length > 0 ?
                errors.map(error => (
                  <div key={error} className="text-alert">
                    {error}
                  </div>
                )) :
                null}
            </div>
          </div>
          <div className="button-box">
            <Button
              className="change-btn text-uppercase"
              disabled={!changeEnabled}
              onClick={this.onChangePassword}
              isHollow
              color="light-blue"
            >
              Change Password
            </Button>
          </div>
        </div>
      </MarketingBox>
    );
  }
}

export default watch(PasswordChange, {
  user: 'user'
});

// @flow

import React, {Component} from 'react';
import {Input, dispatchSet, getPath} from 'redux-easy';

import {buildClassName} from '../util/component-util';

import './check-box.css';

type PropsType = {
  path?: string,
  checked?: boolean,
  name: string,
  disabled?: boolean,
  label?: any,
  postLabel?: any,
  styleName?: string,
  labelClassName?: string,
  postLabelClassName?: string,
  inputClassName?: string,
  className?: ?string,
  onChange?: Function,
  onClick?: Function
};

const noOp = () => {};

class CheckBox extends Component<PropsType> {
  onInternalClick = () => {
    const {disabled, path, onChange, onClick} = this.props;

    if (!disabled) {
      if (path) {
        const value = getPath(path);
        dispatchSet(path, !value);
      }
      if (onChange) {
        setTimeout(onChange);
      }
      if (onClick) {
        setTimeout(onClick);
      }
    }
  };

  render() {
    const {
      path,
      checked,
      name,
      disabled,
      label,
      postLabel,
      styleName,
      labelClassName,
      postLabelClassName,
      inputClassName,
      className
    } = this.props;

    const topClass = buildClassName(
      'prism-check-box',
      className,
      styleName ? `styled-${styleName}` : 'unstyled'
    );

    const labelClass = buildClassName(
      'prism-check-box-label',
      labelClassName,
      disabled ? 'disabled' : null,
      !label ? 'empty-label' : null
    );

    const postLabelClass = buildClassName(
      'prism-check-box-post-label',
      postLabelClassName
    );

    const inputClass = buildClassName('prism-check-box-input', inputClassName);

    const MyInput = path ? (
      <Input
        type="checkbox"
        path={path}
        name={name}
        className={inputClass}
        disabled={disabled}
        onChange={noOp}
        onClick={this.onInternalClick}
      />
    ) : (
      <input
        type="checkbox"
        checked={checked}
        name={name}
        className={inputClass}
        disabled={disabled}
        onChange={noOp}
        onClick={this.onInternalClick}
      />
    );

    return (
      <div className={topClass}>
        {MyInput}{' '}
        <label
          className={labelClass}
          htmlFor={name}
          onClick={this.onInternalClick}
        >
          {label}
        </label>
        {postLabel ? <span className={postLabelClass}>{postLabel}</span> : null}
      </div>
    );
  }
}

export default CheckBox;

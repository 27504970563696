// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';
import {get} from 'lodash';

import {Button} from 'react-foundation';

import GeneralPage from '../general-components/general-page';
import EditSiteInfo from './edit-site-info';
import SiteSystems from './site-systems';
import EditSitePartnerPanel from './edit-site-partner-panel';
import EditSiteOwnerPanel from './edit-site-owner-panel';
import RemoveSiteModal from './remove-site-modal';

import {buildCondensedAddress} from '../util/component-util';
import {changeRoute} from '../route';
import siteSvc from './site-service';

import type {
  SiteType,
  SystemType,
  UserType,
  SiteInvitationType,
  SiteFormType
} from '../types';

import './edit-site.css';

type PropsType = {
  site: SiteType,
  editSite: SiteFormType,
  siteSystems: SystemType[],
  siteInvites: SiteInvitationType[],
  user: UserType,
  userIsOrgAdmin: boolean
};

type MyStateType = {
  removeSiteOpen: boolean,
  busy: boolean
};

export class EditSite extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      removeSiteOpen: false,
      busy: false
    };
  }

  openRemoveSite = () => {
    this.setState({
      removeSiteOpen: true
    });
  };

  removeSiteCancel = () => {
    this.setState({
      removeSiteOpen: false
    });
  };

  removeSiteSuccess = () => {
    this.setState({
      removeSiteOpen: false
    });
    changeRoute('manage-sites');
  };

  saveInfoChanges = async () => {
    const {site, editSite} = this.props;
    await siteSvc.updateSite(site.id, editSite);
  };

  doRefresh = async () => {
    const {site} = this.props;
    this.setState({busy: true});
    await siteSvc.selectSite(site.id);
    this.setState({busy: false});
  };

  render() {
    const {
      site,
      siteSystems,
      user,
      userIsOrgAdmin,
      editSite,
      siteInvites
    } = this.props;
    const {removeSiteOpen, busy} = this.state;
    const s = siteSystems.length === 1 ? '' : 's';

    const subText = (
      <span>
        {buildCondensedAddress(site)}
      </span>
    );

    const userOrgId = user.organization ? user.organization.id : -1;

    const siteOrgs = site && site.organizations ? site.organizations : [];

    const siteOwner = siteOrgs.find(org => org.associationType === 'Owner');
    const partners = siteOrgs.filter(org => org.associationType === 'Partner');

    const userSiteOrg = siteOrgs.find(org => org.id === userOrgId);

    const userSiteRel = userSiteOrg ? userSiteOrg.associationType : null;
    const userIsOwner = userSiteRel === 'Owner';

    const siteOwnerName = get(siteOwner, 'name', '-');

    const allOwnerInvites = siteInvites.filter(
      invite => invite.associationType === 'Owner'
    );
    const ownerInvite = allOwnerInvites.length > 0 ? allOwnerInvites[0] : null;

    const partnerInvite = siteInvites.filter(i => i.associationType === 'Partner');

    return (
      <GeneralPage
        title={site.name}
        className="edit-site"
        contentClassName="grid-x"
        subText={subText}
      >
      <RemoveSiteModal
        isOpen={removeSiteOpen}
        siteToRemove={site}
        onRequestClose={this.removeSiteCancel}
        onSuccess={this.removeSiteSuccess}
      />

      <div className="cell large-2 large-offset-10 medium-3 medium-offset-9 small-6 small-offset-6 btn-cell">
        {userIsOrgAdmin && userIsOwner ? (
          <Button
            isHollow
            isExpanded
            className="new-site-btn text-uppercase"
            onClick={this.openRemoveSite}
            disabled={busy}
          >
            Remove Site
          </Button>
        ) : null}
      </div>

      <div className="cell small-12 edit-site-panel">
        <EditSiteInfo
          onChange={this.saveInfoChanges}
          editSite={editSite}
          disabled={busy}
          startingName={site.name}
         />
       </div>

      <div className="cell small-12 edit-site-panel">
        <EditSiteOwnerPanel
          site={site}
          owner={siteOwner}
          partner={partners}
          ownerInvite={ownerInvite}
          partnerInvite={partnerInvite}
          userIsOrgAdmin={userIsOrgAdmin}
          userRelType={userSiteRel}
          disabled={busy}
          onChange={this.doRefresh}
        />
      </div>

      <div className="cell small-12 edit-site-panel">
        <EditSitePartnerPanel
          site={site}
          partner={partners}
          partnerInvite={partnerInvite}
          ownerInvite={ownerInvite}
          userRelType={userSiteRel}
          userIsOrgAdmin={userIsOrgAdmin}
          onChange={this.doRefresh}
          disabled={busy}
        />
      </div>

      <div className="cell small-12 edit-site-panel">
        <SiteSystems
          site={site}
          siteSystems={siteSystems}
          onChange={this.doRefresh}
          disabled={busy}
          userIsOrgAdmin={userIsOrgAdmin}
          userRelType={userSiteRel}
          userSiteOrg={userSiteOrg}
        />
      </div>
      
      </GeneralPage>
    );
  }
}

export default watch(EditSite, {
  editSite: 'forms.editSite',
  site: 'selectedSite',
  siteInvites: 'selectedSiteInvites',
  siteSystems: 'selectedSiteSystems',
  user: 'user',
  userIsOrgAdmin: 'sessionInfo.userIsOrgAdmin'
});

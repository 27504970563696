// @flow

import React, {Component} from 'react';
import {Button} from 'react-foundation';
import TitledModal from './titled-modal';

import {buildClassName} from '../util/component-util';

import './prove-it-modal.css';

type PropsType = {
  title: string,
  message: any,
  valueToMatch: string,
  onMatchedConfirmed: Function,
  onCancel: Function,
  isOpen: boolean,
  cancelText?: string,
  okText?: string,
  className?: string,
  disabled?: boolean,
  isUpperCase?: boolean
};

type MyStateType = {
  valueEntered: string,
  valueMatched: boolean
};

class ProveItModal extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      valueEntered: '',
      valueMatched: false
    };
  }

  // $FlowFixMe - Event is a type external to this project
  onInputChanged = event => {
    const {valueToMatch, isUpperCase} = this.props;
    const {value} = event.target;
    const valueEntered = isUpperCase && value ? value.toUpperCase() : value;
    this.setState({
      valueEntered,
      valueMatched: valueEntered === valueToMatch
    });
  };

  onInternalCancel = () => {
    const {onCancel} = this.props;
    this.setState({
      valueEntered: '',
      valueMatched: false
    });

    onCancel();
  };

  render() {
    const {
      title,
      message,
      onMatchedConfirmed,
      isOpen,
      cancelText,
      okText,
      className,
      disabled,
      valueToMatch
    } = this.props;

    const {valueEntered, valueMatched} = this.state;

    const okBtnText = okText || 'OK';
    const cancelBtnText = cancelText || 'Cancel';

    return (
      <TitledModal
        title={title}
        isOpen={isOpen}
        closeDisabled
        className={buildClassName('prove-it-modal', 'minor', className)}
        contentClassName="grid-x prove-it-modal-content"
      >
        <div className="cell small-12 prove-it-message">
          <p>{message}</p>
        </div>
        <div className="cell small-12 prove-it-input-container">
          <input
            className="prove-it-text-input"
            type="text"
            value={valueEntered}
            onChange={this.onInputChanged}
            style={{textAlign: 'center'}}
            disabled={disabled}
            autoFocus
            maxLength={valueToMatch.length}
          />
        </div>
        <div className="cell small-12 prove-it-buttons">
          <div className="grid-x grid-padding-x">
            <div className="cell medium-4 medium-offset-2 small-6">
              <Button
                onClick={this.onInternalCancel}
                color="black"
                isExpanded
                isHollow
                className="text-uppercase cancel-btn"
                disabled={disabled}
              >
                {cancelBtnText}
              </Button>
            </div>
            <div className="cell medium-4 small-6">
              <Button
                onClick={onMatchedConfirmed}
                color="black"
                isExpanded
                isHollow
                disabled={!valueMatched || disabled}
                className="text-uppercase ok-btn"
              >
                {okBtnText}
              </Button>
            </div>
          </div>
        </div>
      </TitledModal>
    );
  }
}

export default ProveItModal;

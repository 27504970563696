// @flow

import React, {Component} from 'react';
import {Button} from 'react-foundation';

import YesOrNo from '../general-components/yes-or-no';
import CollapsibleGridContainer from '../general-components/collapsible-grid-container';
import CollapsibleGrid from '../general-components/collapsible-grid';
import CollapsibleGridRow from '../general-components/collapsible-grid-row';

import workflowService from '../workflow/workflow-service';

import {getDate} from '../util/moment-util';

import './org-invitation-panel.css';

import type {OrgInvitationType} from '../types';

// eslint-disable-next-line no-duplicate-imports
import type {ColumnDescriptorType} from '../general-components/collapsible-grid';

type PropsType = {
  orgInvitations: OrgInvitationType[],
  onInformationChanged: Function
};

type MyStateType = {
  selectedInvitation: OrgInvitationType,
  cancelVerifyOpen: boolean,
  resendVerifyOpen: boolean,
  busy: boolean
};

const COLUMNS: ColumnDescriptorType[] = [
  {
    label: 'Invitee',
    size: 2,
    className: 'invitee-header',
    dataClassName: 'invitee-data'
  },
  {
    label: 'Role',
    size: 2,
    className: 'role-header',
    dataClassName: 'role-data'
  },
  {
    label: 'Status',
    size: 2,
    className: 'status-header',
    dataClassName: 'status-data'
  },
  {
    label: 'Expires On',
    size: 2,
    className: 'expires-header',
    dataClassName: 'expires-data'
  },
  {
    label: '',
    size: 4,
    className: 'btn-header',
    dataClassName: 'btn-container'
  }
];

const EMPTY_STATE = {
  selectedInvitation: {
    id: -1,
    organizationId: -1,
    inviteeEmail: 'none selected',
    invitationExpires: new Date(0),
    workflowId: -1,
    inviteeRoles: []
  },
  cancelVerifyOpen: false,
  resendVerifyOpen: false,
  busy: false
};

class OrgInvitationPanel extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      ...EMPTY_STATE
    };
  }

  clearState = () => {
    this.setState({
      ...EMPTY_STATE
    });
  };

  verifyCancel = (invitation: OrgInvitationType) => {
    this.setState({
      selectedInvitation: invitation,
      cancelVerifyOpen: true
    });
  };

  verifyResend = (invitation: OrgInvitationType) => {
    this.setState({
      selectedInvitation: invitation,
      resendVerifyOpen: true
    });
  };

  closeEverything = () => {
    this.setState({
      cancelVerifyOpen: false,
      resendVerifyOpen: false
    });
  };

  doAction = async (action: string) => {
    const {selectedInvitation} = this.state;
    const {onInformationChanged} = this.props;
    this.setState({busy: true});

    await workflowService.takeAction({
      workflowId: selectedInvitation.workflowId,
      action
    });

    this.setState({busy: false});
    this.closeEverything();
    onInformationChanged();
  };

  doCancel = () => this.doAction('cancel');

  doResend = () => this.doAction('resend');

  convertDate = (input: Date) => {
    const localDate = getDate(input);

    const displayDate = localDate.format('MM/DD/YYYY');
    const displayTime = localDate.format('hh:mm a');

    return (
      <span>
        {displayDate}
        <br />
        {displayTime}
      </span>
    );
  };

  convertToRow = (invite: OrgInvitationType) => {
    const cells = [
      invite.inviteeEmail,
      invite.inviteeRoles.map(role => (
        <div key={role} className="invitee-role">
          {role}
        </div>
      )),
      'Invitation Sent',
      this.convertDate(invite.invitationExpires),
      <div key={`btn-${invite.id}`} className="grid-x grid-padding-x">
        <div className="cell medium-6 small-12">
          <Button
            className="text-uppercase cancel-invite-button"
            isHollow
            isExpanded
            color="little-blue"
            onClick={this.verifyCancel.bind(this, invite)}
          >
            Cancel Invite
          </Button>
        </div>
        <div className="cell medium-6 small-12">
          <Button
            className="text-uppercase resend-invite-button"
            isHollow
            isExpanded
            color="little-blue"
            onClick={this.verifyResend.bind(this, invite)}
          >
            Resend Invite
          </Button>
        </div>
      </div>
    ];

    return (
      <CollapsibleGridRow
        key={invite.id}
        className="org-invite"
        columns={COLUMNS}
        cells={cells}
      />
    );
  };

  render() {
    const {orgInvitations} = this.props;
    const {
      selectedInvitation,
      cancelVerifyOpen,
      resendVerifyOpen,
      busy
    } = this.state;

    return (
      <CollapsibleGridContainer className="org-invitation-panel">
        <YesOrNo
          title="Confirm Cancellation"
          className="cancel-confirm"
          message={`Are you sure you want to cancel the invitation to ${
            selectedInvitation.inviteeEmail
          }?`}
          onYes={this.doCancel}
          onNo={this.closeEverything}
          isOpen={cancelVerifyOpen}
          yesText="Cancel Invitation"
          disabled={busy}
        />
        <YesOrNo
          title="Confirm Resend"
          className="resend-confirm"
          message={`Are you sure you want to re-send the invitation to ${
            selectedInvitation.inviteeEmail
          }?`}
          onYes={this.doResend}
          onNo={this.closeEverything}
          isOpen={resendVerifyOpen}
          yesText="Resend Invitation"
          disabled={busy}
        />
        <div className="title-header text-uppercase">Invitations</div>
        <div className="collapsible-grid-divider" />
        <CollapsibleGrid className="org-invitation-grid" columns={COLUMNS}>
          {orgInvitations.map(invite => this.convertToRow(invite))}
        </CollapsibleGrid>
      </CollapsibleGridContainer>
    );
  }
}

export default OrgInvitationPanel;

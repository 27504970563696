// @flow

import React, {Component} from 'react';

import {buildClassName} from '../util/component-util';

import intelligenColor from '../general-components/images/intelligen-color.svg';
import simplicityImage from '../general-components/images/simplicity.svg';
import colorBar from '../general-components/images/color-bar.svg';

import './marketing-box-left.css';

type PropsType = {
  className?: ?string
};

export default class MarketingBox extends Component<PropsType> {
  render() {
    const {className} = this.props;

    return (
      <div className={buildClassName('marketing-left', className)}>
        <img
          src={intelligenColor}
          className="intelligen-color"
          alt="IntelliGen Logo"
        />
        <img
          src={simplicityImage}
          className="simplicity-img"
          alt="Simplicity"
        />
        <img src={colorBar} className="colorbar-img" alt="" />
        <div className="introducing-text">
          Introducing the New <strong>intelliGen™</strong>
          <br />
          Refrigeration Controller
        </div>
        <div className="visit-text">
          Visit{' '}
          <a href="https://intelligencontrols.com" target="_icontrols">
            intelliGencontrols.com
          </a>{' '}
          to learn more.
        </div>
      </div>
    );
  }
}

// @flow

import React, {Component} from 'react';

import {Button} from 'react-foundation';

import TitledModal from '../general-components/titled-modal';
import Radio from '../general-components/radio';
import siteSvc from './site-service';

import './remove-system-modal.css';

import type {SiteType, SiteOrgType, SystemType} from '../types';

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onSuccess: Function,
  site: SiteType,
  system: SystemType,
  partner: ?SiteOrgType
};

type StateType = {
  currentStep: number,
  keepAccessOrg: boolean,
  busy: boolean
};

const STARTING_STATE = {
  currentStep: 1,
  keepAccessOrg: true,
  busy: false
};

export default class RemoveSystemModal extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      ...STARTING_STATE
    };
  }

  onKeepAccessChanged = (value: string) => {
    this.setState({
      keepAccessOrg: value === 'Yes'
    });
  };

  doCancel = () => {
    const {onRequestClose} = this.props;
    this.setState({
      ...STARTING_STATE
    });
    onRequestClose();
  };

  goToStep = (step: number) => {
    this.setState({
      currentStep: step
    });
  };

  doRemoval = async () => {
    const {onSuccess, system, site} = this.props;
    const {keepAccessOrg} = this.state;

    this.setState({
      busy: true
    });

    await siteSvc.removeSystem(site.id, system.id, keepAccessOrg);

    this.setState({
      ...STARTING_STATE
    });
    onSuccess();
  };

  render() {
    const {isOpen, partner, site, system, owner} = this.props;
    const {currentStep, keepAccessOrg, busy} = this.state;

    const siteName = site && site.name ? site.name : 'UNKNOWN';
    const systemName = system && system.name ? system.name : 'No Name';
    const ownerName = owner && owner.name ? owner.name : 'UNKNOWN';

    const partnersListTodisplay = partner.map((org)=>{
      return <li>{org.name}</li>;
    });
    return (
      <TitledModal
        title="Remove System From This Site"
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.doCancel}
        className="remove-system-modal minor"
      >
        {currentStep === 1 ? (
          <div className="grid-x grid-padding-x step-1">
            <div className="cell small-12 instructions">
              <p>
                You are removing <strong>{systemName}</strong> from <strong>{siteName}</strong>{' '}
              </p>
            </div>
            <div className="cell small-12 instructions">
              {partner &&  partner.length > 0 ? (
                <div>
                  <p>
                  Do you want <strong>{systemName}</strong>’s access partners to continue to have access to the system once the system is removed 
                  from the <strong>{siteName}</strong> site?
                  </p>
                  <div className="display-partner-list">
                      <strong>
                        <ul>
                          {partnersListTodisplay}
                        </ul>
                      </strong>
                    </div>
                  <fieldset className="keep-access-radios">
                    <Radio
                      label="Keep access partners."
                      name="keepAccessOrg"
                      value="Yes"
                      className="keep-access-radio keep-access-yes"
                      checked={keepAccessOrg}
                      onChange={this.onKeepAccessChanged}
                    />
                    <Radio
                      label="Remove access partners."
                      name="keepAccessOrg"
                      value="No"
                      className="keep-access-radio keep-access-no"
                      checked={!keepAccessOrg}
                      onChange={this.onKeepAccessChanged}
                    />
                  </fieldset>
                </div>
              ) : null}
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-6 medium-4 medium-offset-2">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.doCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-6 medium-4">
              <Button
                disabled={busy}
                isExpanded
                isHollow
                className="text-uppercase next-btn"
                color="black"
                onClick={this.goToStep.bind(this, 2)}
              >
                Next
              </Button>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div className="grid-x grid-padding-x step-2">
            <div className="cell small-12 instructions">
              <p>
                Are you sure you want to remove <strong>{systemName}</strong> from the <strong>{siteName}</strong> site?
              </p>
              <p>
              <strong>{systemName}</strong> will be reassigned to the <strong>NO SITE ASSIGNED</strong> group.
              </p>
            </div>
            <div className="cell small-12 instructions">
              {partner && partner.length > 0 ? (
                keepAccessOrg ? (
                  <p>
                    <strong>{systemName}</strong>'s system owner will be <strong>{ownerName}</strong> and its system access partners will retain access to the system.
                  </p>
                ) : (
                  <p>
                    <strong>{systemName}</strong>'s system owner will be <strong>{ownerName}</strong> and its system access partners will lose access to the system.
                  </p>
                )
              ) : null}
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-4">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.doCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-4">
              <Button
                isExpanded
                isHollow
                disabled={busy}
                className="text-uppercase back-btn"
                color="black"
                onClick={this.goToStep.bind(this, 1)}
              >
                Back
              </Button>
            </div>
            <div className="cell small-4">
              <Button
                disabled={busy}
                isExpanded
                isHollow
                className="text-uppercase ok-btn"
                color="black"
                onClick={this.doRemoval}
              >
                OK
              </Button>
            </div>
          </div>
        ) : (
          <div>We seem to have encountered an error</div>
        )}
      </TitledModal>
    );
  }
}

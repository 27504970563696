// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';

import GeneralPage from '../general-components/general-page';
import TitledPanel from '../general-components/titled-panel';
import LabelledCell from '../general-components/labelled-cell';
import SystemProfileOwnerPanel from './system-profile-owner-panel';
import SystemProfilePartnerPanel from './system-profile-partner-panel';
import SystemProfileUnregisterPanel from './system-profile-unregister-panel';
import {changeRoute} from '../route';
import systemSvc from './system-service';
import {buildCondensedAddress} from '../util/component-util';

import './system-profile.css';

import type {SystemType, SystemInvitationType, UserType} from '../types';

type PropsType = {
  system: SystemType,
  user: UserType,
  invites: SystemInvitationType[]
};

type MyStateType = {
  transferOwnerOpen: boolean
};

class SystemProfile extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      transferOwnerOpen: false
    };
  }

  componentDidMount() {
    const {system} = this.props;
    if (!system) {
      changeRoute('systems');
    }
    this.updateSystem();
  }

  updateSystem = () => {
    const {system, user} = this.props;
    const systemOrgs = system.organizations;
    const orgId = user.organization ? user.organization.id : -1;
    const userIsOwner = systemOrgs.some(
      org => org.id === orgId && org.type === 'Owner'
    );

    systemSvc.updateSelectedSystem(system.id);
    if (userIsOwner) {
      systemSvc.updateSelectedSystemInvites(system.id);
    } else {
      systemSvc.clearSelectedSystemInvites();
    }
  };

  render() {
    const {system, user, invites} = this.props;

    if (!user.authenticated) return changeRoute('login');

    const orgInfo = systemSvc.getOrgInfo(system, user.organization, invites);

    const systemDisplayName = system.name ? system.name : 'No Name';

    return (
      <GeneralPage
        title={systemDisplayName}
        className="system-profile"
        contentClassName="system-profile-content grid-x"
      >
        {system.site ? (
          <div className="cell small-12 site-name-header">
            {system.site.name}
          </div>
        ) : null}
        {system.site ? (
          <div className="cell small-12 site-address-header">
            {buildCondensedAddress(system.site)}
          </div>
        ) : null}

        <div className="cell small-12 system-panel">
          <TitledPanel
            title="System Information"
            className="system-info-panel"
            contentClassName="grid-x"
          >
            <div className="cell small-12 details">
              System Name can be changed in the System View. Click on GO TO
              SYSTEM in the Dashboard to go to System View.
            </div>

            <div className="cell small-12 medium-4 system-name">
              <LabelledCell
                label="System Name"
                className="system-name-display-cell"
                labelClassName="system-name-display-cell-label"
              >
                <input
                  type="text"
                  disabled
                  value={system.name}
                  className="system-name-display"
                />
              </LabelledCell>
            </div>
          </TitledPanel>
        </div>
        <div className="cell small-12 system-panel">
          <SystemProfileOwnerPanel
            owner={orgInfo.owner}
            ownerInvite={orgInfo.ownerInvite}
            partner={orgInfo.partner}
            partnerInvite={orgInfo.partnerInvite}
            userRelType={orgInfo.userRelType}
            system={system}
            onInformationChanged={this.updateSystem}
          />
        </div>
        <div className="cell small-12 system-panel">
          <SystemProfilePartnerPanel
            partner={orgInfo.partner}
            partnerInvite={orgInfo.partnerInvite}
            ownerInvite={orgInfo.ownerInvite}
            userRelType={orgInfo.userRelType}
            system={system}
            onInformationChanged={this.updateSystem}
          />
        </div>
        { orgInfo.userRelType === 'Owner' && user.organization.userRoles &&
          user.organization.userRoles.includes('Administrator') ? <div className="cell small-12 system-panel">
          <SystemProfileUnregisterPanel
            owner={orgInfo.owner}
            ownerInvite={orgInfo.ownerInvite}
            partner={orgInfo.partner}
            partnerInvite={orgInfo.partnerInvite}
            userRelType={orgInfo.userRelType}
            system={system}
            onInformationChanged={this.updateSystem}
          />
        </div> : null }        
      </GeneralPage>
    );
  }
}

export default watch(SystemProfile, {
  invites: 'selectedSystemInvitations',
  system: 'selectedSystem',
  user: ''
});

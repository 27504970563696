// @flow
/* eslint-disable complexity */

import React, {Component} from 'react';
import {Block, Breakpoints, Button} from 'react-foundation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import workflowSvc from '../workflow/workflow-service';
import notificationSvc from './notification-service';
import sysSvc from '../systems/system-service';

import NotificationItemAddressBlock from './notification-item-address-block';

import {buildClassName} from '../util/component-util';
import {buildNotificationItemDisplay} from './notification-item-display-factory';

import actionRequiredIcon from '@fortawesome/fontawesome-free-solid/faExclamationCircle';
import newMarkerIcon from './images/new-marker.svg';

import {timeZoneAbbrev} from '../util/date-util';

import type {
  NotificationType,
  SystemType,
  UserType,
  WorkflowActionType
} from '../types';

// eslint-disable-next-line no-duplicate-imports
import type {NotificationItemDisplayType} from './notification-item-display-factory';

import './notification-item.css';

// ESLint is unable to notice that these props are being used in the static
// `getDerivedStateFromProps` method below.
type PropsType = {
  notification: NotificationType,
  // eslint-disable-next-line react/no-unused-prop-types
  systems: SystemType[],
  // eslint-disable-next-line react/no-unused-prop-types
  lastViewedNotifications: Date,
  user: UserType
};

type StateType = {
  expanded: boolean,
  notificationDisplay: NotificationItemDisplayType
};

class NotificationItem extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      expanded: false,
      notificationDisplay: {
        id: -1,
        icon: null,
        barColor: 'white',
        type: 'empty',
        isNew: false,
        hasActions: false,
        done: false,
        displayDate: '',
        displayTime: '',
        title: '',
        message: '',
        systemAvailable: false
      }
    };
  }

  static getDerivedStateFromProps(props: PropsType, state: StateType) {
    return {
      ...state,
      notificationDisplay: buildNotificationItemDisplay(
        props.notification,
        props.lastViewedNotifications,
        props.systems
      )
    };
  }

  toggleExpand = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  goToSystem = () => {
    const {system} = this.state.notificationDisplay;
    if (system) {
      sysSvc.goToSystem(system);
    }
  };

  doAction = async (action: WorkflowActionType) => {
    const {user} = this.props;
    await workflowSvc.takeAction(action, user.id);
    await notificationSvc.updateNotifications();
  };

  buildButton = (className: string, fn: Function, label: string, key: any) => (
    <Button
      key={key}
      isExpanded
      isHollow
      color="little-blue"
      className={buildClassName(
        'notification-item-button',
        'text-uppercase',
        className
      )}
      onClick={fn}
    >
      {label}
    </Button>
  );

  render() {
    const {notificationDisplay, expanded} = this.state;
    const {notification} = this.props;

    const seeMoreContent = (
      <a className="toggle-expansion" onClick={this.toggleExpand}>
        {!expanded ? 'See more...' : 'See less...'}
      </a>
    );

    const newNotificationMarker = notificationDisplay.isNew ? (
      <div className="new-notification-marker">
        <img
          className="new-notification-marker-icon"
          src={newMarkerIcon}
          alt="New Notification"
        />
      </div>
    ) : null;

    const actionRequiredContent = notificationDisplay.hasActions ? (
      <div className="action-available-box">
        <FontAwesomeIcon
          className="action-available-icon"
          icon={actionRequiredIcon}
        />
      </div>
    ) : null;

    const systemButton =
      notificationDisplay.systemAvailable &&
        // $FlowFixMe - systemAvailable is only true when I have a system.
        notificationDisplay.system.isConnected ?
        [
          this.buildButton(
            'go-to-system',
            this.goToSystem,
            'Go To System',
            'go-to-sys'
          )
        ] :
        [];

    const actionButtons = notificationDisplay.hasActions ?
      notification.actions.map(action =>
        this.buildButton(
          `action-${action.action}`,
          this.doAction.bind(this, action),
          action.label || action.action.toUpperCase(),
          `action-${action.action}`
        )
      ) :
      [];

    const buttons = systemButton.concat(actionButtons);

    const displayStyle = expanded ?
      {
        borderLeft: `6px solid ${notificationDisplay.barColor}`
      } :
      {
        marginLeft: 6
      };

    return (
      <div
        className={buildClassName(
          'notification-item',
          'callout',
          expanded ? 'expanded' : 'collapsed',
          notificationDisplay.isNew ? 'new-notification' : null
        )}
      >
        <Block showFor={Breakpoints.MEDIUM}>
          {expanded ? (
            <div
              className="grid-x grid-padding-x notification-info-box"
              style={displayStyle}
            >
              <div className="cell small-8 small-offset-2">
                <div className="grid-x grid-padding-x">
                  <div className="cell small-4 small-offset-4">
                    <div className="heading text-uppercase">Posted Date</div>
                    <div>{notificationDisplay.displayDate}</div>
                  </div>
                  <div className="cell small-4">
                    <div className="heading text-uppercase">Posted Time</div>
                    <div>
                      {notificationDisplay.displayTime.toUpperCase()}{' '}
                      {timeZoneAbbrev}
                    </div>
                  </div>
                </div>
              </div>
              <div className="cell small-2 text-center">{seeMoreContent}</div>
              <div className="cell small-1 middle-row-cell">
                {newNotificationMarker}
                {actionRequiredContent}
              </div>
              <div className="cell small-1 middle-row-cell">
                <img
                  className="notification-icon"
                  src={notificationDisplay.icon}
                  alt={notificationDisplay.type}
                />
              </div>
              <div className="cell small-8 middle-row-cell">
                <div className="grid-x grid-padding-x">
                  <div className="cell small-4 title-display">
                    {notificationDisplay.title}
                  </div>
                  <div className="cell small-4">
                    <div className="heading text-uppercase">Description</div>
                    <div>{notificationDisplay.message}</div>
                  </div>
                  <div className="cell small-4">
                    {notificationDisplay.systemAvailable ? (
                      <div>
                        <div className="heading text-uppercase">Location</div>
                        <div>
                          <NotificationItemAddressBlock
                            site={notificationDisplay.site}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="cell small-2 middle-row-cell">
                {buttons.length > 0 ? buttons[0] : null}
              </div>
              <div className="cell small-8 small-offset-2">
                <div className="grid-x grid-padding-x">
                  <div className="cell small-4 small-offset-4">
                    {notificationDisplay.systemAvailable ? (
                      <div>
                        <div className="heading text-uppercase">System</div>
                        <div>{notificationDisplay.systemName}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className="cell small-4">
                    {notificationDisplay.systemUnit ? (
                      <div>
                        <div className="heading text-uppercase">Unit</div>
                        <div>{notificationDisplay.systemUnit}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="cell small-2">
                {buttons.length > 1 ? buttons[1] : null}
              </div>
            </div>
          ) : (
            <div
              className="grid-x grid-padding-x notification-info-box"
              style={displayStyle}
            >
              <div className="cell small-1">
                {newNotificationMarker}
                {actionRequiredContent}
              </div>
              <div className="cell small-1">
                <img
                  className="notification-icon"
                  src={notificationDisplay.icon}
                  alt={notificationDisplay.type}
                />
              </div>
              <div className="cell small-8">
                <div className="grid-x grid-padding-x">
                  <div className="cell small-4 title-display">
                    {notificationDisplay.title}
                  </div>
                  <div className="cell small-4">
                    <div className="heading text-uppercase">Posted Date</div>
                    <div>{notificationDisplay.displayDate}</div>
                  </div>
                  <div className="cell small-4">
                    <div className="heading text-uppercase">Posted Time</div>
                    <div>
                      {notificationDisplay.displayTime.toUpperCase()}{' '}
                      {timeZoneAbbrev}
                    </div>
                  </div>
                </div>
              </div>
              <div className="cell small-2 text-center">{seeMoreContent}</div>
            </div>
          )}
        </Block>
        <Block showOnlyFor={Breakpoints.SMALL}>
          {expanded ? (
            <div
              className="grid-x grid-padding-x notification-info-box"
              style={displayStyle}
            >
              <div className="cell small-2">
                {newNotificationMarker}
                {actionRequiredContent}
              </div>
              <div className="cell small-10 grid-x">
                <div className="cell small-4" />
                <div className="cell small-4 text-right">
                  <div className="heading text-uppercase">Posted Date</div>
                  {notificationDisplay.displayDate}{' '}
                </div>
                <div className="cell small-4 text-right">
                  <div className="heading text-uppercase">Posted Time</div>
                  {notificationDisplay.displayTime}
                </div>
              </div>
              <div className="cell small-2 small-offset-1 middle-row-cell">
                <img
                  className="notification-icon"
                  src={notificationDisplay.icon}
                  alt={notificationDisplay.type}
                />
              </div>
              <div className="cell small-8 middle-row-cell">
                {notificationDisplay.title}
              </div>
              <div className="cell small-1 middle-row-cell" />
              <div className="cell small-10 small-offset-1 middle-row-cell">
                <div className="heading text-uppercase">Description</div>
                <div>{notificationDisplay.message}</div>
              </div>
              <div className="cell small-1 middle-row-cell" />
              <div className="cell small-10 small-offset-1">
                {notificationDisplay.systemAvailable ? (
                  <div className="grid-x grid-padding-x">
                    <div className="cell small-6 middle-row-cell">
                      <div className="heading text-uppercase">System</div>
                      <div>{notificationDisplay.systemName}</div>
                    </div>
                    <div className="cell small-6 middle-row-cell">
                      {notificationDisplay.systemUnit ? (
                        <div>
                          <div className="heading text-uppercase">Unit</div>
                          <div>{notificationDisplay.systemUnit}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="cell small-12 middle-row-cell">
                      <div className="heading text-uppercase">Location</div>
                      <div>
                        <NotificationItemAddressBlock
                          site={notificationDisplay.site}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="cell small-1" />
              <div className="cell small-5 small-offset-1">
                {buttons.length > 0 ? buttons[0] : null}
              </div>
              <div className="cell small-5">
                {buttons.length > 1 ? buttons[1] : null}
              </div>
              <div className="cell small-1" />
              <div className="cell small-12 text-right">{seeMoreContent}</div>
            </div>
          ) : (
            <div
              className="grid-x grid-padding-x notification-info-box"
              style={displayStyle}
            >
              <div className="cell small-2">
                {newNotificationMarker}
                {actionRequiredContent}
              </div>
              <div className="cell small-10 grid-x">
                <div className="cell small-4" />
                <div className="cell small-4 text-right">
                  <div className="heading text-uppercase">Posted Date</div>
                  {notificationDisplay.displayDate}{' '}
                </div>
                <div className="cell small-4 text-right">
                  <div className="heading text-uppercase">Posted Time</div>
                  {notificationDisplay.displayTime}
                </div>
              </div>
              <div className="cell small-2 small-offset-1 middle-row-cell">
                <img
                  className="notification-icon"
                  src={notificationDisplay.icon}
                  alt={notificationDisplay.type}
                />
              </div>
              <div className="cell small-8 middle-row-cell">
                {notificationDisplay.title}
              </div>
              <div className="cell small-1 middle-row-cell" />
              <div className="cell small-12 text-right">{seeMoreContent}</div>
            </div>
          )}
        </Block>
      </div>
    );
  }
}

export default NotificationItem;

// @flow

import React, { Component } from 'react';

import { Button } from 'react-foundation';

import TitledPanel from '../general-components/titled-panel';
import AddSitePartnerModal from './add-site-partner-modal';
import LabelledCell from '../general-components/labelled-cell';
import YesOrNo from '../general-components/yes-or-no';

import siteSvc from './site-service';

import { buildCondensedAddress } from '../util/component-util';

import './edit-site-partner-panel.css';


class EditSitePartnerPanel extends Component {
  constructor() {
    super();
    this.state = {
      addPartnerOpen: false,
      removePartnerOpen: false,
      removePartnerBusy: false,
      partnerToRemove: {},
      invitedPartnerToCancel: {}
    };
  }

  openAddPartnerConfirm = () => {
    this.setState({
      addPartnerOpen: true
    });
  };

  closeAddPartner = () => {
    this.setState({
      addPartnerOpen: false
    });
  };

  addPartnerDone = () => {
    const { onChange } = this.props;
    this.setState({
      addPartnerOpen: false
    });

    onChange();
  };


  openRemovePartnerConfirm(event, partnerToRemove) {
    this.setState({
      partnerToRemove: partnerToRemove,
      removePartnerOpen: true
    });
  };

  removePartnerYes = async () => {
    const { site, onChange } = this.props;
    if (this.state.partnerToRemove.id) {
      this.setState({
        removePartnerBusy: true
      });
      await siteSvc.removeSiteAccess(site.id, this.state.partnerToRemove.id);
      this.setState({
        removePartnerOpen: false,
        removePartnerBusy: false,
        partnerToRemove: {}
      });
      onChange();
    }
  };

  removePartnerNo = () => {
    this.setState({
      removePartnerOpen: false,
      partnerToRemove: {}
    });
  };

  render() {
    const {
      addPartnerOpen,
      removePartnerOpen,
      removePartnerBusy,
    } = this.state;

    const {
      partner,
      partnerInvite,
      ownerInvite,
      site,
      userRelType,
      userIsOrgAdmin
    } = this.props;

    const partnerToDisplay = partner ?
      partner :
      {
        id: -1,
        type: 'Partner',
        name: 'None',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        email: '',
        phone: '',
        website: ''
      };

    const userIsOwner = userRelType === 'Owner';

    const title = 'Site Access Partners';
    let partnerNametoRemove = this.state.partnerToRemove.name ? this.state.partnerToRemove.name : 'unknown';
    let siteName = site.name ? site.name : 'unknown';
    const partnerInviteCount = partnerInvite ? partnerInvite.length : 0;
    const partnersCount = partner ? partner.length : 0;
    const totalPartnersAndInvitations = partnersCount + partnerInviteCount;
    const invitePartnerDisabled = !userIsOwner || !userIsOrgAdmin || (totalPartnersAndInvitations >= 4);
    const removePartnerButtonDisabled = !userIsOrgAdmin || !userIsOwner;
    const revokePartnerAccessMessage = (
      <div>
        <p>
          Are you sure you want to remove <strong>{partnerNametoRemove}</strong>'s site access to site <strong>{siteName}</strong>?
        </p>
        <p>
          <strong>{partnerNametoRemove}</strong> will no longer have system access to the <strong>{siteName}</strong> site or any of its systems.
        </p>
      </div>
    );

    return (
      <TitledPanel title={title} className="edit-site-partner-panel">
        <AddSitePartnerModal
          isOpen={addPartnerOpen}
          onRequestClose={this.closeAddPartner}
          onSuccess={this.addPartnerDone}
          site={site}
        />
        <YesOrNo
          title="Remove Site Access Partner"
          message={revokePartnerAccessMessage}
          isOpen={removePartnerOpen}
          disabled={removePartnerBusy}
          onYes={this.removePartnerYes}
          onNo={this.removePartnerNo}
          yesText="Yes"
        />
        <div className="grid-x grid-padding-x invite-partners">
          <div className="cell small-12 medium-4">{ userIsOwner ? 
            <Button
              isExpanded
              isHollow
              color="little-blue"
              className="text-uppercase"
              onClick={this.openAddPartnerConfirm}
              disabled={invitePartnerDisabled}
            >
              Invite Partner
            </Button> : null}
          </div>
          <div className="cell small-12 medium-4"></div>
          <div className="cell small-12 medium-4 partner-count text-uppercase">
            {partner.length + partnerInvite.length} of 4 partners
          </div>
        </div>
        {partner.length > 0 || !userIsOwner ? partner.map((org) => {
          return (
            <div className="grid-x grid-padding-x bottom-part">
              <div className="cell small-12 medium-4">
                <LabelledCell label="Access Partner">
                  {org.name}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Email">
                  {org.email}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Website">
                  {org.website}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Address">
                  {buildCondensedAddress(org)}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Phone">
                  {org.phone}
                </LabelledCell>
              </div>
              {userIsOwner ? (
                <div className="cell small-12 medium-4">
                  <div className="partner-buttons">
                    <Button
                      isHollow
                      isExpanded
                      color="little-blue"
                      className="text-uppercase"
                      onClick={(event) => this.openRemovePartnerConfirm(event, org)}
                      disabled={removePartnerButtonDisabled || (ownerInvite && ownerInvite.swapRelationships && org.id === ownerInvite.inviteeOrgId)}
                    >
                      Remove Partner
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>)
        }) : null
        }
      </TitledPanel>
    );
  }
}

export default EditSitePartnerPanel;

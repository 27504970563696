// @flow

import moment from 'moment';

export function getDate(date: Date | string) {
  if (process.env.NODE_ENV !== 'test') {
    return moment(date).local();
  }

  return moment(date).utc();
}

// @flow

import renderer from 'react-test-renderer';
import initialState from '../initial-state';
import {reduxSetup, setStore} from 'redux-easy';
import configureStore from 'redux-mock-store';
import './enzyme-setup';
import './foundation-test-setup';

import type {StateType, StoreType, SystemType, UserType} from '../types';

const systems: SystemType[] = [
  {
    alerts: [{level: 1, count: 3}, {level: 2, count: 1}],
    id: 's1',
    location: 'Santa Cruz, CA',
    model: 'ABC123',
    name: "Applebee's AC #3",
    temperature: 1,
    temperatureC: 2,
    isConnected: true,
    organizations: [],
    wecFirmwareVersion: '1.2.3'
  },
  {
    alerts: [{level: 2, count: 1}, {level: 3, count: 5}],
    id: 's2',
    location: 'St. Charles, MO',
    model: 'DEF678',
    name: 'Taco Bell Furnace #2',
    temperature: 2,
    temperatureC: 3,
    isConnected: false,
    organizations: [],
    wecFirmwareVersion: '1.2.3'
  }
];

type PrimitiveType = boolean | number | string;
export function different(v1: ?PrimitiveType, v2: ?PrimitiveType): boolean {
  return (Boolean(v1) || Boolean(v2)) && v1 !== v2;
}

export function setupMockStore(initialState: StateType = {}) {
  const store = configureStore([])(initialState);
  setStore(store, true);
  reduxSetup({initialState, silent: true});
  return store;
}

export function expectAction(
  store: StoreType,
  type: string,
  payload: any = undefined
) {
  const actions = store.getActions();
  const testAction = actions.find(action => action.type === type);
  expect(testAction).toBeDefined();
  if (testAction) {
    expect(testAction.payload).toEqual(payload);
  }
}

export function getAuthenticatedState(): StateType {
  const {user, ui} = initialState;
  return {
    ...initialState,
    sessionInfo: {
      userLoggedIn: true,
      userHasOrg: false,
      userIsOrgAdmin: false,
      userIsOrgServicer: false,
      userHasTempOrgRelationship: false
    },
    notifications: [
      {
        id: 1,
        notificationId: 1,
        created: new Date(3),
        type: 'type',
        title: 'Title',
        message: 'Message',
        data: {},
        actions: [],
        done: false
      },
      {
        id: 2,
        notificationId: 3,
        created: new Date(2),
        type: 'type',
        title: 'Title',
        message: 'Message',
        data: {},
        actions: [],
        done: false
      }
    ],
    unreadNotifications: 1,
    systems,
    user: {
      ...user,
      firstName: 'Testy',
      lastName: 'Testerson',
      authenticated: true,
      organization: null
    },
    ui: {...ui, hash: 'systems'}
  };
}

export function getAuthenticatedOrgState(): StateType {
  const state = getAuthenticatedState();

  state.user.organization = {
    id: 12345,
    name: 'Test Org',
    address1: 'address 1',
    address2: 'address 2',
    city: 'city',
    state: 'state',
    zipcode: 'zipcode',
    country: 'country',
    email: 'email',
    phone: '+1 (111) 222-3333',
    website: 'website',
    userRoles: ['Spectator']
  };

  state.sessionInfo.userHasOrg = true;

  return state;
}

export function getAuthenticatedOrgAdminState(): StateType {
  const state = getAuthenticatedOrgState();
  if (state.user.organization) {
    state.user.organization.userRoles.push('Administrator');
    state.sessionInfo.userIsOrgAdmin = true;
  }
  return state;
}

export function getFilledOutPreferencesState(): StateType {
  const state = getAuthenticatedOrgState();
  state.userPreferences = {
    userId: state.user.id,
    temperatureDisplayUnit: 'F',
    notifications: {
      email: {
        enabled: true,
        systemEvent: {
          enabled: true,
          delayMinutes: 35
        },
        dailySummary: {
          enabled: true,
          startingHour: 3
        },
        weeklySummary: {
          enabled: true,
          startingDay: 2,
          startingHour: 14
        }
      },
      sms: {
        enabled: true,
        systemEvent: {
          enabled: true,
          delayMinutes: 35
        }
      },
      application: {
        enabled: true,
        systemEvent: {
          enabled: true,
          delayMinutes: 35
        }
      }
    }
  };
  state.user = {
    ...state.user,
    smsPhoneNumber: '+1 (123) 456-7890',
    smsVerified: true
  };

  return state;
}

export function getErrorState() {
  const errors = new Set(['error #1', 'error #2']);
  return {...initialState, errors};
}

export function getTestUser(): UserType {
  return {
    acceptEula: true,
    active: true,
    authenticated: false,
    confirmEmail: 'foo@bar.baz',
    confirmPassword: 'p@SSw0rd',
    email: 'foo@bar.baz',
    firstName: 'Joe',
    id: 1,
    lastName: 'Boxer',
    organization: null,
    password: 'p@SSw0rd',
    phone: '+1 (123) 456-7890',
    lastViewedNotifications: new Date(1)
  };
}

export function inTest(): boolean {
  return process.env.NODE_ENV === 'test';
}

export function logErrorIfNotTest(...args: mixed[]) {
  if (!inTest()) console.error(...args);
}

export function snapshot(jsx: React.ElementType) {
  const tree = renderer.create(jsx);
  expect(tree.toJSON()).toMatchSnapshot();
}

export function timeoutSequence(done: Function, ...steps: Function[]) {
  if (steps.length > 0) {
    const [nextStep] = steps;
    const remaining = steps.slice(1);
    setTimeout(() => {
      try {
        nextStep();
        timeoutSequence(done, ...remaining);
      } catch (e) {
        done.fail(e);
      }
    });
  } else {
    done();
  }
}

export const TEST_EMAIL = 'test@email.com';

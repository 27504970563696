// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';
import {Block, Breakpoints, TitleBar} from 'react-foundation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {changeRoute} from '../route';

import './second-nav.css';

import barsIcon from '@fortawesome/fontawesome-free-solid/faBars';

import companyIcon from './images/company-icon.svg';
import systemsIcon from './images/manage-systems-icon.svg';
import usersIcon from './images/manage-users-icon.svg';
import sitesIcon from './images/manage-sites-icon.svg';

type PropsType = {
  userLoggedIn: boolean,
  userIsOrgAdmin: boolean,
  userIsOrgServicer: boolean
};

type ComponentStateType = {
  menuExpanded: boolean
};

class SecondNav extends Component<PropsType, ComponentStateType> {
  constructor() {
    super();
    this.state = {
      menuExpanded: false
    };
  }

  toggleMenu() {
    this.setState({menuExpanded: !this.state.menuExpanded});
  }

  goToManageSystems = () => {
    this.setState({menuExpanded: false});
    changeRoute('manage-systems');
  };

  goToManageSites = () => {
    this.setState({menuExpanded: false});
    changeRoute('manage-sites');
  };

  goToManageUsers = () => {
    this.setState({menuExpanded: false});
    changeRoute('manage-users');
  };

  goToCompanyProfile = () => {
    this.setState({menuExpanded: false});
    changeRoute('org-profile');
  };

  render() {
    const {menuExpanded} = this.state;
    const {userLoggedIn, userIsOrgAdmin, userIsOrgServicer} = this.props;

    if (!userLoggedIn) {
      return null;
    }

    return (
      <div className="SecondNav">
        <Block showOnlyFor={Breakpoints.SMALL}>
          <TitleBar className="grid-x second-nav-title-bar">
            <div className="second-nav-icons cell small-2">
              <a onClick={this.toggleMenu.bind(this)}>
                <div className="labeled-icon clearfix">
                  <FontAwesomeIcon
                    icon={barsIcon}
                    className="float-left bars-icon"
                    size="2x"
                  />
                  <div className="text-uppercase text-left icon-label menu-label-txt">
                    System Menu
                  </div>
                </div>
              </a>
            </div>
          </TitleBar>
          {menuExpanded ? (
            <div className="navbar-tray grid-x second-nav-icons text-left">
              {userIsOrgAdmin ? (
                <div className="cell small-12 navbar-tray-item">
                  <a
                    title="Manage Systems"
                    className="manage-systems-link"
                    onClick={this.goToManageSystems}
                  >
                    <div className="labeled-icon clearfix">
                      <div className="icon float-left">
                        <img src={systemsIcon} alt="Manage Systems" />
                      </div>
                      <div className="text-uppercase text-left icon-label">
                        Manage Systems
                      </div>
                    </div>
                  </a>
                </div>
              ) : null}
              {userIsOrgAdmin ? (
                <div className="cell small-12 navbar-tray-item">
                  <a
                    title="Manage Users"
                    className="manage-users-link"
                    onClick={this.goToManageUsers}
                  >
                    <div className="labeled-icon clearfix">
                      <div className="icon float-left">
                        <img src={usersIcon} alt="Manage Users" />
                      </div>
                      <div className="text-uppercase text-left icon-label">
                        Manage Users
                      </div>
                    </div>
                  </a>
                </div>
              ) : null}
              {userIsOrgAdmin || userIsOrgServicer ? (
                <div className="cell small-12 navbar-tray-item">
                  <a
                    title="Manage Sites"
                    className="manage-sites-link"
                    onClick={this.goToManageSites}
                  >
                    <div className="labeled-icon clearfix">
                      <div className="icon float-left">
                        <img src={sitesIcon} alt="Manage Sites" />
                      </div>
                      <div className="text-uppercase text-left icon-label">
                        Manage Sites
                      </div>
                    </div>
                  </a>
                </div>
              ) : null}
              <div className="cell small-12 navbar-tray-item">
                <a
                  title="Company Profile"
                  className="company-profile-link"
                  onClick={this.goToCompanyProfile}
                >
                  <div className="labeled-icon clearfix">
                    <div className="icon float-left">
                      <img src={companyIcon} alt="Company Profile" />
                    </div>
                    <div className="text-uppercase text-left icon-label">
                      Company Profile
                    </div>
                  </div>
                </a>
              </div>
            </div>
          ) : null}
        </Block>
        <Block showFor={Breakpoints.MEDIUM}>
          <TitleBar className="grid-x second-nav-title-bar">
            <div className="second-nav-icons left-set cell small-12 text-left">
              {userIsOrgAdmin ? (
                <div className="icon-link">
                  <a
                    title="Manage Systems"
                    className="manage-systems-link"
                    onClick={this.goToManageSystems}
                  >
                    <div className="labeled-icon clearfix">
                      <div className="icon float-left">
                        <img src={systemsIcon} alt="Manage Systems" />
                      </div>
                      <div className="text-uppercase text-left icon-label">
                        Manage Systems
                      </div>
                    </div>
                  </a>
                </div>
              ) : null}
              {userIsOrgAdmin ? (
                <div className="icon-link">
                  <a
                    title="Manage Users"
                    className="manage-users-link"
                    onClick={this.goToManageUsers}
                  >
                    <div className="labeled-icon clearfix">
                      <div className="icon float-left">
                        <img src={usersIcon} alt="Manage Users" />
                      </div>
                      <div className="text-uppercase text-left icon-label">
                        Manage Users
                      </div>
                    </div>
                  </a>
                </div>
              ) : null}
              {userIsOrgAdmin || userIsOrgServicer ? (
                <div className="icon-link">
                  <a
                    title="Manage Sites"
                    className="manage-sites-link"
                    onClick={this.goToManageSites}
                  >
                    <div className="labeled-icon clearfix">
                      <div className="icon float-left">
                        <img src={sitesIcon} alt="Manage Sites" />
                      </div>
                      <div className="text-uppercase text-left icon-label">
                        Manage Sites
                      </div>
                    </div>
                  </a>
                </div>
              ) : null}
              <div className="icon-link">
                <a
                  title="Company Profile"
                  className="company-profile-link"
                  onClick={this.goToCompanyProfile}
                >
                  <div className="labeled-icon clearfix">
                    <div className="icon float-left">
                      <img src={companyIcon} alt="Company Profile" />
                    </div>
                    <div className="text-uppercase text-left icon-label">
                      Company Profile
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </TitleBar>
        </Block>
      </div>
    );
  }
}

export default watch(SecondNav, {
  userIsOrgAdmin: 'sessionInfo.userIsOrgAdmin',
  userIsOrgServicer: 'sessionInfo.userIsOrgServicer',
  userLoggedIn: 'sessionInfo.userLoggedIn'
});

// @flow

export const COUNTRY_OPTIONS = [
  {
    value: 'US',
    display: 'US - United States'
  },
  {
    value: 'CA',
    display: 'CA - Canada'
  }
];

export function getStateLabelForCountry(countryCode: ?string): string {
  if (countryCode === 'CA') {
    return 'Province/Territory';
  }

  return 'State';
}

export function getCityLabelForCountry(countryCode: ?string): string {
  if (countryCode === 'CA') {
    return 'Municipality';
  }

  return 'City';
}

export function getZipCodeLabelForCountry(countryCode: ?string): string {
  if (countryCode === 'CA') {
    return 'Postal Code';
  }

  return 'Zip Code';
}

export function getZipCodeValidationForCountry(countryCode: ?string) {
  const usZipcodeRegex = /^\d{5}(-\d{4})?$/;
  const usZipcodeError =
    'Zip code format is incorrect. ex: ##### or #####-####';
  const caZipcodeRegex = /^[A-Z]\d[A-Z] \d[A-Z]\d$/;
  const caZipcodeError = 'Postal code format is incorrect. ex: A#A #A#';

  if (countryCode === 'CA') {
    return {
      regex: caZipcodeRegex,
      message: caZipcodeError
    };
  }

  return {
    regex: usZipcodeRegex,
    message: usZipcodeError
  };
}

export function checkZipCode(countryCode: ?string, zipCode: ?string) {
  if (!zipCode) {
    return [];
  }

  const checker = getZipCodeValidationForCountry(countryCode);

  if (!checker.regex.test(zipCode)) {
    return [checker.message];
  }

  return [];
}

// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';
import {Block, Breakpoints} from 'react-foundation';

import WizardBox from '../general-components/wizard-box';
import Select from '../general-components/select';
import LabelledInput from '../labelled-input/labelled-input';
import LabelledPhoneInput from '../labelled-input/labelled-phone-input';
import {createOrg} from '../organization/organization-service';
import {updateUser, logout} from '../user/user-service';
import {changeRoute} from '../route';
import {checkEmail, checkPhone} from '../user/user-validate';
import {buildClassName} from '../util/component-util';

import {
  checkZipCode,
  getStateLabelForCountry,
  getCityLabelForCountry,
  getZipCodeLabelForCountry,
  COUNTRY_OPTIONS
} from '../util/l10n-util';

import './create-org.css';

import logoutIcon from './images/logout-icon.svg';
import nextIcon from './images/next-icon.svg';

import type {NewOrgType, UserType} from '../types';

type PropsType = {
  orgInputs: NewOrgType,
  user: UserType,
  userHasOrg: boolean
};

type MyStateType = {
  errors: string[],
  emailInvalid: boolean,
  emailFocusCount: number,
  nameInvalid: boolean,
  phoneInvalid: boolean,
  zipInvalid: boolean,
  busy: boolean
};

class CreateOrg extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      errors: [],
      emailInvalid: false,
      emailFocusCount: 0,
      nameInvalid: false,
      phoneInvalid: false,
      zipInvalid: false,
      busy: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    if (nextProps.userHasOrg) {
      changeRoute('created-org');
    } else {
      this.validate(nextProps.orgInputs, this.state.emailFocusCount);
    }
  }

  onLogout = () => {
    logout();
  };

  onEmailBlur = () => {
    const newFocusCount = this.state.emailFocusCount + 1;
    this.setState({
      emailFocusCount: newFocusCount
    });
    this.validate(this.props.orgInputs, newFocusCount);
  };

  onCreateOrg = async () => {
    const {user, orgInputs} = this.props;
    const {errors} = this.state;

    if (orgInputs.name && errors.length === 0) {
      this.setState({
        busy: true
      });

      try {
        await createOrg(orgInputs);
        await updateUser(user.id);
      } catch (e) {
        console.error('Org creation failed', e);
      }

      this.setState({
        busy: false
      });
    }
  };

  validate(inputs: NewOrgType, emailFocusCount: number) {
    let nameInvalid = false;
    let emailInvalid = false;
    let errors = [];

    const zipErrors = checkZipCode(inputs.country, inputs.zipcode);
    const zipInvalid = zipErrors.length > 0;
    errors = errors.concat(zipErrors);

    const phoneErrors = checkPhone(inputs.phone);
    const phoneInvalid = phoneErrors.length > 0;
    errors = errors.concat(phoneErrors);

    if (!inputs.name) {
      nameInvalid = true;
      errors.push('Mandatory Field. Please provide information.');
    }

    const emailErrors = emailFocusCount >= 1 ? checkEmail(inputs.email) : [];
    emailInvalid = emailErrors.length > 0;
    errors = errors.concat(emailErrors);

    this.setState({
      nameInvalid,
      emailInvalid,
      phoneInvalid,
      zipInvalid,
      errors
    });
  }

  render() {
    const {orgInputs} = this.props;
    const {
      nameInvalid,
      emailInvalid,
      emailFocusCount,
      phoneInvalid,
      zipInvalid,
      errors,
      busy
    } = this.state;

    const stateLabel = getStateLabelForCountry(orgInputs.country).toUpperCase();
    const cityLabel = getCityLabelForCountry(orgInputs.country).toUpperCase();
    const zipLabel = getZipCodeLabelForCountry(orgInputs.country).toUpperCase();

    const nextEnabled = orgInputs.name && errors.length === 0;

    const nextClassName = buildClassName(
      'option',
      'next-option',
      !nextEnabled ? 'disabled' : null
    );

    const labelWidth = orgInputs.country === 'CA' ? 125 : 102;

    return (
      <WizardBox className="create-org">
        <div className="grid-x">
          <div className="cell small-12 top-banner">
            <div className="grid-x">
              <div className="cell small-12 text-right">
                <div className="option">
                  <a onClick={this.onLogout}>
                    <div className="option-label text-uppercase">Log Out</div>
                    <div className="option-icon">
                      <img src={logoutIcon} alt="Logout" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="cell small-12 spacer" />
              <div className="cell medium-2 small-12 text-right medium-order-2 small-order-1">
                <span>
                  {busy ? (
                    <span className="working">Working...</span>
                  ) : (
                    <div className={nextClassName}>
                      <a className="next-link" onClick={this.onCreateOrg}>
                        <div className="option-label text-uppercase">Next</div>
                        <div className="option-icon">
                          <img src={nextIcon} alt="Next" />
                        </div>
                      </a>
                    </div>
                  )}
                </span>
              </div>
              <div className="cell medium-8 medium-offset-2 small-12 medium-order-1 small-order-2">
                <h2>
                  <strong>
                    Welcome to{' '}
                    <span className="text-light-blue-alt">intelliGen™</span>
                  </strong>
                </h2>
              </div>
            </div>
          </div>
          <div className="cell small-12 padding-top-3 bottom-portion">
            <div className="grid-container fluid">
              <div className="grid-x align-center top-grid">
                <div className="cell small-12 text-left wizard-cell-full-width">
                  <div className="setup-instructions text-little-blue">
                    Setup your Account.
                    <br />
                    Create a Company Profile.
                  </div>
                </div>
                <div className="cell small-12 text-left wizard-cell-full-width">
                  <p className="detail-instructions">
                    By creating a Company, you will be able to add, remove and
                    manage users inside your organization. You can edit this
                    information later in the Company Profile section.
                  </p>
                </div>
              </div>
              <div className="grid-x align-center input-grid">
                <div className="cell text-left wizard-cell small-12 medium-6 large-6">
                  <p className="column-heading">Company Information</p>
                  <LabelledInput
                    className="name-input"
                    label="COMPANY NAME*"
                    type="text"
                    path="forms.createOrg.name"
                    disabled={busy}
                    isInvalid={nameInvalid}
                    autoFocus
                    labelWidth={labelWidth}
                    maxLength={30}
                  />
                  <LabelledPhoneInput
                    label="PHONE"
                    type="text"
                    path="forms.createOrg.phone"
                    disabled={busy}
                    labelWidth={labelWidth}
                    isInvalid={phoneInvalid}
                  />
                  <LabelledInput
                    label="EMAIL"
                    type="text"
                    path="forms.createOrg.email"
                    disabled={busy}
                    isInvalid={emailFocusCount >= 1 && emailInvalid}
                    labelWidth={labelWidth}
                    onBlur={this.onEmailBlur}
                    maxLength={50}
                  />
                  <LabelledInput
                    label="WEBSITE"
                    type="text"
                    path="forms.createOrg.website"
                    disabled={busy}
                    labelWidth={labelWidth}
                    maxLength={100}
                  />
                  {errors.length > 0 ? (
                    <Block className="error-box" showFor={Breakpoints.LARGE}>
                      {errors.map(error => (
                        <div className="text-alert" key={error}>
                          {error}
                        </div>
                      ))}
                    </Block>
                  ) : null}
                </div>
                <div className="cell text-left wizard-cell small-12 medium-6 large-6">
                  <p className="column-heading">Company Address</p>
                  <div>
                    <Select
                      className="country-select"
                      path="forms.createOrg.country"
                      disabled={busy}
                      options={COUNTRY_OPTIONS}
                    />
                  </div>
                  <LabelledInput
                    label="ADDRESS 1"
                    type="text"
                    path="forms.createOrg.address1"
                    disabled={busy}
                    labelWidth={labelWidth}
                    maxLength={35}
                  />
                  <LabelledInput
                    label="ADDRESS 2"
                    type="text"
                    path="forms.createOrg.address2"
                    disabled={busy}
                    labelWidth={labelWidth}
                    maxLength={35}
                  />
                  <LabelledInput
                    label={zipLabel}
                    type="text"
                    path="forms.createOrg.zipcode"
                    disabled={busy}
                    isInvalid={zipInvalid}
                    labelWidth={labelWidth}
                    maxLength={11}
                  />
                  <LabelledInput
                    label={cityLabel}
                    type="text"
                    path="forms.createOrg.city"
                    disabled={busy}
                    labelWidth={labelWidth}
                    maxLength={35}
                  />
                  <LabelledInput
                    label={stateLabel}
                    type="text"
                    path="forms.createOrg.state"
                    disabled={busy}
                    labelWidth={labelWidth}
                    maxLength={35}
                  />
                </div>
              </div>
              <div className="grid-x align-center last-grid">
                <div className="cell wizard-cell medium-6 small-12 text-left mandatory-text">
                  {errors.length > 0 ? (
                    <Block className="error-box" hideFor={Breakpoints.LARGE}>
                      {errors.map(error => (
                        <div className="text-alert" key={error}>
                          {error}
                        </div>
                      ))}
                    </Block>
                  ) : null}
                  *Mandatory
                </div>
                <div className="cell wizard-cell medium-6 small-12" />
              </div>
            </div>
          </div>
        </div>
      </WizardBox>
    );
  }
}

export default watch(CreateOrg, {
  orgInputs: 'forms.createOrg',
  user: 'user',
  userHasOrg: 'sessionInfo.userHasOrg'
});

// @flow

import {fetchJson, userUrl} from '../util/rest-util';
import {dispatchSet} from 'redux-easy';

async function loadBuildInfo() {
  try {
    const url = userUrl('build/');
    const res = await fetchJson(url);
    const {status} = res;
    if (status === 200) {
      const body = await res.json();
      dispatchSet('ui.buildInfoLoaded', true);
      dispatchSet('ui.buildid', body.buildid);
      dispatchSet('ui.recaptchaSiteKey', body.recaptchaSiteKey);
    } else {
      dispatchSet('ui.buildInfoLoaded', false);
      dispatchSet('ui.buildid', 'Error');
    }
  } catch (err) {
    dispatchSet('ui.buildInfoLoaded', false);
    dispatchSet('ui.buildid', 'Error');
    console.error(err);
  }
}

export {loadBuildInfo};

// @flow

import React, {Component} from 'react';

import {buildClassName} from '../util/component-util';

import './labelled-cell.css';

type PropsType = {
  label: string,
  className?: string,
  labelClassName?: string
};

export default class LabelledCell extends Component<PropsType> {
  render() {
    // ESLint and Flow really don't like me using the 'children'
    // property.
    /* eslint-disable react/prop-types */
    const {
      label,
      className,
      labelClassName,
      // $FlowFixMe -- props.children is provided by React
      children
    } = this.props;

    return (
      <div className={buildClassName('labelled-cell', className)}>
        <div className={buildClassName('labelled-cell-label', labelClassName)}>
          <div className="labelled-cell-label-text">{label}</div>
          <div className="labelled-cell-content">{children}</div>
        </div>
      </div>
    );
  }
}

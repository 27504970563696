// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';
import {changeRoute} from '../route';
import Modal from 'react-modal';

import AdminBar from '../admin-bar/admin-bar';
import CreateAccount from '../create-account/create-account';
import CreateOrg from '../create-org/create-org';
import CreateOrgMaybe from '../create-org/create-org-maybe';
import CreatedOrg from '../create-org/created-org';
import CreatedAccount from '../created-account/created-account';
import EditProfile from '../user/edit-profile';
import EditMember from '../organization/edit-member';
import EditSite from '../sites/edit-site';
import ForgotPassword from '../forgot-password/forgot-password';
import Login from '../login/login';
import MagicLink from '../magic-link/magic-link';
import ManageSystems from '../systems/manage-systems';
import ManageSites from '../sites/manage-sites';
import Notifications from '../notification/notifications';
import OrgProfile from '../organization/organization-profile';
import PasswordChange from '../password-change/password-change';
import PrismToaster from '../prism-toaster/prism-toaster';
import SecondNav from '../second-nav/second-nav';
import SystemDetail from '../system-detail/system-detail';
import Systems from '../systems/systems';
import SystemProfile from '../systems/system-profile';
import TopNav from '../top-nav/top-nav';
import UserMgmt from '../user-mgmt/user-mgmt';

import {loadBuildInfo} from '../build-info/build-info-svc';

import './app.css';

const defaultRoute = 'login';

const noNavs = [
  'login',
  'create-account',
  'created-account',
  'forgot-password',
  'create-org',
  'create-org-maybe',
  'created-org',
  'system-detail'
];

const noAuthRoutes = [
  'login',
  'create-account',
  'created-account',
  'forgot-password',
  'ml',
  'password-change'
];

const componentMap = {
  'create-account': <CreateAccount />,
  'created-account': <CreatedAccount />,
  'create-org': <CreateOrg />,
  'create-org-maybe': <CreateOrgMaybe />,
  'created-org': <CreatedOrg />,
  'edit-member': <EditMember />,
  'edit-site': <EditSite />,
  profile: <EditProfile />,
  'forgot-password': <ForgotPassword />,
  login: <Login />,
  ml: <MagicLink />,
  'manage-systems': <ManageSystems />,
  'manage-sites': <ManageSites />,
  'manage-users': <UserMgmt />,
  notifications: <Notifications />,
  'org-profile': <OrgProfile />,
  'password-change': <PasswordChange />,
  'system-detail': <SystemDetail />,
  'system-profile': <SystemProfile />,
  systems: <Systems />
};

type PropsType = {
  hash: string,
  // ESLint does not recognize that this prop is
  // read in `getDerivedStateFromProps`
  // eslint-disable-next-line react/no-unused-prop-types
  userAuthenticated: boolean,
  buildInfoLoaded: boolean
};

type MyStateType = {
  isLoading: boolean,
  validatedRoute: string
};

export class App extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      validatedRoute: 'login'
    };
  }

  static getDerivedStateFromProps(props: PropsType): MyStateType {
    const {hash, userAuthenticated} = props;

    let validatedRoute = hash;
    if (!Object.keys(componentMap).includes(hash)) {
      validatedRoute = defaultRoute;
    }

    if (!userAuthenticated && !noAuthRoutes.includes(hash)) {
      validatedRoute = defaultRoute;
    }

    return {
      isLoading: validatedRoute !== hash,
      validatedRoute
    };
  }

  componentDidUpdate() {
    const {hash, buildInfoLoaded} = this.props;
    const {validatedRoute} = this.state;

    if (!buildInfoLoaded) {
      loadBuildInfo();
    }

    if (hash !== validatedRoute) {
      changeRoute(validatedRoute);
    }
  }

  render() {
    const {isLoading, validatedRoute} = this.state;

    return (
      <div className="app" ref={app => Modal.setAppElement(app)}>
        {noNavs.includes(validatedRoute) ? null : (
          <div>
            <TopNav />
            <SecondNav />
          </div>
        )}
        <AdminBar />
        <PrismToaster />
        {isLoading ? <div>Loading...</div> : componentMap[validatedRoute]}
      </div>
    );
  }
}

export default watch(App, {
  buildInfoLoaded: 'ui.buildInfoLoaded',
  hash: 'ui.hash',
  userAuthenticated: 'user.authenticated'
});

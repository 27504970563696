// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import GeneralPage from '../general-components/general-page';
import GeneralPageEmptyMessage from '../general-components/general-page-empty-message';
import NotificationItem from './notification-item';
import * as userService from '../user/user-service';
import sysService from '../systems/system-service';

import actionRequiredIcon from '@fortawesome/fontawesome-free-solid/faExclamationCircle';

import newMarker from './images/new-marker.svg';

import type {NotificationType, SystemType, UserType} from '../types';

import './notifications.css';

type PropsType = {
  notifications: NotificationType[],
  systems: SystemType[],
  user: UserType
};

type StateType = {
  doingUpdate: boolean
};

class Notifications extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      doingUpdate: false
    };
  }

  async markUnreadAsRead(notifications, user) {
    if (notifications.length > 0) {
      const lastCreated = notifications
        .map(notification => new Date(notification.created))
        .reduce((x, y) => (x > y ? x : y), new Date(0));
      const lastViewedNotifications = new Date(user.lastViewedNotifications);
      if (lastViewedNotifications < lastCreated) {
        this.setState({doingUpdate: true});
        await userService.updateUserProfileInfo(user.id, {
          lastViewedNotifications: lastCreated
        });
        await userService.updateUser(user.id);
        this.setState({doingUpdate: false});
      }
    }
  }

  componentDidMount() {
    sysService.startPolling();
  }

  componentWillUnmount() {
    sysService.stopPolling();
  }

  async componentDidUpdate() {
    const {notifications, user} = this.props;
    const {doingUpdate} = this.state;
    if (!doingUpdate) {
      await this.markUnreadAsRead(notifications, user);
    }
  }

  render() {
    const {notifications, systems, user} = this.props;

    return (
      <GeneralPage
        title="Notifications"
        className="notifications"
        contentClassName="notifications-content"
      >
        <div className="top-section grid-x">
          <div className="cell large-3 medium-6 small-12">
            <div className="legend-item">
              <div className="legend-icon-box">
                <img
                  src={newMarker}
                  className="legend-icon"
                  alt="New Notification Marker"
                />
              </div>
              <div className="legend-text">New Notification</div>
            </div>
          </div>
          <div className="cell large-3 medium-6 small-12">
            <div className="legend-item">
              <div className="legend-icon-box">
                <FontAwesomeIcon
                  icon={actionRequiredIcon}
                  className="legend-icon action-required-icon"
                />
              </div>
              <div className="legend-text">User Action Available</div>
            </div>
          </div>
        </div>
        {notifications.length === 0 ? (
          <div className="grid-x">
            <div className="cell small-12">
              <GeneralPageEmptyMessage>
                You do not have any notifications.
              </GeneralPageEmptyMessage>
            </div>
          </div>
        ) : (
          <div className=" notification-list grid-x">
            {notifications.map(notification => (
              <div key={notification.id} className="cell small-12">
                <NotificationItem
                  notification={notification}
                  systems={systems}
                  lastViewedNotifications={user.lastViewedNotifications}
                  user={user}
                />
              </div>
            ))}
          </div>
        )}
      </GeneralPage>
    );
  }
}

export default watch(Notifications, {
  notifications: 'notifications',
  systems: 'systems',
  user: 'user'
});

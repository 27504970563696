// @flow

import React, {Component} from 'react';
import {Button} from 'react-foundation';
import {watch} from 'redux-easy';

import CollapsibleGridContainer from '../general-components/collapsible-grid-container';
import CollapsibleGrid from '../general-components/collapsible-grid';
import CollapsibleGridRow from '../general-components/collapsible-grid-row';

import * as userService from '../user/user-service';
import {changeRoute} from '../route';

import type {OrgMemberType, UserType} from '../types';

// eslint-disable-next-line no-duplicate-imports
import type {ColumnDescriptorType} from '../general-components/collapsible-grid';

import './user-list.css';

type PropsType = {
  user: UserType,
  isOrgAdmin: boolean,
  orgMembers: OrgMemberType[]
};

const COLUMNS: ColumnDescriptorType[] = [
  {
    label: 'Name',
    size: 2,
    className: 'name-header',
    dataClassName: 'name-data'
  },
  {
    label: 'Role',
    size: 2,
    className: 'role-header',
    dataClassName: 'role-data'
  },
  {
    label: 'Phone',
    size: 2,
    className: 'phone-header',
    dataClassName: 'phone-data'
  },
  {
    label: 'Email',
    size: 4,
    className: 'email-header',
    dataClassName: 'email-data'
  },
  {
    label: '',
    size: 2,
    className: 'go-btn-header',
    dataClassName: 'go-btn-data btn-container'
  }
];

class UserList extends Component<PropsType> {
  editUser = async (userId: number) => {
    const currentUser = this.props.user;

    if (currentUser.id === userId) {
      changeRoute('profile');
    } else {
      await userService.loadOrgUserToEdit(userId);
      changeRoute('edit-member');
    }
  };

  convertToRow = (member: OrgMemberType, isOrgAdmin: boolean) => {
    const cells = [
      `${member.firstName} ${member.lastName}`,
      member.userRoles.map(role => (
        <div key={role} className="member-role">
          {role}
        </div>
      )),
      member.phone,
      member.email,
      isOrgAdmin ? (
        <div className="grid-x grid-padding-x">
          <div className="cell small-12">
            <Button
              key={`go-${member.userId}`}
              className="text-uppercase user-profile-button"
              isHollow
              isExpanded
              color="little-blue"
              onClick={this.editUser.bind(this, member.userId)}
            >
              Go To Profile
            </Button>
          </div>
        </div>
      ) : null
    ];

    return (
      <CollapsibleGridRow
        key={member.userId}
        className="member-row"
        columns={COLUMNS}
        cells={cells}
      />
    );
  };

  render() {
    const {isOrgAdmin, orgMembers} = this.props;

    return (
      <CollapsibleGridContainer className="member-list">
        <div className="title-header text-uppercase">Members</div>
        <div className="collapsible-grid-divider" />
        <CollapsibleGrid className="member-grid" columns={COLUMNS}>
          {orgMembers.map(member => this.convertToRow(member, isOrgAdmin))}
        </CollapsibleGrid>
      </CollapsibleGridContainer>
    );
  }
}

export default watch(UserList, {
  isOrgAdmin: 'sessionInfo.userIsOrgAdmin',
  orgMembers: '',
  user: ''
});

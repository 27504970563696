// @flow

import React, {Component} from 'react';
import {Button} from 'react-foundation';

import TitledModal from '../general-components/titled-modal';
import LabelledRawInput from '../labelled-input/labelled-raw-input';
import {checkEmail} from '../user/user-validate';
import sysSvc from './system-service';

import {buildClassName} from '../util/component-util';

import './invite-user-to-system-modal.css';

import type {SystemOrgRelNameType} from '../types';

type UIEventType = {
  target: {
    value: string
  }
};

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onInviteSuccess: Function,
  relationship: SystemOrgRelNameType,
  title: string,
  text: string,
  systemId: string,
  className?: ?string
};

type MyStateType = {
  busy: boolean,
  emailToInvite: string,
  emailFocusCount: number,
  errors: string[],
  valid: boolean
};

const INITIAL_STATE = {
  busy: false,
  emailToInvite: '',
  emailFocusCount: 0,
  errors: [],
  valid: true
};

export default class InviteUserToSystemModal extends Component<
  PropsType,
  MyStateType
> {
  constructor() {
    super();
    this.state = {
      ...INITIAL_STATE
    };
  }

  clearAndClose = () => {
    const {onRequestClose} = this.props;
    this.setState({
      ...INITIAL_STATE
    });
    onRequestClose();
  };

  onEmailChanged = (event: UIEventType) => {
    const email = event.target.value;

    const errors = checkEmail(email);

    this.setState({
      emailToInvite: email,
      errors,
      valid: errors.length === 0
    });
  };

  onEmailBlur = () => {
    this.setState({
      emailFocusCount: this.state.emailFocusCount + 1
    });
  };

  doInvite = async () => {
    const {onInviteSuccess, systemId, relationship} = this.props;
    const {emailToInvite} = this.state;

    this.setState({
      busy: true
    });

    const result = await sysSvc.inviteOrgToSystem(
      systemId,
      emailToInvite,
      relationship
    );

    if (result.success) {
      this.setState({
        busy: false,
        emailToInvite: '',
        errors: [],
        valid: true
      });
      onInviteSuccess();
    } else {
      this.setState({
        busy: false,
        errors: [result.message],
        valid: false
      });
    }
  };

  render() {
    const {errors, emailToInvite, emailFocusCount, valid, busy} = this.state;
    const {isOpen, title, text, className} = this.props;

    return (
      <TitledModal
        title={title}
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.clearAndClose}
        className={buildClassName(
          'invite-user-to-system-modal',
          'minor',
          className
        )}
        contentClassName="grid-x grid-margin-x invite-user-to-system-modal-content"
      >
        <div className="cell small-12 text-cell invite-system-partners-text">{text}</div>
        <div className="cell small-12 input-cell">
          <LabelledRawInput
            label="EMAIL"
            autoFocus
            className="email-input"
            isInvalid={emailFocusCount >= 1 && !valid}
            type="text"
            value={emailToInvite}
            onChange={this.onEmailChanged}
            onBlur={this.onEmailBlur}
            disabled={busy}
            maxLength={50}
          />
        </div>
        <div className="cell small-12 error-box">
          {emailFocusCount >= 1 ?
            errors.map((message, i) => (
              <div key={i} className="text-alert">
                {message}
              </div>
            )) :
            null}
        </div>
        <div className="cell small-12 spacer-div" />
        <div className="cell small-6 medium-4 medium-offset-2">
          <Button
            className="text-uppercase cancel-btn"
            color="black"
            disabled={busy}
            isExpanded
            isHollow
            onClick={this.clearAndClose}
          >
            Cancel
          </Button>
        </div>
        <div className="cell small-6 medium-4">
          <Button
            disabled={!emailToInvite || !valid || busy}
            isExpanded
            isHollow
            className="text-uppercase ok-btn"
            color="black"
            onClick={this.doInvite}
          >
            OK
          </Button>
        </div>
      </TitledModal>
    );
  }
}

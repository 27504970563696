// @flow

import React, {Component} from 'react';
import TitledPanel from '../general-components/titled-panel';
import LabelledCellInput from '../general-components/labelled-cell-input';
import LabelledCellPhoneInput from '../general-components/labelled-cell-phone-input';

type PropsType = {
  disabled: boolean,
  basePath: string,
  emailErrors: string[],
  firstNameErrors: string[],
  lastNameErrors: string[],
  phoneErrors: string[],
  onChange: Function,
  onEmailChange: Function
};

type MyStateType = {
  somethingChanged: boolean,
  emailChanged: boolean
};

class UserInfoPanel extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      somethingChanged: false,
      emailChanged: false
    };
  }

  somethingChanged = () => {
    this.setState({
      somethingChanged: true
    });
  };

  emailChanged = () => {
    this.setState({
      emailChanged: true
    });
  };

  onBlur = () => {
    const {onChange} = this.props;
    const {somethingChanged} = this.state;

    if (somethingChanged) {
      onChange();
    }

    this.setState({somethingChanged: false});
  };

  onEmailBlur = () => {
    const {onEmailChange} = this.props;
    const {emailChanged} = this.state;

    if (emailChanged) {
      onEmailChange();
    }

    this.setState({emailChanged: false});
  };

  render() {
    const {
      disabled,
      basePath,
      emailErrors,
      firstNameErrors,
      lastNameErrors,
      phoneErrors
    } = this.props;

    return (
      <TitledPanel
        title="User Information"
        className="user-info-panel"
        contentClassName="grid-x grid-padding-x"
      >
        <div className="cell large-4 medium-6 small-12">
          <LabelledCellInput
            label="First Name"
            inputClassName="first-name-input"
            path={`${basePath}.firstName`}
            disabled={disabled}
            errors={firstNameErrors}
            onChange={this.somethingChanged}
            onBlur={this.onBlur}
            maxLength={20}
          />
        </div>
        <div className="cell large-4 medium-6 small-12">
          <LabelledCellInput
            label="Last Name"
            path={`${basePath}.lastName`}
            disabled={disabled}
            errors={lastNameErrors}
            onChange={this.somethingChanged}
            onBlur={this.onBlur}
            maxLength={20}
          />
        </div>
        <div className="cell large-4 medium-12 small-12" />
        <div className="cell large-4 medium-6 small-12">
          <LabelledCellPhoneInput
            label="Phone"
            path={`${basePath}.phone`}
            disabled={disabled}
            errors={phoneErrors}
            onChange={this.somethingChanged}
            onBlur={this.onBlur}
          />
        </div>
        <div className="cell large-4 medium-6 small-12">
          <LabelledCellInput
            label="Email"
            inputClassName="email-input"
            path={`${basePath}.email`}
            disabled={disabled}
            errors={emailErrors}
            onChange={this.emailChanged}
            onBlur={this.onEmailBlur}
            maxLength={50}
          />
        </div>
        <div className="cell large-4 medium-12 small-12`" />
      </TitledPanel>
    );
  }
}

export default UserInfoPanel;

// @flow

import React, {Component} from 'react';

import CheckBox from '../general-components/check-box';
import TitledPanel from '../general-components/titled-panel';

type PropsType = {
  disabled: boolean,
  basePath: string,
  onChange: Function
};

class UserRolePanel extends Component<PropsType> {
  render() {
    const {disabled, basePath, onChange} = this.props;

    return (
      <TitledPanel
        title="User Roles"
        className="user-role-panel"
        contentClassName="grid-x grid-padding-x user-role-content"
      >
        <div className="cell medium-4 small-12">
          <CheckBox
            className="text-left"
            path={`${basePath}.isSpectator`}
            name="spectator-check"
            disabled
            label="Spectator"
            styleName="dark-blue-square"
          />
        </div>
        <div className="cell medium-4 small-12">
          <CheckBox
            className="text-left servicer-check-input"
            path={`${basePath}.isServicer`}
            name="servicer-check"
            disabled={disabled}
            label="Servicer"
            styleName="dark-blue-square"
            onChange={onChange}
          />
        </div>
        <div className="cell medium-4 small-12">
          <CheckBox
            className="text-left"
            path={`${basePath}.isAdmin`}
            name="admin-check"
            disabled={disabled}
            label="Administrator"
            styleName="dark-blue-square"
            onChange={onChange}
          />
        </div>
      </TitledPanel>
    );
  }
}

export default UserRolePanel;

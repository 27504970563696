// @flow

import {dispatch} from 'redux-easy';
import {fetchJson, systemUrl} from '../util/rest-util';

import * as userSvc from '../user/user-service';

import type {WorkflowActionType} from '../types';

async function takeAction(
  action: WorkflowActionType,
  userId: ?number
): Promise<any> {
  const url = systemUrl(`workflow/${action.workflowId}/${action.action}`);
  const options = {
    method: 'POST'
  };
  const res = await fetchJson(url, options);

  if (res.status !== 200) {
    const body = await res.json();
    dispatch('addErrorToast', `Unable to perform this action: ${body.message}`);
  } else {
    const body = await res.json();
    dispatch('addToast', {
      title: 'Success',
      type: 'success',
      message: body.message
    });

    if (userId) {
      await userSvc.updateUser(userId);
    }
  }
}

export default {
  takeAction
};

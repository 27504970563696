// @flow

import React, { Component } from 'react';
import { Button } from 'react-foundation';

import TitledPanel from '../general-components/titled-panel';
import LabelledCell from '../general-components/labelled-cell';
import TransferSiteModal from './transfer-site-modal';
import SwapSiteModal from './swap-site-modal';
import siteSvc from './site-service';

import { getDate } from '../util/moment-util';
import { buildCondensedAddress } from '../util/component-util';

import './edit-site-owner-panel.css';


class EditSiteOwnerPanel extends Component {
  constructor() {
    super();
    this.state = {
      transferOwnerOpen: false,
      swapOwnerOpen: false
    };
  }

  cancelOwnerInvitation = async () => {
    const { site, ownerInvite,  onChange } = this.props;
    if (ownerInvite && ownerInvite.id) {
      await siteSvc.cancelSiteInvite(site.id, ownerInvite.id);
      onChange();
    }
  };

  openTransferOwnerModalConfirm = () => {
    this.setState({
      transferOwnerOpen: true
    });
  };

  toggleTransferOwnerModal = () => {
    this.setState({
      transferOwnerOpen: !this.state.transferOwnerOpen
    });
  };

  transferOwnerComplete = () => {
    const { onChange } = this.props;
    this.setState({
      transferOwnerOpen: false
    });
    onChange();
  };

  openSwapOwnerModalConfirm = () => {
    this.setState({
      swapOwnerOpen: true
    });
  };

  swapOwnerModalComplete = () => {
    const { onChange } = this.props;
    this.setState({
      swapOwnerOpen: false
    });
    onChange();
  };


  cancelSwapOwnerModal = () => {
    this.setState({
      swapOwnerOpen: false
    });
  };

  render() {
    const {
      owner,
      partner,
      ownerInvite,
      userRelType,
      site,
      userIsOrgAdmin
    } = this.props;
    const {
      transferOwnerOpen,
      swapOwnerOpen,
    } = this.state;

    const ownerToDisplay = owner ?
      owner :
      {
        name: 'unknown',
        email: '',
        phone: ''
      };
    const userIsOwner = userRelType === 'Owner';
    const ownerInviteStatus = ownerInvite && ownerInvite.swapRelationships ? 'Swap Pending' : 'Transfer Pending';
    const ownerInviteCancelButton = ownerInvite && ownerInvite.swapRelationships ? 'Cancel Swap Request' : 'Cancel Transfer Request';
    const inviteeOrgLabel = ownerInvite && ownerInvite.swapRelationships ? 'Owner Swap Requested' : 'New Owner Requested';
    const activeInvite = Boolean(ownerInvite);
    const transferOwnerDisabled = !userIsOwner || !userIsOrgAdmin || activeInvite;
    const swapOwnerDisabled = transferOwnerDisabled || !partner.length > 0;
    const cancelInvitationButtonDisabled = !userIsOwner || !userIsOrgAdmin;
    const invitaionExpirationDisplay =
      ownerInvite && ownerInvite.invitationExpires ?
        getDate(ownerInvite.invitationExpires).format('MM/DD/YYYY hh:mm a') :
        '';

    return (
      <TitledPanel title={'Site Owner'} className="edit-site-owner-panel">
        <TransferSiteModal
          isOpen={transferOwnerOpen}
          onRequestClose={this.toggleTransferOwnerModal}
          onSuccess={this.transferOwnerComplete}
          site={site}
          partner={partner}
          owner={owner}
        />
        <SwapSiteModal
          isOpen={swapOwnerOpen}
          onRequestClose={this.cancelSwapOwnerModal}
          onSuccess={this.swapOwnerModalComplete}
          site={site}
          owner={owner}
          partner={partner}
        />
        <div>
          <div className="grid-x grid-padding-x top-part">
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label="Owner">
                {ownerToDisplay.name}
              </LabelledCell>
            </div>
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label="Email">{ownerToDisplay.email}</LabelledCell>
            </div>
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label="Website">{ownerToDisplay.website}</LabelledCell>
            </div>
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label="Address">{buildCondensedAddress(ownerToDisplay)}</LabelledCell>
            </div>
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label="Phone">{ownerToDisplay.phone}</LabelledCell>
            </div>
          </div>
          {activeInvite ? (
            <div className="grid-x grid-padding-x bottom-part">
              <div className="cell small-12 medium-4 text-left">
                <LabelledCell label={inviteeOrgLabel}>
                  {ownerInvite.inviteeOrgName}
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4 text-left">
                <LabelledCell label="Email">{ownerInvite.inviteeEmail}</LabelledCell>
              </div>
              <div className="cell small-12 medium-4"></div>
              <div className="cell small-12 medium-4 text-left">
                <LabelledCell label="status">{ownerInviteStatus}</LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="Expires On">
                  {invitaionExpirationDisplay}
                </LabelledCell>
              </div>
              {userIsOwner ? (
                <div className="cell small-12 medium-4">
                  <div className="invite-cancel-buttons">
                    <Button
                      isHollow
                      isExpanded
                      color="little-blue"
                      className="text-uppercase"
                      onClick={this.cancelOwnerInvitation}
                      disabled={cancelInvitationButtonDisabled}
                    >
                      {ownerInviteCancelButton}
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          {userIsOwner ? (
            <div className="grid-x grid-padding-x bottom-part">
              <div className="cell small-12 medium-4">
                <LabelledCell label="">
                  <Button
                    isHollow
                    isExpanded
                    color="little-blue"
                    className="swap-owner-btn text-uppercase"
                    disabled={swapOwnerDisabled}
                    onClick={this.openSwapOwnerModalConfirm}
                  >
                    Swap Owner
                  </Button>
                </LabelledCell>
              </div>
              <div className="cell small-12 medium-4">
                <LabelledCell label="">
                  <Button
                    isHollow
                    isExpanded
                    color="little-blue"
                    className="transfer-owner-btn text-uppercase"
                    disabled={transferOwnerDisabled}
                    onClick={this.openTransferOwnerModalConfirm}
                  >
                    Transfer Owner
                  </Button>
                </LabelledCell>
              </div>
            </div>
          ) : null}
        </div>
      </TitledPanel>
    );
  }
}

export default EditSiteOwnerPanel;

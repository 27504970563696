// @flow

import React, {Component} from 'react';
import {Button} from 'react-foundation';
import moment from 'moment';

import StatusBead from './status-bead';
import CollapsibleGridRow from '../general-components/collapsible-grid-row';

import sysSvc from '../systems/system-service';

import './system.css';

import type {ColumnDescriptorType} from '../general-components/collapsible-grid';
import type {SystemType} from '../types';

type PropsType = {
  columns: ColumnDescriptorType[],
  system: SystemType,
  temperatureDisplayUnit: 'C' | 'F'
};

const TEMP_BOUNDARIES = {
  F: {
    min: -58,
    max: 158
  },
  C: {
    min: -50,
    max: 70
  }
};

type StringMapType = {
  [id: string]: string
};

const OPERATING_MODE_TEXT: StringMapType = {
  Off: 'OFF',
  Cooling: 'COOLING',
  Pumpdown: 'PUMP DOWN',
  Defrost: 'DEFROST',
  Drain: 'DRAINING',
  Delay: 'DELAY',
  'Box Load': 'PRODUCT LOAD',
  Manual: 'EXV MANUAL',
  Contingency: 'Active CONTINGENCY',
  'Factory Run Test': 'FACTORY TEST',
  Refreeze: 'REFREEZE',
  Service: 'SERVICE'
};

export default class System extends Component<PropsType> {
  onDetail = () => {
    const {system} = this.props;
    sysSvc.goToSystem(system);
  };

  buildStatus = (system: SystemType) => {
    if (!system.isConnected) {
      let disconnectedDays = 0;
      if (system.lastDisconnected) {
        const now = moment(new Date());
        const then = moment(system.lastDisconnected);
        const days = moment.duration(now.diff(then)).asDays();
        disconnectedDays = Math.floor(days);
      }

      const text = (
        <span className="disconnected-text">
          <span className="disconnected-message">System Disconnected</span>
          {disconnectedDays > 1 ? (
            <span className="disconnected-days">
              <br />
              {disconnectedDays} Days
            </span>
          ) : null}
        </span>
      );

      return {
        color: 'gray',
        text
      };
    }

    if (system.alarmCount && system.alarmCount > 0) {
      const s = system.alarmCount === 1 ? '' : 's';
      return {
        color: 'red',
        text: `Active Alarm${s}`
      };
    }

    if (system.errorCount && system.errorCount > 0) {
      const s = system.errorCount === 1 ? '' : 's';
      return {
        color: 'yellow',
        text: `Active Error${s}`
      };
    }

    const operatingMode = system.operatingMode || 'Connected';

    const text = OPERATING_MODE_TEXT[operatingMode] || operatingMode;

    return {
      color: 'green',
      text
    };
  };

  getDisplayTemperature(system: SystemType): string {
    const {temperatureDisplayUnit} = this.props;

    const tempProp =
      temperatureDisplayUnit === 'C' ? 'temperatureC' : 'temperature';

    const temp = system[tempProp];

    const {min, max} = TEMP_BOUNDARIES[temperatureDisplayUnit];

    if (typeof temp !== 'number' || temp < min || temp > max) {
      return '--';
    }

    let temp0 = Math.trunc(temp * 10);
    const temp00 = Math.trunc(temp * 100);
    const temp00str = String(temp00);
    const roundDigit = parseInt(
      temp00str.slice(temp00str.length - 1, temp00str.length),
      10
    );
    const inc = temp00 >= 0 ? 1 : -1;
    if (roundDigit >= 5) {
      temp0 += inc;
    }
    const temp0Str = String(temp0);
    return (
      temp0Str.slice(0, temp0Str.length - 1) +
      '.' +
      temp0Str.slice(temp0Str.length - 1, temp0Str.length) +
      ' º' +
      temperatureDisplayUnit
    );
  }

  render() {
    const {columns, system} = this.props;

    const status = this.buildStatus(system);

    const cells = [
      <div key={system.id} className="status">
        <div className="status-left">
          <StatusBead color={status.color} />{' '}
        </div>
        <div className="status-right">{status.text}</div>
      </div>,
      system.name || 'unknown',
      <span key={system.id}>{this.getDisplayTemperature(system)}</span>,
      system.alarmCount || 0,
      system.errorCount || 0,
      system.isConnected ? (
        <Button
          key={system.id}
          className="text-uppercase display-button"
          isExpanded
          isHollow
          onClick={this.onDetail}
          color="little-blue"
        >
          Go To System
        </Button>
      ) : null
    ];

    return (
      <CollapsibleGridRow className="system" columns={columns} cells={cells} />
    );
  }
}

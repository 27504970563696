// @flow

import React, {Component} from 'react';

import {Button} from 'react-foundation';

import WizardBox from '../general-components/wizard-box';
import {changeRoute} from '../route';

import './created-org.css';

// $FlowFixMe -- I don't have props or state
class CreatedOrg extends Component {
  onGoToDashboard = () => {
    changeRoute('systems');
  };

  render() {
    return (
      <WizardBox className="created-org">
        <div className="grid-x">
          <div className="cell small-12">
            <h1 className="welcome">
              <strong>Your account is ready</strong>
            </h1>
            <p className="extra-info">
              You can update your account information under User Profile.
            </p>
            <Button
              className="text-uppercase dash-button"
              onClick={this.onGoToDashboard}
              color="white"
              isHollow
            >
              Continue To Dashboard
            </Button>
          </div>
        </div>
      </WizardBox>
    );
  }
}

export default CreatedOrg;

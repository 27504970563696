// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';

import {Button} from 'react-foundation';

import WizardBox from '../general-components/wizard-box';

import {sendConfirmEmail} from '../create-account/confirm-email';
import {changeRoute} from '../route';
import {logErrorIfNotTest} from '../util/test-util';

import './created-account.css';

import type {UserType} from '../types';

type PropsType = {
  createdUser: ?UserType
};

type MyStateType = {
  busy: boolean,
  emailResent: boolean,
  emailResentError: boolean
};

class CreatedAccount extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      busy: false,
      emailResent: false,
      emailResentError: false
    };
  }

  goToLogin = () => {
    changeRoute('login');
  };

  onResend = async () => {
    this.setState({
      busy: true,
      emailResent: false,
      emailResentError: false
    });
    let success = false;

    if (this.props.createdUser) {
      const {email} = this.props.createdUser;
      if (email) {
        const msg = await sendConfirmEmail(email);
        success = !msg;
        if (msg) logErrorIfNotTest(msg);
      }
    }

    this.setState({
      busy: false,
      emailResent: success,
      emailResentError: !success
    });
  };

  render() {
    const {createdUser} = this.props;
    const {busy, emailResent, emailResentError} = this.state;

    if (!createdUser) {
      changeRoute('login');
    }

    const createdUserActive = createdUser && createdUser.active;

    return (
      <WizardBox className="created-account">
        <div className="grid-container fluid">
          <div className="grid-x align-center">
            <div className="cell small-12">
              <h1>
                <strong>
                  Welcome to{' '}
                  <span className="text-light-blue-alt">intelliGen™</span>
                </strong>
              </h1>
              <div className="account-created">Your account was created.</div>
              {!createdUserActive ? (
                <div>
                  <p className="instructions">
                    Check your email for an account confirmation link.
                  </p>
                  <p className="instructions">
                    Can{"'"}t find the confirmation email?
                  </p>
                  <Button
                    className="resend-btn text-uppercase"
                    onClick={this.onResend}
                    isHollow
                    isDisabled={busy}
                    color="white"
                  >
                    Resend Email
                  </Button>
                  {emailResent ? (
                    <p className="instructions bottom-instructions">
                      If you still cannot see the email, contact{' '}
                      <a
                        href="https://www.intelligencontrols.com/homepage/resources"
                        target="_support"
                      >
                        Customer Support
                      </a>
                      .
                    </p>
                  ) : null}
                  {emailResentError ? (
                    <p className="instructions">
                      We encountered an error while attempting to resend your
                      confirmation email.
                      <br />
                      Please contact support.
                    </p>
                  ) : null}
                </div>
              ) : (
                <div>
                  <p className="instructions">
                    Please login to start using{' '}
                    <span className="text-light-blue-alt">
                      <strong>intelliGen™</strong>
                    </span>
                  </p>
                  <Button
                    className="login-btn text-uppercase"
                    onClick={this.goToLogin}
                    isHollow
                    color="white"
                  >
                    Login
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </WizardBox>
    );
  }
}

export default watch(CreatedAccount, {
  createdUser: ''
});

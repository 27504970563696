// @flow

import React, {Component} from 'react';

import LabelledCell from './labelled-cell';
import PhoneInputBase from './phone-input-base';

import {buildClassName, cleanProps} from '../util/component-util';

type PropsType = {
  label: string,
  path: string,
  invalid?: boolean,
  errors?: string[],
  className?: string,
  labelClassName?: string,
  inputClassName?: string,
  errorsClassName?: string
};

export default class LabelledCellPhoneInput extends Component<PropsType> {
  render() {
    const {
      label,
      path,
      invalid,
      errors,
      className,
      labelClassName,
      inputClassName,
      errorsClassName
    } = this.props;

    const displayErrors = errors ? errors : [];

    const inputProps = cleanProps(this.props, [
      'label',
      'path',
      'invalid',
      'className',
      'labelClassName',
      'inputClassName'
    ]);

    return (
      <LabelledCell
        label={label}
        className={className}
        labelClassName={labelClassName}
      >
        <PhoneInputBase
          className={buildClassName(
            'labelled-cell-input',
            'labelled-cell-phone-input',
            invalid ? 'invalid' : null,
            inputClassName
          )}
          path={path}
          {...inputProps}
        />
        <div
          className={buildClassName(
            'labelled-cell-input-error-box',
            errorsClassName
          )}
        >
          {displayErrors.map((message, i) => (
            <div key={i} className="text-alert labelled-cell-input-error">
              {message}
            </div>
          ))}
        </div>
      </LabelledCell>
    );
  }
}

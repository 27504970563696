// @flow

import PropTypes from 'prop-types';
import {Button} from 'react-foundation';

// Manually overriding react-foundation "Color" propType for Button.
// I have added custom colors to the pallette that extend the standard
// options.
// eslint-disable-next-line react/forbid-foreign-prop-types
Button.propTypes.color = PropTypes.string;

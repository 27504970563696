// @flow

import React, {Component} from 'react';

import {buildClassName} from '../util/component-util';

import './collapsible-grid-header-label.css';

type PropsType = {
  className?: ?string
};

export default class CollapsibleGridHeaderLabel extends Component<PropsType> {
  render() {
    // ESLint and Flow really don't like me using the 'children'
    // property.
    /* eslint-disable react/prop-types */
    // $FlowFixMe -- props.children is provided by React
    const {children} = this.props;

    const {className} = this.props;

    const myClassName = buildClassName(
      'collapsible-grid-header-label',
      className
    );

    return <span className={myClassName}>{children}</span>;
  }
}

// @flow

import React, {Component} from 'react';
import {ToastMessageAnimated} from 'react-toastr';
import {dispatch, dispatchSet, watch} from 'redux-easy';

import './prism-toaster.css';

import errorIcon from './images/error.svg';
import successIcon from './images/success.svg';
import infoIcon from './images/info.svg';
import warningIcon from './images/warning.svg';

import type {ToastMessageType} from '../types';

type PropsType = {
  toasts: ToastMessageType[]
};

const iconMap = {
  info: infoIcon,
  success: successIcon,
  warning: warningIcon,
  error: errorIcon,
  notification: null
};

class PrismToaster extends Component<PropsType> {
  removeToast = i => {
    dispatch('removeToast', this.props.toasts[i]);
  };

  toastIsValid = toast =>
    typeof toast.message === 'string' || React.isValidElement(toast.message);

  componentDidUpdate() {
    const {toasts} = this.props;

    const newToasts = toasts.filter(this.toastIsValid);
    if (newToasts.length !== toasts.length) {
      dispatchSet('toasts', newToasts);
    }
  }

  render() {
    const {toasts} = this.props;

    const cleanToasts = toasts.filter(this.toastIsValid);

    const markedUpToasts = cleanToasts.map(toast => ({
      ...toast,
      toastContent: (
        <div className="toast-content grid-x grid-margin-x small-margin-collapse">
          {iconMap[toast.type] ? (
            <div className="toast-icon cell auto shrink">
              <img
                src={iconMap[toast.type]}
                width={25}
                alt={`${toast.type} notification`}
              />
            </div>
          ) : (
            <div className="empty-toast-icon toast-icon cell auto shrink" />
          )}
          <div className="toast-text text-left cell auto">
            <div className="toast-text-title">
              <strong>{toast.title || ' '}</strong>
            </div>
            <div className="toast-text-message">{toast.message}</div>
          </div>
          <div className="toast-close-button" />
        </div>
      )
    }));

    return (
      <div
        className="PrismToaster"
        style={{position: 'fixed', top: 100, right: '4vw', paddingLeft: '4vw'}}
      >
        {markedUpToasts.map((toast, i) => (
          <ToastMessageAnimated
            key={i}
            message={toast.toastContent}
            showAnimation="animated fadeInDown"
            hideAnimation="animated fadeOut"
            timeOut={10000}
            extendedTimeOut={0}
            onRemove={this.removeToast.bind(this, i)}
            className={'toast callout'}
          />
        ))}
      </div>
    );
  }
}

export default watch(PrismToaster, {
  toasts: ''
});

// @flow

import React, {Component} from 'react';
import {Button} from 'react-foundation';

import TitledModal from '../general-components/titled-modal';
import LabelledRawInput from '../labelled-input/labelled-raw-input';
import {checkEmail} from '../user/user-validate';
import siteSvc from './site-service';

import './add-site-partner-modal.css';

import type {SiteType} from '../types';

type UIEventType = {
  target: {
    value: string
  }
};

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onSuccess: Function,
  site: SiteType
};

type MyStateType = {
  busy: boolean,
  emailToInvite: string,
  emailFocusCount: number,
  errors: string[],
  valid: boolean
};

const INITIAL_STATE = {
  busy: false,
  emailToInvite: '',
  emailFocusCount: 0,
  errors: [],
  valid: true
};

export default class AddSitePartnerModal extends Component<
  PropsType,
  MyStateType
> {
  constructor() {
    super();
    this.state = {
      ...INITIAL_STATE
    };
  }

  clearAndClose = () => {
    const {onRequestClose} = this.props;
    this.setState({
      ...INITIAL_STATE
    });

    onRequestClose();
  };

  onEmailBlur = () => {
    this.setState({
      emailFocusCount: this.state.emailFocusCount + 1
    });
  };

  onEmailChanged = (event: UIEventType) => {
    const email = event.target.value;

    const errors = checkEmail(email);

    this.setState({
      emailToInvite: email,
      errors,
      valid: errors.length === 0
    });
  };

  doInvite = async () => {
    const {onSuccess, site} = this.props;
    const {emailToInvite} = this.state;

    this.setState({
      busy: true
    });

    const result = await siteSvc.grantSiteAccess(site.id, emailToInvite);

    if (result.success) {
      this.setState({
        busy: false,
        emailToInvite: '',
        errors: [],
        valid: true
      });
      onSuccess();
    } else {
      this.setState({
        busy: false,
        errors: [result.message],
        valid: false
      });
    }
  };

  render() {
    const {errors, emailToInvite, emailFocusCount, valid, busy} = this.state;
    const {isOpen, site} = this.props;

    return (
      <TitledModal
        title="Grant Site Access To a Partner"
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.clearAndClose}
        contentClassName="grid-x grid-margin-x"
        className="add-site-partner-modal minor"
      >
        <div className="cell small-12 instructions">
          <p>Enter the email address of an Admin in the partner organization you
             are granting site access to site <strong>{site.name}</strong> and all of its
             systems:
          </p>
        </div>
        <div className="cell small-12 inputs">
          <LabelledRawInput
            label="EMAIL"
            autoFocus
            isInvalid={emailFocusCount >= 1 && !valid}
            className="email-input"
            type="text"
            value={emailToInvite}
            onChange={this.onEmailChanged}
            onBlur={this.onEmailBlur}
            disabled={busy}
            maxLength={50}
          />
        </div>
        <div className="cell small-12 error-box">
          {emailFocusCount >= 1 ?
            errors.map((message, i) => (
              <div key={i} className="text-alert">
                {message}
              </div>
            )) :
            null}
        </div>
        <div className="cell small-12 divider-cell" />
        <div className="cell small-6 medium-4 medium-offset-2">
          <Button
            className="text-uppercase cancel-btn"
            color="black"
            disabled={busy}
            isExpanded
            isHollow
            onClick={this.clearAndClose}
          >
            Cancel
          </Button>
        </div>
        <div className="cell small-6 medium-4">
          <Button
            disabled={!emailToInvite || !valid || busy}
            isExpanded
            isHollow
            className="text-uppercase ok-btn"
            color="black"
            onClick={this.doInvite}
          >
            Done
          </Button>
        </div>
      </TitledModal>
    );
  }
}

// @flow

import React, {Component} from 'react';

import {buildClassName} from '../util/component-util';

import './status-bead.css';

type PropsType = {
  color: string
};

export default class StatusBead extends Component<PropsType> {
  render() {
    const {color} = this.props;

    return (
      <div
        className={buildClassName('status-bead', `status-bead-color-${color}`)}
      />
    );
  }
}

// @flow

import React, {Component} from 'react';
import {Input, dispatchSet, watch} from 'redux-easy';
import {Button} from 'react-foundation';

import TitledModal from '../general-components/titled-modal';
import PhoneInputBase from '../general-components/phone-input-base';
import * as userSvc from './user-service';

import initialState from '../initial-state';

import './sms-phone-modal.css';

import type {UserType, SmsFormType} from '../types';

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onSuccess: Function,
  user: UserType,
  inputs: SmsFormType
};

type MyStateType = {
  busy: boolean,
  readyToVerify: boolean,
  phoneValid: boolean,
  verifyErrors: string[],
  verifyValid: boolean
};

const NON_NUMBERS = /\D/g;

const PHONE_REGEX = /\+1\s\(\d{3}\)\s\d{3}-\d{4}/;

const INITIAL_STATE = {
  busy: false,
  readyToVerify: false,
  phoneValid: false,
  verifyErrors: [],
  verifyValid: false
};

export class SmsPhoneModal extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      ...INITIAL_STATE
    };
  }

  clearAndClose = () => {
    const {onRequestClose} = this.props;
    this.setState({
      ...INITIAL_STATE
    });
    dispatchSet('forms.smsPhone', initialState.forms.smsPhone);
    onRequestClose();
  };

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    const {inputs} = nextProps;

    const phoneValid = PHONE_REGEX.test(inputs.phoneNumber);

    this.setState({
      phoneValid
    });

    if (!phoneValid) {
      this.setState({
        readyToVerify: false
      });
    }

    const newCode = inputs.verificationCode.replace(NON_NUMBERS, '');
    if (newCode !== inputs.verificationCode) {
      dispatchSet('forms.smsPhone.verificationCode', newCode);
    } else {
      this.setState({
        verifyValid: inputs.verificationCode.length === 6
      });
    }
  }

  onReadyToVerify = async () => {
    const {user, inputs} = this.props;
    this.setState({
      busy: true
    });

    const result = await userSvc.setSmsPhone(user.id, inputs.phoneNumber);

    this.setState({
      busy: false,
      readyToVerify: result
    });
  };

  doVerify = async () => {
    const {user, inputs, onSuccess} = this.props;
    this.setState({
      busy: true,
      verifyErrors: []
    });

    const result = await userSvc.verifySmsPhone(
      user.id,
      inputs.verificationCode
    );

    if (!result) {
      this.setState({
        busy: false,
        verifyErrors: ['The provided verification code is not correct.'],
        verifyValid: false
      });
    } else {
      this.setState({
        busy: false,
        verifyErrors: []
      });

      onSuccess();
    }
  };

  render() {
    const {
      busy,
      readyToVerify,
      phoneValid,
      verifyErrors,
      verifyValid
    } = this.state;
    const {isOpen} = this.props;

    const title = readyToVerify ?
      'Validate Cell Phone Number' :
      'Add Cell Phone Number';

    return (
      <TitledModal
        title={title}
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.clearAndClose}
        className="sms-phone-modal minor"
        contentClassName="sms-phone-modal-content"
      >
        <div>
          {!readyToVerify ? (
            <div className="grid-x grid-padding-x">
              <div className="cell small-12 instructions">
                To activate SMS notifications please enter your Cell Phone
                number so it can be validated:
              </div>
              <div className="cell small-12 phone-input-cell">
                <PhoneInputBase
                  className="phone-input"
                  path="forms.smsPhone.phoneNumber"
                  disabled={busy}
                />
              </div>
              <div className="cell small-6 medium-5 medium-offset-1 text-right">
                <Button
                  isHollow
                  className="cancel-btn"
                  disabled={busy}
                  onClick={this.clearAndClose}
                  color="black"
                >
                  Cancel
                </Button>
              </div>
              <div className="cell small-6 medium-5 text-left">
                <Button
                  isHollow
                  className="next-btn"
                  disabled={busy || !phoneValid}
                  onClick={this.onReadyToVerify}
                  color="black"
                >
                  Next
                </Button>
              </div>
              <div className="cell small-12 medium-3" />
            </div>
          ) : (
            <div className="grid-x grid-padding-x">
              <div className="cell small-12 instructions">
                <p>
                  We just sent a code to the phone number provided, please
                  validate your phone number by writing the code sent:
                </p>
              </div>
              <div className="cell small-12 verify-input-cell">
                <Input
                  type="text"
                  className="code-input text-center"
                  path="forms.smsPhone.verificationCode"
                  maxLength={6}
                  disabled={busy}
                />
              </div>
              <div className="cell small-12 error-box">
                {verifyErrors.map((error, i) => (
                  <p key={i} className="text-alert">
                    {error}
                  </p>
                ))}
              </div>
              <div className="cell small-6 medium-4">
                <Button
                  isHollow
                  isExpanded
                  className="cancel-btn-2"
                  disabled={busy}
                  onClick={this.clearAndClose}
                  color="black"
                >
                  Cancel
                </Button>
              </div>
              <div className="cell small-6 medium-4">
                <Button
                  isHollow
                  isExpanded
                  className="new-code-btn"
                  disabled={busy}
                  onClick={this.onReadyToVerify}
                  color="black"
                >
                  Send New Code
                </Button>
              </div>
              <div className="cell small-12 medium-4">
                <Button
                  isHollow
                  isExpanded
                  className="verify-btn"
                  disabled={busy || !verifyValid}
                  onClick={this.doVerify}
                  color="black"
                >
                  Validate
                </Button>
              </div>
            </div>
          )}
        </div>
      </TitledModal>
    );
  }
}

export default watch(SmsPhoneModal, {
  inputs: 'forms.smsPhone',
  user: 'user'
});

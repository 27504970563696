// @flow

import React, {Component} from 'react';

import {Button} from 'react-foundation';

import CollapsibleGridContainer from '../general-components/collapsible-grid-container';
import CollapsibleGrid from '../general-components/collapsible-grid';
import System from '../system/system';

import siteSvc from '../sites/site-service';
import {changeRoute} from '../route';

import './systems-site-group.css';

// eslint-disable-next-line no-duplicate-imports
import type {ColumnDescriptorType} from '../general-components/collapsible-grid';
import type {SystemType} from '../types';

type PropsType = {
  siteName: string,
  siteAddress: string,
  siteId?: ?number,
  systems: SystemType[],
  userIsOrgAdmin: boolean,
  userIsOrgServicer: boolean,
  temperatureDisplayUnit: 'C' | 'F'
};

const COLUMNS: ColumnDescriptorType[] = [
  {
    label: 'Status',
    size: 4,
    className: 'status-header',
    dataClassName: 'system-status'
  },
  {
    label: 'Name',
    size: 3,
    className: 'name-header',
    dataClassName: 'system-name'
  },
  {
    label: 'Temp',
    size: 1,
    className: 'temp-header',
    dataClassName: 'system-temp'
  },
  {
    label: 'Alarms',
    size: 1,
    className: 'alarms-header',
    dataClassName: 'system-alarms'
  },
  {
    label: 'Errors',
    size: 1,
    className: 'errors-header',
    dataClassName: 'system-errors'
  },
  {
    label: '',
    size: 2,
    className: 'system-button-header',
    dataClassName: 'system-button'
  }
];

export default class SystemsSiteGroup extends Component<PropsType> {
  goToSite = async () => {
    const {siteId} = this.props;
    if (siteId) {
      await siteSvc.selectSite(siteId);
      changeRoute('edit-site');
    }
  };

  render() {
    const {
      siteName,
      siteAddress,
      systems,
      siteId,
      userIsOrgAdmin,
      userIsOrgServicer,
      temperatureDisplayUnit
    } = this.props;

    const showGoToSite = siteId && (userIsOrgAdmin || userIsOrgServicer);

    return (
      <CollapsibleGridContainer className="systems-site-group">
        <div className="grid-x">
          <div className="cell medium-10 small-12">
            <div className="site-tag text-uppercase">Site</div>
            <div className="site-name text-uppercase">{siteName}</div>
            <div className="site-address">{siteAddress}</div>
          </div>
          <div className="cell medium-2 medium-offset-0 small-6 small-offset-6">
            {showGoToSite ? (
              <Button
                isHollow
                isExpanded
                onClick={this.goToSite}
                className="go-to-site-button text-uppercase"
                color="little-blue"
              >
                Go To Site
              </Button>
            ) : null}
          </div>
        </div>
        <div className="collapsible-grid-divider" />
        <CollapsibleGrid className="systems-site-group-grid" columns={COLUMNS}>
          {systems.map(system => (
            <System
              key={system.id}
              columns={COLUMNS}
              system={system}
              temperatureDisplayUnit={temperatureDisplayUnit}
            />
          ))}
        </CollapsibleGrid>
      </CollapsibleGridContainer>
    );
  }
}

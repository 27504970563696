// @flow

import {get} from 'lodash';
import React, {Component} from 'react';
import {watch, dispatch} from 'redux-easy';
import {Button} from 'react-foundation';

import LabelledInput from '../labelled-input/labelled-input';
import MarketingBox from '../general-components/marketing-box';
import MarketingBoxLeft from '../general-components/marketing-box-left';
import BuildInfo from '../build-info/build-info';
import {changeRoute} from '../route';
import {fetchJson, userUrl} from '../util/rest-util';
import {checkEmail} from '../user/user-validate';
import {loadUserPreferences} from '../user/user-service';

import './login.css';

import type {UserType} from '../types';

type PropsType = {
  user: UserType
};

type MyStateType = {
  showUserNameRecovery: boolean,
  errors: string[],
  busy: boolean,
  emailInputInvalid: boolean,
  inputsInvalid: boolean,
  emailFocusCount: number
};

export class Login extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      showUserNameRecovery: false,
      inputsInvalid: false,
      emailInputInvalid: false,
      errors: [],
      busy: false,
      emailFocusCount: 0
    };
  }

  onForgotUsername = () => {
    this.setState({
      showUserNameRecovery: true
    });
  };

  validate(userEmail: string) {
    const errors = checkEmail(userEmail);
    this.setState({
      errors,
      emailInputInvalid: errors.length > 0,
      inputsInvalid: false
    });
  }

  onEmailBlur = () => {
    this.setState({
      emailFocusCount: this.state.emailFocusCount + 1
    });
  };

  componentDidMount() {
    const {user} = this.props;
    if (user.authenticated) return this.goToNextRoute();
    this.validate(user.email);
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    const {user} = nextProps;
    if (user.authenticated) return this.goToNextRoute();
    this.validate(user.email);
  }

  onForgotPassword = () => changeRoute('forgot-password');

  onKeyPress = (event: any) => {
    const isEnter = event.key === 'Enter' || event.keyCode === 13;
    if (isEnter) this.onLogin();
  };

  goToNextRoute = () => {
    const userOrg = get(this.props, 'user.organization');
    const nextRoute = userOrg ? 'systems' : 'create-org-maybe';
    return changeRoute(nextRoute);
  };

  onLogin = async () => {
    const {email, password} = this.props.user;
    const data = {email, password};
    const options = {
      method: 'POST',
      body: JSON.stringify(data)
    };

    this.setState({
      inputsInvalid: false,
      emailInputInvalid: false,
      errors: [],
      busy: true
    });

    try {
      const url = userUrl('auth/login');
      const res = await fetchJson(url, options, true);
      const {status} = res;
      if (status === 200) {
        const body = await res.json();
        const {user} = body;
        loadUserPreferences(user.id);
        user.authenticated = true;
        this.setState({
          errors: [],
          busy: false
        });
        dispatch('login', user);
        return this.goToNextRoute();
      } else if (status === 409) {
        this.setState({
          errors: ['Account not confirmed yet.'],
          busy: false
        });
      } else if (status === 423) {
        this.setState({
          errors: [
            'Your account has been locked.  Please contact Customer Support at (800) 537-7775.'
          ],
          busy: false
        });
      } else if (status === 401) {
        const body = await res.json();
        this.setState({
          inputsInvalid: true,
          busy: false,
          errors: [body.message]
        });
      } else {
        this.setState({
          inputsInvalid: true,
          busy: false,
          errors: ['Email Address or Password is incorrect.']
        });
      }
    } catch (e) {
      // istanbul ignore next
      console.error('create-account onCreate: e =', e);
      this.setState({
        busy: false
      });
    }
  };

  render() {
    const {user} = this.props;
    const {
      busy,
      errors,
      showUserNameRecovery,
      inputsInvalid,
      emailInputInvalid,
      emailFocusCount
    } = this.state;

    const emailInvalid =
      emailFocusCount >= 1 && (emailInputInvalid || inputsInvalid);

    const {email, password} = user;
    const loginEnabled = Boolean(
      email && password && !busy && !inputsInvalid && !emailInvalid
    );

    const links = [
      {
        label: 'Create Account',
        route: 'create-account'
      },
      {
        label: 'Log In',
        route: 'login'
      }
    ];

    const labelWidth = 74;

    return (
      <MarketingBox className="login" links={links}>
        <MarketingBoxLeft />
        <div className="marketing-spacer" />
        <div className="marketing-right">
          <h6 className="login-heading">
            <strong>Log in to your account</strong>
          </h6>
          <div className="login-inputs">
            <LabelledInput
              autoFocus
              className="email-input"
              label="EMAIL*"
              type="email"
              path="user.email"
              value={email}
              isInvalid={emailInvalid}
              disabled={busy}
              labelWidth={labelWidth}
              onBlur={this.onEmailBlur}
              maxLength={50}
            />
            <LabelledInput
              className="password-input"
              label="PASSWORD*"
              type="password"
              onKeyPress={this.onKeyPress}
              path="user.password"
              value={password}
              isInvalid={inputsInvalid}
              disabled={busy}
              labelWidth={labelWidth}
              maxLength={100}
            />
          </div>
          <p className="small-link-box">
            <a
              className="forgot-username-link small-link"
              onClick={this.onForgotUsername}
            >
              Not sure what Email Address you used?
            </a>
            <br />
            <a
              className="forgot-password-link small-link"
              onClick={this.onForgotPassword}
            >
              Did you forget your Password?
            </a>
          </p>
          {showUserNameRecovery ? (
            <div className="forgot-username-message">
              To recover your Email Address call Customer Support at:
              <br />
              (800) 537-7775
            </div>
          ) : null}
          <div className="button-box">
            <Button
              className="login-btn text-uppercase"
              isHollow
              disabled={!loginEnabled}
              color="bright-blue"
              onClick={this.onLogin}
            >
              Log In
            </Button>
          </div>
          <div className="error-box">
            {emailFocusCount >= 1 ?
              [...errors].map(msg => (
                <div className="error" key={msg}>
                  {msg}
                </div>
              )) :
              null}
          </div>
        </div>
        <BuildInfo />
      </MarketingBox>
    );
  }
}

export default watch(Login, {
  user: 'user'
});

// @flow

import React, {Component} from 'react';

import TitledModal from '../general-components/titled-modal';
import CheckBox from '../general-components/check-box';
import LabelledInput from '../labelled-input/labelled-input';
import {Button} from 'react-foundation';

import './add-user-modal.css';

import type {InviteUserToOrgFormType} from '../types';

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  emailErrors: string[],
  emailValid: boolean,
  disabled: boolean,
  inviteInputs: InviteUserToOrgFormType,
  onSave: Function
};

export default class AddUserModal extends Component<PropsType> {
  render() {
    const {
      isOpen,
      emailErrors,
      emailValid,
      disabled,
      inviteInputs,
      onRequestClose,
      onSave
    } = this.props;

    return (
      <TitledModal
        title="Invite User"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="add-user-modal minor"
        contentClassName="grid-x grid-padding-x add-user-modal-content"
      >
        <div className="cell small-12 instructions step-1">
          <span className="text-uppercase">
            <strong>1. Send Invitation</strong>
          </span>
          <br />
          Type the email address of the user to invite:
        </div>
        <div className="cell small-12 input-cell">
          <LabelledInput
            autoFocus
            className="email-input"
            label="EMAIL"
            path="forms.inviteUserToOrg.emailToInvite"
            disabled={disabled}
            isInvalid={!emailValid}
            maxLength={50}
          />
        </div>
        <div className="cell small-12 error-box">
          {emailErrors.map((message, i) => (
            <div key={i} className="text-alert">
              {message}
            </div>
          ))}
        </div>
        <div className="cell small-12 instructions step-2">
          <span className="text-uppercase">
            <strong>2. Set a Role</strong>
          </span>
          <br />
          Select the roles for the user being invited:
        </div>
        <div className="cell small-12 divider-cell" />
        <div className="cell medium-4 small-12">
          <CheckBox
            className="text-left-small text-center-medium invite-user-spectator"
            path="forms.inviteUserToOrg.inviteAsSpectator"
            name="spectator-check"
            disabled
            label="Spectator"
            styleName="dark-blue-square"
          />
        </div>
        <div className="cell medium-4 small-12">
          <CheckBox
            className="text-left-small text-center-medium invite-user-servicer"
            path="forms.inviteUserToOrg.inviteAsServicer"
            name="servicer-check"
            disabled={disabled}
            label="Servicer"
            styleName="dark-blue-square"
          />
        </div>
        <div className="cell medium-4 small-12">
          <CheckBox
            className="text-left-small text-center-medium invite-user-admin"
            path="forms.inviteUserToOrg.inviteAsAdmin"
            name="admin-check"
            disabled={disabled}
            label="Admin"
            styleName="dark-blue-square"
          />
        </div>
        <div className="cell small-12 divider-cell" />
        <div className="cell medium-5 medium-offset-1 small-6">
          <Button
            className="text-uppercase cancel-invite-button"
            color="black"
            onClick={onRequestClose}
            disabled={disabled}
            isHollow
            isExpanded
          >
            Cancel
          </Button>
        </div>
        <div className="cell medium-5 small-6">
          <Button
            disabled={
              !inviteInputs.emailToInvite || emailErrors.length > 0 || disabled
            }
            className="text-uppercase send-invite-button"
            color="black"
            onClick={onSave}
            isHollow
            isExpanded
          >
            Send Invitation
          </Button>
        </div>
      </TitledModal>
    );
  }
}

// @flow

import React, {Component} from 'react';

import ProveItModal from '../general-components/prove-it-modal';

import siteSvc from './site-service';

import type {SiteType} from '../types';

type PropsType = {
  isOpen: boolean,
  siteToRemove: SiteType,
  onRequestClose: Function,
  onSuccess: Function
};

type StateType = {
  busy: boolean
};

export default class RemoveSiteModal extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      busy: false
    };
  }

  removeSite = async () => {
    const {onSuccess, siteToRemove} = this.props;
    this.setState({
      busy: true
    });
    await siteSvc.deleteSite(siteToRemove.id);
    this.setState({
      busy: false
    });

    onSuccess();
  };

  render() {
    const {isOpen, onRequestClose, siteToRemove} = this.props;
    const {busy} = this.state;

    const message = (
      <div className="remove-site-message text-center">
        Are you sure you want to remove this site?
        <br />
        <br />
        All the systems assigned to {siteToRemove.name} will be moved to the
        SYSTEMS NOT ASSIGNED TO ANY SITE group on the Dashboard.
        <br />
        <br />
        To complete this action type {'"'}REMOVE{'"'}:
      </div>
    );

    return (
      <ProveItModal
        title="Remove Site"
        message={message}
        valueToMatch="REMOVE"
        onMatchedConfirmed={this.removeSite}
        onCancel={onRequestClose}
        isOpen={isOpen}
        cancelText="Cancel"
        okText="Yes, Remove"
        className="remove-site-modal"
        disabled={busy}
        isUpperCase
      />
    );
  }
}

// @flow

import bowser from 'bowser';

const browserInfo = {
  isMobileSafari: bowser.safari && (bowser.mobile || bowser.tablet),
  isIe: bowser.msie,
  isSafari: bowser.safari
};

export default browserInfo;

// @flow

import React, {Component} from 'react';
import {Button} from 'react-foundation';

import WizardBox from '../general-components/wizard-box';

import {changeRoute} from '../route';
import {getHashParameters} from 'react-hash-route';
import {fetchJson, userUrl} from '../util/rest-util';

import './magic-link.css';

import checkIcon from './images/green-check.svg';
import xIcon from './images/red-x.svg';

type PropsType = {};
type StateType = {
  busy: boolean,
  success: boolean,
  message: ?string,
  nextRoute: string,
  link: string
};

class MagicLink extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      busy: true,
      success: false,
      message: '',
      nextRoute: 'login',
      link: ''
    };
  }

  UNSAFE_componentWillMount() {
    const [magicLink] = getHashParameters();

    if (!magicLink) {
      this.setState({
        busy: false,
        success: false,
        message: 'No Link Found'
      });
    } else {
      this.setState({
        link: magicLink
      });
    }
  }

  goToNextRoute = () => {
    const {nextRoute} = this.state;
    const routeParts = nextRoute ? nextRoute.split('/') : ['login'];
    const base = routeParts.shift();
    changeRoute(base, routeParts);
  };

  async processLink() {
    const {link} = this.state;

    if (link) {
      const options = {
        method: 'POST',
        body: JSON.stringify({value: link})
      };

      const result = await fetchJson(userUrl('magic-link/'), options);

      const {status} = result;
      const resultBody = await result.json();

      const success = status === 200;

      this.setState({
        busy: false,
        success: success && resultBody.successful,
        message: resultBody.message,
        nextRoute: resultBody.nextRoute
      });
    }
  }

  componentDidMount() {
    this.processLink();
  }

  render() {
    const {busy, success, message} = this.state;

    return (
      <WizardBox className="magic-link">
        <h1 className="welcome">
          <strong>
            Welcome to <span className="text-light-blue">intelliGen!</span>
          </strong>
        </h1>
        {busy ? (
          <h4 className="working-div">Please wait while we check on this...</h4>
        ) : success ? (
          <div className="hooray">
            <img className="icon" src={checkIcon} alt="Success Icon" />
            <div className="icon-description">Success!</div>
          </div>
        ) : (
          <div className="boo">
            <img className="icon" src={xIcon} alt="Error Icon" />
            <div className="icon-description">Something Went Wrong</div>
          </div>
        )}
        <div className="message">{message}</div>
        <br />
        {!busy ? (
          <Button
            className="next-route-btn"
            onClick={this.goToNextRoute}
            color="white"
            isHollow
          >
            Next
          </Button>
        ) : null}
      </WizardBox>
    );
  }
}

export default MagicLink;

// @flow

import React, {Component} from 'react';

import MaskedInputEx from './masked-input';

import {buildClassName, cleanProps} from '../util/component-util';

type PropsType = {
  path: string,
  className?: ?string
};

export default class PhoneInputBase extends Component<PropsType> {
  render() {
    const {className, path} = this.props;

    const otherProps = cleanProps(this.props, ['className', 'path']);

    return (
      <MaskedInputEx
        className={buildClassName('phone-input', className)}
        path={path}
        mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholder="+1 (___) ___-____"
        {...otherProps}
      />
    );
  }
}

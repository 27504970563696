// @flow

import React, {Component} from 'react';
import {Block, Breakpoints, Button} from 'react-foundation';
import {watch} from 'redux-easy';

import {buildClassName} from '../util/component-util';
import {changeRoute} from '../route';

import heatcraftLogo from './images/heatcraft-logo.svg';

import './marketing-box.css';

export type MarketingLinkType = {
  label: string,
  route: string
};

type PropsType = {
  className: ?string,
  currentRoute: string,
  links?: MarketingLinkType[]
};

export class MarketingBox extends Component<PropsType> {
  goTo = (route: string) => {
    changeRoute(route);
  };

  buildLinks = (): any[] => {
    const {links, currentRoute} = this.props;
    const myLinks = links ? links : [];

    return myLinks.map(
      (link: MarketingLinkType): any => (
        <Button
          key={link.route}
          className={buildClassName(
            'marketing-box-link-button',
            currentRoute === link.route ? 'current-route' : 'other-route',
            'text-uppercase'
          )}
          isHollow={currentRoute !== link.route}
          disabled={currentRoute === link.route}
          onClick={this.goTo.bind(this, link.route)}
        >
          {link.label}
        </Button>
      )
    );
  };

  render() {
    // ESLint and Flow really don't like me using the 'children'
    // property.
    /* eslint-disable react/prop-types */
    // $FlowFixMe -- props.children is provided by React
    const {children, className} = this.props;

    return (
      <div className="marketing-box">
        <div className="top-cell">
          <Block showFor={Breakpoints.MEDIUM} className="top-btn-container">
            {this.buildLinks()}
          </Block>
        </div>
        <Block className="small-post-top-cell" showOnlyFor={Breakpoints.SMALL}>
          {this.buildLinks()}
        </Block>
        <div className={buildClassName('marketing-box-content', className)}>
          {children}
        </div>
        <Block
          className="small-pre-bottom-cell"
          showOnlyFor={Breakpoints.SMALL}
        >
          <img
            className="small-logo"
            src={heatcraftLogo}
            alt="Heatcraft Logo"
          />
        </Block>
        <div className="bottom-cell">
          <Block className="logo-container" showFor={Breakpoints.MEDIUM}>
            <img src={heatcraftLogo} alt="Heatcraft Logo" />
          </Block>
        </div>
      </div>
    );
  }
}

export default watch(MarketingBox, {
  currentRoute: 'ui.hash'
});


import React, { Component } from 'react';
import { dispatchSet, Input, watch } from 'redux-easy';

import Radio from '../general-components/radio';
import TitledPanel from '../general-components/titled-panel';
import TitledPanelSectionHeader from '../general-components/titled-panel-section-header';
import './user-notification-delay-preferences-panel.css';

const DELAY_MINS_PATH =
  'userPreferences.notifications.email.systemEvent.delayMinutes';

class UserNotificationDelayPreferencePanel extends Component {
  constructor() {
    super();
    this.state = {
      delayMinutesInvalid: false,
      somethingChanged: false
    };
  }

  componentDidMount() {
    const { userPreferences } = this.props;
    this.validateDelayMinutes(userPreferences);
  }

  validateDelayMinutes = (userPreferences) => {
    const currentValue = Number(
      userPreferences.notifications.email.systemEvent.delayMinutes
    );

    if (isNaN(currentValue) || currentValue < 0) {
      dispatchSet(DELAY_MINS_PATH, 0);
    } else if (currentValue > 60) {
      dispatchSet(DELAY_MINS_PATH, 60);
    } else {
      const isValid =
        currentValue === 0 || (currentValue >= 5 && currentValue <= 60);
      this.setState({
        delayMinutesInvalid: !isValid
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userPreferences } = nextProps;

    this.validateDelayMinutes(userPreferences);
  }

  delayRadioChanged = (value) => {
    const delay = value === 'delay' ? 5 : 0;

    dispatchSet(DELAY_MINS_PATH, delay);
    this.internalOnChange();
  };

  somethingChanged = () => {
    this.setState({ somethingChanged: true });
  };

  internalOnChange = () => {
    const { delayMinutesInvalid } = this.state;
    const { onChange } = this.props;

    if (!delayMinutesInvalid) {
      setTimeout(() => {
        onChange();
        this.setState({ somethingChanged: false });
      });
    }
  };

  render() {
    const { disabled, userPreferences } = this.props;
    const { delayMinutesInvalid } = this.state;
    const { notifications } = userPreferences;

    const immediateChecked = !notifications.email.systemEvent.delayMinutes;
    const delayMinutesClass = delayMinutesInvalid ? 'invalid' : '';


    return (
      <TitledPanel
        title="Notification Preferences"
        className="user-notification-delay-preferences-panel"
        contentClassName="grid-x grid-padding-x"
      >
        <div className="cell small-12 delay-details">
          <div className="grid-x">
            <div className="cell large-8 medium-12 small-12 delay-instructions">
              These notification preferences affect dashboard, email and SMS notifications.
            </div>
            <div className="cell small-12">
              <TitledPanelSectionHeader
                title="Delivery Delay"
                className="delay-header"
              />
            </div>
            <div className="cell large-8 medium-12 small-12 instructions">
              Send alarm and error notifications immediately or send after a delay. If the alarm or error clears before the delay expires then the notification is not sent.
            </div>
            <div className="cell large-4 medium-12 small-12" />
            <div className="cell small-12 medium-6 large-4 use-delay-radio-cell">
              <Radio
                label="Send Immediately"
                name="useDelay"
                value="immediate"
                className="use-delay-radio use-delay-immediate"
                checked={immediateChecked}
                disabled={disabled}
                onChange={this.delayRadioChanged}
              />
            </div>
            <div className="cell small-12 medium-6 large-4 use-delay-radio-cell">
              <Radio
                label="Delay before sending"
                name="useDelay"
                value="delay"
                className="use-delay-radio use-delay-immediate"
                checked={!immediateChecked}
                disabled={disabled}
                onChange={this.delayRadioChanged}
              />
            </div>
            <div className="cell small-12 medium-6 large-4">
              <div className="delay-mins-container">
                <Input
                  className={`delay-mins ${delayMinutesClass}`}
                  type="number"
                  path="userPreferences.notifications.email.systemEvent.delayMinutes"
                  disabled={disabled}
                  onChange={this.somethingChanged}
                  onBlur={this.internalOnChange}
                />
                <div className="delay-mins-unit-label">mins</div>
                {delayMinutesInvalid ? (
                  <span className="delay-mins-instructions error-instructions">
                    Please choose a value between 5 and 60
                  </span>
                ) : (
                  <span className="delay-mins-instructions">
                    5 to 60 minutes
                  </span>
                )}
              </div>
            </div>
            <div className="cell large-12 medium-6 small-12" />
          </div>
        </div>
      </TitledPanel>
    );
  }
}

export default watch(UserNotificationDelayPreferencePanel, {
  userPreferences: ''
});

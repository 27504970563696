// @flow

import React, {Component} from 'react';
import {dispatchSet, getPath} from 'redux-easy';
import MaskedInput from 'react-text-mask';

import {buildClassName, cleanProps} from '../util/component-util';

type PropsType = {
  path: string,
  mask: string,
  onChange?: ?Function,
  className?: ?string,
  placeholder: string
};

type UIEventType = {
  target: {
    value: string
  }
};

class MaskedInputEx extends Component<PropsType> {
  handleChange = (event: UIEventType) => {
    const {path, onChange} = this.props;
    const {value} = event.target;
    dispatchSet(path, value);

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {className, mask, path, placeholder} = this.props;
    const value = getPath(path);

    const otherProps = cleanProps(this.props, [
      'className',
      'mask',
      'path',
      'type',
      'onChange',
      'placeholder'
    ]);

    return (
      <MaskedInput
        className={buildClassName('prism-masked-input', className)}
        value={value}
        mask={mask}
        placeholder={placeholder}
        onChange={this.handleChange}
        {...otherProps}
      />
    );
  }
}

export default MaskedInputEx;

// @flow

import React, {Component} from 'react';
import {dispatch} from 'redux-easy';
import {get} from 'lodash';
import {Button, Icon} from 'react-foundation';
import 'foundation-icons/foundation-icons.css';
import CollapsibleGridRow from '../general-components/collapsible-grid-row';
import './manage-system-item.css';

import {changeRoute} from '../route';
import systemSvc from './system-service';

import type {ColumnDescriptorType} from '../general-components/collapsible-grid';
import type {SystemType, UserType} from '../types';

type PropsType = {
  columns: ColumnDescriptorType[],
  system: SystemType,
  user: UserType,
  systemsLength: number,
  index: number,
  onUp: () => void,
  onDown: () => void
};

class ManageSystemItem extends Component<PropsType> {
  onEdit = () => {
    const {system} = this.props;
    dispatch('setSelectedSystem', system);
    changeRoute('system-profile');
  };

  render() {
    const {columns, system, user, systemsLength, index} = this.props;

    const orgInfo = systemSvc.getOrgInfo(system, user.organization, []);

    const ownerName = get(orgInfo, 'owner.name', '');

    const partners = orgInfo.partner;
    let partnersName = '';
    if (Array.isArray(partners) && partners.length > 0) {
      for (let partner of partners) {
        partnersName = partner.name + ';' + partnersName;
      }
    } else {
      partnersName = '<no access>';
    }
    partnersName = partnersName.length > 42 ? partnersName.substring(0,41).concat('...') : partnersName;

    const systemName = get(system, 'name', 'unknown');

    const cells = [
      systemName,
      ownerName,
      partnersName,
      <Button
        key={system.id}
        className="text-uppercase edit-button"
        isExpanded
        isHollow
        color="little-blue"
        onClick={this.onEdit}
      >
        Edit System
      </Button>,
      <span className="reorder-buttons-wrapper">
        <Button
          className="up-button"
          isClear
          disabled={index === 0}
          onClick={this.props.onUp}
        >
          <Icon
            name="fi-arrow-up"
            style={{color: '#197dc2', fontSize: '1rem'}}
          />
        </Button>
        <Button
          className="down-button"
          isClear
          disabled={systemsLength - index === 1}
          onClick={this.props.onDown}
        >
          <Icon
            name="fi-arrow-down"
            style={{color: '#153e78', fontSize: '1rem'}}
          />
        </Button>
      </span>
    ];

    return (
      <CollapsibleGridRow
        className="manage-system-item"
        columns={columns}
        cells={cells}
      />
    );
  }
}

export default ManageSystemItem;

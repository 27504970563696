// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';

import {Button} from 'react-foundation';

import WizardBox from '../general-components/wizard-box';

import {logout} from '../user/user-service';
import {changeRoute} from '../route';

import './create-org-maybe.css';

type PropsType = {
  userLoggedIn: boolean,
  userHasOrg: boolean
};

export class CreateOrgMaybe extends Component<PropsType> {
  componentDidMount() {
    const {userLoggedIn, userHasOrg} = this.props;

    if (!userLoggedIn) {
      changeRoute('login');
    } else if (userHasOrg) {
      changeRoute('created-org');
    }
  }

  goToCreateOrg = () => {
    changeRoute('create-org');
  };

  doLogout = () => {
    logout();
  };

  render() {
    return (
      <WizardBox className="create-org-maybe">
        <div className="grid-container fluid">
          <div className="grid-x align-center">
            <div className="cell small-12">
              <h1>
                <strong>
                  Welcome to{' '}
                  <span className="text-light-blue-alt">intelliGen™</span>
                </strong>
              </h1>
              <div className="info-text">
                It looks like you are not part of a company yet.
                <br />
                Ask to be invited to an existing company or{' '}
                <a className="create-org-link" onClick={this.goToCreateOrg}>
                  create a new company
                </a>
                .
              </div>
              <div className="button-box">
                <Button
                  className="logout-btn"
                  isHollow
                  color="white"
                  onClick={this.doLogout}
                >
                  Log Out
                </Button>
              </div>
            </div>
          </div>
        </div>
      </WizardBox>
    );
  }
}

export default watch(CreateOrgMaybe, {
  userHasOrg: 'sessionInfo.userHasOrg',
  userLoggedIn: 'sessionInfo.userLoggedIn'
});

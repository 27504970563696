// @flow

import {
  route,
  onHashChange,
  getHash,
  getHashParameters
} from 'react-hash-route';
import {dispatchSet} from 'redux-easy';

const defaultRoute = 'login';

function initialize() {
  const startingRoute = getHash();
  const startingParams = getHashParameters();

  changeRoute(startingRoute, startingParams);

  onHashChange(newHash => {
    dispatchSet('ui.hash', newHash);
    if (!newHash) {
      changeRoute('login');
    }
  });
}

function changeRoute(hash: string, params: string[] = []): void {
  const newRoute = hash ? hash : defaultRoute;

  dispatchSet('ui.hash', newRoute);

  const paramStr = params.length > 0 ? `/${params.join('/')}` : '';

  return route(`${newRoute}${paramStr}`);
}

export {changeRoute, initialize};

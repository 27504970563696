export const sortSystems = (systems) => {
    const sortSystems = passedSystems => passedSystems.sort((sysA, sysB) => sysA.sortOrder - sysB.sortOrder);
  
    const sortedSystems = [
      ...sortSystems(
        systems.filter(system => system.sortOrder >= 0)
      ),
      ...systems.filter(system => system.sortOrder <= -1)
    ];
    return sortedSystems;
  }
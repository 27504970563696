// @flow

import React, {Component} from 'react';
import ReactCodeInput from 'react-code-input';
import {dispatch, watch} from 'redux-easy';

import './pin-input.css';

type PropsType = {
  disabled?: boolean,
  invalid?: boolean,
  registrationCode: number[]
};

type MyStateType = {
  width: number
};

const DIGIT_INDEXES = [0, 1, 2, 3, 4, 5];

class PinInput extends Component<PropsType, MyStateType> {
  state = {width: 0};
  constructor() {
    super();
    this.state = {
      width: 0
    };
  }

  updateDimensions = () => {
    this.setState({width: window.innerWidth});
  };
  componentDidMount() {
    this.setState({width: window.innerWidth});
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  setRef = ref => (this.pin = ref);

  clear = () => {
    for (let i = 0; i < 6; i++) {
      this.pin.state.input[i] = '';
      if (this.pin.textInput[i]) {
        this.pin.textInput[i].value = '';
      }
    }
    if (this.pin.textInput[0]) {
      this.pin.textInput[0].focus();
    }
  };

  onChange = value => {
    if (value) {
      const rawDigits = value.split('').map(digit => Number(digit));
      const digits = DIGIT_INDEXES.map(i => rawDigits[i]);
      dispatch('setRegistrationCode', digits);
    } else {
      dispatch('clearRegistrationCode');
    }
  };

  render() {
    const {registrationCode, disabled, invalid} = this.props;
    const inputValue = registrationCode.join('');

    if (!inputValue && this.pin) {
      this.clear();
    }

    const propsMedium = {
      backgroundColor: '#163e78',
      border: 'solid 2px #34bbf4',
      borderRadius: '10px',
      color: 'white',
      display: 'inline-block',
      fontWeight: 300,
      margin: '0 10px',
      padding: '10px',
      textAlign: 'center',
      width: '70px',
      height: '130px',
      fontSize: '72px'
    };

    const propsSmall = {
      backgroundColor: '#163e78',
      border: 'solid 2px #34bbf4',
      borderRadius: '10px',
      color: 'white',
      display: 'inline-block',
      fontWeight: 300,
      margin: '0 5px',
      padding: '5px',
      textAlign: 'center',
      width: '56px',
      height: '87px',
      fontSize: '38px'
    };

    const propsXSmall = {
      backgroundColor: '#163e78',
      border: 'solid 2px #34bbf4',
      borderRadius: '10px',
      color: 'white',
      display: 'inline-block',
      fontWeight: 300,
      margin: '0 3px',
      padding: '5px',
      textAlign: 'center',
      width: '40px',
      height: '68px',
      fontSize: '28px'
    };

    const styleProps = {
      inputStyleInvalid: {
        fontFamily: 'monospace',
        margin: '4px',
        MozAppearance: 'textfield',
        width: '15px',
        borderRadius: '3px',
        fontSize: '14px',
        height: '26px',
        paddingLeft: '7px',
        backgroundColor: 'black',
        color: 'red',
        border: '1px solid red'
      },
      inputStyle:
        this.state.width > 600
          ? propsMedium
          : this.state.width > 425
          ? propsSmall
          : propsXSmall
    };

    return (
      <div className="pin-input grid-x">
        <div className="cell small-12">
          <ReactCodeInput
            {...styleProps}
            autoFocus
            type="number"
            fields={6}
            value={inputValue}
            disabled={disabled}
            onChange={this.onChange}
            inputMode="numeric"
            invalid={invalid}
            ref={this.setRef}
          />
        </div>
      </div>
    );
  }
}

export default watch(PinInput, {
  registrationCode: ''
});

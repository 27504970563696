// @flow

import React, {Component} from 'react';

import {Button} from 'react-foundation';

import CollapsibleGridRow from '../general-components/collapsible-grid-row';

import siteSvc from './site-service';
import {changeRoute} from '../route';

import type {SortedSiteType} from './manage-sites';
import type {ColumnDescriptorType} from '../general-components/collapsible-grid';

type PropsType = {
  columns: ColumnDescriptorType[],
  site: SortedSiteType
};

export default class SortedSiteRow extends Component<PropsType> {
  doEditSite = async () => {
    const {site} = this.props;
    await siteSvc.selectSite(site.id);
    changeRoute('edit-site');
  };

  render() {
    const {columns, site} = this.props;

    const cells = [
      site.name,
      site.systemCount,
      site.address,
      <Button
        key={site.id}
        className="text-uppercase edit-btn"
        isExpanded
        isHollow
        color="little-blue"
        onClick={this.doEditSite}
      >
        Edit Site
      </Button>
    ];

    return (
      <CollapsibleGridRow
        className="sorted-site-row"
        columns={columns}
        cells={cells}
      />
    );
  }
}

// @flow

export type NumericOptionType = {
  value: number | string,
  display: string
};

const TIMEZONE_OPTIONS = [
  {
    value: 'UTC',
    display: 'Universal (UTC)'
  },
  {
    value: 'AST',
    display: 'Atlantic (AST)'
  },
  {
    value: 'EST',
    display: 'Eastern (EST)'
  },
  {
    value: 'CST',
    display: 'Central (CST)'
  },
  {
    value: 'MST',
    display: 'Mountain (MST)'
  },
  {
    value: 'PST',
    display: 'Pacific (PST)'
  },
  {
    value: 'AKST',
    display: 'Alaska (AKST)'
  },
  {
    value: 'HAST',
    display: 'Hawaii (HAST)'
  },
  {
    value: 'SST',
    display: 'Samoan (SST)'
  },
  {
    value: 'CHST',
    display: 'Chamorro (CHST)'
  },
  {
    value: 'WAKT',
    display: 'Wake (WAKT)'
  }
];

const HOUR_OPTIONS: NumericOptionType[] = [
  {
    value: 0,
    display: '12:00 am'
  },
  {
    value: 1,
    display: '1:00 am'
  },
  {
    value: 2,
    display: '2:00 am'
  },
  {
    value: 3,
    display: '3:00 am'
  },
  {
    value: 4,
    display: '4:00 am'
  },
  {
    value: 5,
    display: '5:00 am'
  },
  {
    value: 6,
    display: '6:00 am'
  },
  {
    value: 7,
    display: '7:00 am'
  },
  {
    value: 8,
    display: '8:00 am'
  },
  {
    value: 9,
    display: '9:00 am'
  },
  {
    value: 10,
    display: '10:00 am'
  },
  {
    value: 11,
    display: '11:00 am'
  },
  {
    value: 12,
    display: '12:00 pm'
  },
  {
    value: 13,
    display: '1:00 pm'
  },
  {
    value: 14,
    display: '2:00 pm'
  },
  {
    value: 15,
    display: '3:00 pm'
  },
  {
    value: 16,
    display: '4:00 pm'
  },
  {
    value: 17,
    display: '5:00 pm'
  },
  {
    value: 18,
    display: '6:00 pm'
  },
  {
    value: 19,
    display: '7:00 pm'
  },
  {
    value: 20,
    display: '8:00 pm'
  },
  {
    value: 21,
    display: '9:00 pm'
  },
  {
    value: 22,
    display: '10:00 pm'
  },
  {
    value: 23,
    display: '11:00 pm'
  }
];

const DAY_OPTIONS: NumericOptionType[] = [
  {
    value: 0,
    display: 'Sunday'
  },
  {
    value: 1,
    display: 'Monday'
  },
  {
    value: 2,
    display: 'Tuesday'
  },
  {
    value: 3,
    display: 'Wednesday'
  },
  {
    value: 4,
    display: 'Thursday'
  },
  {
    value: 5,
    display: 'Friday'
  },
  {
    value: 6,
    display: 'Saturday'
  }
];

export {DAY_OPTIONS, HOUR_OPTIONS, TIMEZONE_OPTIONS};

// @flow

import React from 'react';
import {findDOMNode} from 'react-dom';
import {dispatch, Input} from 'redux-easy';

import {buildClassName, cleanProps} from '../util/component-util';

import './labelled-input.css';

import type {ValidationFnType} from '../types';

type PropsType = {
  autoFocus?: boolean,
  className?: string,
  label: string,
  onKeyPress?: Function,
  path: string,
  type?: string,
  validationFn?: ValidationFnType,
  isInvalid?: boolean,
  inputComponent?: any,
  onChange?: Function,
  labelWidth?: number
};

type StateType = {
  valid: boolean
};

class LabelledInput extends React.Component<PropsType, StateType> {
  oldMsgs: string[];

  constructor() {
    super();
    this.oldMsgs = [];
    this.state = {valid: true};
  }

  onChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const {validationFn, onChange} = this.props;
    const {value} = event.target;

    if (validationFn) {
      const newMsgs = validationFn(value);
      dispatch('updateErrors', {oldMsgs: this.oldMsgs, newMsgs});

      this.oldMsgs = newMsgs;
      this.setState({valid: newMsgs.length === 0});
    }

    if (onChange) {
      onChange(event);
    }
  };

  componentDidMount() {
    const {autoFocus} = this.props;

    if (autoFocus) {
      this.doFocus();
    }
  }

  doFocus = event => {
    // findDOMNode returns null in tests.
    // eslint-disable-next-line react/no-find-dom-node
    const root = event ? event.target.parentElement : findDOMNode(this);
    const input = Array.from(root.children).find(
      child => child.localName === 'input'
    );
    if (input) input.focus();
  };

  render = () => {
    const {
      className,
      label,
      type,
      isInvalid,
      inputComponent,
      labelWidth
    } = this.props;
    const {valid} = this.state;

    const useInvalidClass = !valid || isInvalid;

    const inputProps = cleanProps(this.props, [
      'autoFocus',
      'className',
      'type',
      'onChange',
      'isInvalid',
      'validationFn',
      'inputComponent',
      'labelWidth'
    ]);

    const MyInput = inputComponent ? inputComponent : Input;

    const labelStyle = labelWidth ?
      {width: labelWidth, minWidth: labelWidth} :
      {};

    return (
      <div
        className={buildClassName(
          'labelled-input',
          useInvalidClass ? 'invalid' : null
        )}
      >
        <label onClick={this.doFocus} style={labelStyle}>
          {label}
        </label>
        <MyInput
          className={buildClassName(className)}
          onChange={this.onChange}
          type={type || 'text'}
          {...inputProps}
        />
      </div>
    );
  };
}

export default LabelledInput;

// @flow

import React, {Component} from 'react';
import {Button} from 'react-foundation';
import TitledModal from './titled-modal';

import './yes-or-no.css';

type PropsType = {
  title: string,
  message: any,
  onYes: Function,
  onNo: Function,
  isOpen: boolean,
  yesText?: string,
  noText?: string,
  className?: string,
  disabled?: boolean
};

class YesOrNo extends Component<PropsType> {
  render() {
    const {
      title,
      message,
      onYes,
      onNo,
      isOpen,
      yesText,
      noText,
      className,
      disabled
    } = this.props;

    const yesBtnText = yesText || 'OK';
    const noBtnText = noText || 'Cancel';

    const modalClassName = 'yes-or-no' + (className ? ` ${className}` : '');
    return (
      <TitledModal
        title={title}
        isOpen={isOpen}
        closeDisabled
        className={modalClassName}
        contentClassName="grid-x"
      >
        <div className="cell small-12 yes-or-no-message">
          <div>{message}</div>
        </div>
        <div className="cell small-12 yes-or-no-buttons">
          <div className="grid-x grid-padding-x">
            <div className="cell medium-4 medium-offset-2 small-12">
              <Button
                onClick={onNo}
                color="black"
                className="text-uppercase no-btn"
                isExpanded
                isHollow
                disabled={disabled}
              >
                {noBtnText}
              </Button>
            </div>
            <div className="cell medium-4 small-12">
              <Button
                onClick={onYes}
                color="black"
                className="text-uppercase yes-btn"
                isExpanded
                isHollow
                disabled={disabled}
              >
                {yesBtnText}
              </Button>
            </div>
          </div>
        </div>
      </TitledModal>
    );
  }
}

export default YesOrNo;

// @flow

import React, {Component} from 'react';
import {dispatchSet, getPath} from 'redux-easy';

import {buildClassName} from '../util/component-util';

import './select.css';

export type SelectOptionType = {
  value: number | string,
  display: string
};

type PropsType = {
  className?: ?string,
  id?: ?string,
  disabled?: ?boolean,
  path: string,
  valueIsNumber?: ?boolean,
  onChange?: Function,
  withLabelledInputs?: ?boolean,
  options: SelectOptionType[]
};

type UIEventType = {
  target: {
    value: string
  }
};

class Select extends Component<PropsType> {
  handleChange = (event: UIEventType) => {
    const {path, valueIsNumber, onChange} = this.props;
    const value = valueIsNumber ?
      Number(event.target.value) :
      event.target.value;
    dispatchSet(path, value);
    if (onChange) {
      setTimeout(onChange);
    }
  };

  render() {
    const {
      id,
      className,
      disabled,
      path,
      withLabelledInputs,
      options
    } = this.props;
    const value = getPath(path);

    return (
      <select
        id={id}
        className={buildClassName(
          'prism-select',
          className,
          withLabelledInputs ? 'with-labelled' : null
        )}
        disabled={disabled}
        value={value}
        onChange={this.handleChange}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.display}
          </option>
        ))}
      </select>
    );
  }
}

export default Select;

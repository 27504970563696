// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';

import {Button} from 'react-foundation';

import TitledModal from '../general-components/titled-modal';
import Select from '../general-components/select';
import LabelledInput from '../labelled-input/labelled-input';
import LabelledPhoneInput from '../labelled-input/labelled-phone-input';
import {checkEmail, checkPhone} from '../user/user-validate';
import siteSvc from './site-service';

import {
  checkZipCode,
  COUNTRY_OPTIONS,
  getStateLabelForCountry,
  getCityLabelForCountry,
  getZipCodeLabelForCountry
} from '../util/l10n-util';

import './add-site-step2-modal.css';

import type {SiteFormType} from '../types';

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onSuccess: Function,
  onNameError: Function,
  onBackToStepOne: Function,
  newSite: SiteFormType
};

type StateType = {
  busy: boolean,
  emailErrors: string[],
  emailFocusCount: number,
  phoneErrors: string[],
  zipErrors: string[],
  otherErrors: string[]
};

const INITIAL_STATE = {
  busy: false,
  emailErrors: [],
  emailFocusCount: 0,
  phoneErrors: [],
  zipErrors: [],
  otherErrors: []
};

export class AddSiteStep2Modal extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      ...INITIAL_STATE
    };
  }

  static getDerivedStateFromProps(props: PropsType) {
    return {
      emailErrors: checkEmail(props.newSite.email),
      phoneErrors: checkPhone(props.newSite.phone),
      zipErrors: checkZipCode(props.newSite.country, props.newSite.zipcode)
    };
  }

  onEmailBlur = () => {
    this.setState({
      emailFocusCount: this.state.emailFocusCount + 1
    });
  };

  onInternalClose = () => {
    const {onRequestClose} = this.props;
    this.setState({
      ...INITIAL_STATE
    });

    onRequestClose();
  };

  createSite = async () => {
    const {newSite, onSuccess, onNameError} = this.props;

    this.setState({
      busy: true,
      otherErrors: []
    });

    const result = await siteSvc.createSite(newSite);

    this.setState({
      busy: false
    });

    if (result.successful) {
      onSuccess();
    } else if (result.nameErrorMessage) {
      onNameError(result.nameErrorMessage);
    } else if (result.errorMessage) {
      this.setState({
        otherErrors: [result.errorMessage]
      });
    }
  };

  render() {
    const {isOpen, onBackToStepOne, newSite} = this.props;
    const {
      busy,
      emailErrors,
      phoneErrors,
      zipErrors,
      otherErrors,
      emailFocusCount
    } = this.state;

    const stateLabel = getStateLabelForCountry(newSite.country).toUpperCase();
    const cityLabel = getCityLabelForCountry(newSite.country).toUpperCase();
    const zipLabel = getZipCodeLabelForCountry(newSite.country).toUpperCase();

    const buttonDisabled =
      busy ||
      emailErrors.length > 0 ||
      phoneErrors.length > 0 ||
      zipErrors.length > 0;

    const labelWidth = newSite.country === 'CA' ? 125 : 68;

    return (
      <TitledModal
        title="Add New Site"
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.onInternalClose}
        className="large add-site-step-2-modal"
        contentClassName="grid-x grid-padding-x add-site-step-2-modal-content"
      >
        <div className="cell small-12 instructions">
          <div className="text-uppercase">
            <strong>Step 2:</strong>
          </div>
          <div>Please define site information</div>
        </div>

        <div className="cell small-12 divider-cell" />

        <div className="cell small-12 medium-6 large-4">
          <LabelledInput
            label="ADDRESS 1"
            path="forms.addSite.address1"
            disabled={busy}
            autoFocus
            labelWidth={labelWidth}
            maxLength={35}
          />
        </div>

        <div className="cell small-12 medium-6 large-4">
          <LabelledInput
            label="ADDRESS 2"
            path="forms.addSite.address2"
            disabled={busy}
            labelWidth={labelWidth}
            maxLength={35}
          />
        </div>

        <div className="cell small-12 medium-12 large-4" />

        <div className="cell small-12 medium-6 large-4">
          <LabelledInput
            label={cityLabel}
            path="forms.addSite.city"
            disabled={busy}
            labelWidth={labelWidth}
            maxLength={35}
          />
        </div>

        <div className="cell small-12 medium-6 large-4">
          <LabelledInput
            label={stateLabel}
            path="forms.addSite.state"
            disabled={busy}
            labelWidth={labelWidth}
            maxLength={35}
          />
        </div>

        <div className="cell small-12 medium-6 large-4">
          <LabelledInput
            label={zipLabel}
            path="forms.addSite.zipcode"
            disabled={busy}
            labelWidth={labelWidth}
            maxLength={11}
          />
          {zipErrors.map(error => (
            <div key={error} className="error-box">
              <div className="text-alert">{error}</div>
            </div>
          ))}
        </div>

        <div className="cell small-12 medium-6 large-4">
          <Select
            className="country-select"
            path="forms.addSite.country"
            disabled={busy}
            withLabelledInputs
            options={COUNTRY_OPTIONS}
          />
        </div>

        <div className="cell small-12 medium-12 large-8" />

        <div className="cell small-12 medium-6 large-4">
          <LabelledPhoneInput
            label="PHONE"
            path="forms.addSite.phone"
            disabled={busy}
            labelWidth={labelWidth}
            isInvalid={phoneErrors.length > 0}
          />
          {phoneErrors.map(error => (
            <div key={error} className="error-box">
              <div className="text-alert">{error}</div>
            </div>
          ))}
        </div>

        <div className="cell small-12 medium-6 large-4">
          <LabelledInput
            label="EMAIL"
            path="forms.addSite.email"
            disabled={busy}
            labelWidth={labelWidth}
            isInvalid={emailFocusCount >= 1 && emailErrors.length > 0}
            onBlur={this.onEmailBlur}
            maxLength={50}
          />
          {emailFocusCount >= 1 ?
            emailErrors.map(error => (
              <div key={error} className="error-box">
                <div className="text-alert">{error}</div>
              </div>
            )) :
            null}
        </div>

        <div className="cell small-12 medium-6 large-4">
          <LabelledInput
            label="WEBSITE"
            path="forms.addSite.website"
            disabled={busy}
            labelWidth={labelWidth}
            maxLength={100}
          />
        </div>

        <div className="cell small-12 error-box">
          {otherErrors.map((message, i) => (
            <div key={i} className="text-alert">
              {message}
            </div>
          ))}
        </div>

        <div className="cell small-12 buttons">
          <div className="grid-x grid-padding-x">
            <div className="cell small-12 medium-4 large-2 large-offset-3">
              <Button
                isExpanded
                isHollow
                className="text-uppercase"
                onClick={this.onInternalClose}
                disabled={busy}
                color="black"
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-12 medium-4 large-2">
              <Button
                isExpanded
                isHollow
                className="text-uppercase"
                onClick={onBackToStepOne}
                disabled={busy}
                color="black"
              >
                Back
              </Button>
            </div>
            <div className="cell small-12 medium-4 large-2">
              <Button
                isExpanded
                isHollow
                className="text-uppercase"
                onClick={this.createSite}
                disabled={buttonDisabled}
                color="black"
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </TitledModal>
    );
  }
}

export default watch(AddSiteStep2Modal, {
  newSite: 'forms.addSite'
});

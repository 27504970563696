// @flow

import React, {Component} from 'react';

import {buildClassName} from '../util/component-util';

type PropsType = {
  className?: string,
  contentClassName?: string
};

export default class GeneralPageEmptyMessage extends Component<PropsType> {
  render() {
    // ESLint and Flow really don't like me using the 'children'
    // property.
    /* eslint-disable react/prop-types */
    // $FlowFixMe -- props.children is provided by React
    const {children} = this.props;
    const {className, contentClassName} = this.props;

    const myClassName = buildClassName(
      'general-page-empty-message',
      'grid-x',
      className
    );
    const myContentClassName = buildClassName(
      'general-page-empty-message-content',
      'cell',
      'small-12',
      'text-center',
      'text-muted',
      contentClassName
    );

    return (
      <div className={myClassName}>
        <div className={myContentClassName}>{children}</div>
      </div>
    );
  }
}

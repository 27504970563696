// @flow

import React, {Component} from 'react';
import {Callout, TitleBar, TitleBarTitle} from 'react-foundation';

import {buildClassName} from '../util/component-util';

import './titled-panel.css';

type PropsType = {
  title: string,
  titleClassName?: ?string,
  className?: ?string,
  contentClassName?: ?string
};

class TitledPanel extends Component<PropsType> {
  render() {
    // ESLint and Flow really don't like me using the 'children'
    // property.
    /* eslint-disable react/prop-types */
    // $FlowFixMe -- props.children is provided by React
    const {children} = this.props;
    const {title, titleClassName, className, contentClassName} = this.props;

    return (
      <Callout className={buildClassName('titled-panel', className)}>
        <TitleBar>
          <TitleBarTitle
            className={buildClassName(
              'titled-panel-title',
              'text-uppercase',
              titleClassName
            )}
          >
            {title}
          </TitleBarTitle>
        </TitleBar>
        <div
          className={buildClassName('titled-panel-content', contentClassName)}
        >
          {children}
        </div>
      </Callout>
    );
  }
}

export default TitledPanel;

// @flow

import React, {Component} from 'react';

import {buildClassName} from '../util/component-util';

import heatcraftLogo from './images/heatcraft-logo.svg';

import './general-page.css';

type PropsType = {
  title: string,
  pageMessage?: any,
  subText?: any,
  className?: string,
  contentClassName?: string
};

export default class GeneralPage extends Component<PropsType> {
  render() {
    // ESLint and Flow really don't like me using the 'children'
    // property.
    /* eslint-disable react/prop-types */
    // $FlowFixMe -- props.children is provided by React
    const {children} = this.props;
    const {
      title,
      pageMessage,
      subText,
      className,
      contentClassName
    } = this.props;

    const myClassName = buildClassName(
      'general-page-container',
      className,
      'grid-container'
    );
    const myContentClassName = buildClassName(
      'general-page-content',
      contentClassName
    );

    return (
      <div className="general-page">
        <div className={myClassName}>
          <div className="grid-x">
            <div className="cell small-12 general-title-cell">
              <span className="title-text">{title}</span>
            </div>
            <div className="cell small-12 general-message-cell">
              {pageMessage ? (
                <span className="page-message">{pageMessage}</span>
              ) : null}
            </div>
            {subText ? (
              <div className="cell small-12 general-subtext-cell">
                <span className="sub-text text-uppercase">{subText}</span>
              </div>
            ) : null}
          </div>
          <div className={myContentClassName}>{children}</div>
        </div>
        <div className="general-footer">
          <div className="heatcraft-logo-box">
            <img
              className="heatcraft-logo"
              src={heatcraftLogo}
              alt="Heatcraft Logo"
            />
          </div>
          <div className="contact-info">
            Heatcraft Phone Number
            <br />
            (800) 537-7775
          </div>
          <div className="other-info">
            Heatcraft Worldwide Refrigeration to 2018
            <br />
            <a href="https://www.heatcraftrpd.com/legal/" target="_terms">
              Terms & Conditions of Sale
            </a>
            {' | '}
            <a
              href="https://www.heatcraftrpd.com/support/warranty.asp"
              target="_warranty"
            >
              Product Warranty
            </a>
          </div>
        </div>
      </div>
    );
  }
}

// @flow

import React, {Component} from 'react';

import TitledPanel from '../general-components/titled-panel';
import LabelledCell from '../general-components/labelled-cell';
import Select from '../general-components/select';
import LabelledCellInput from '../general-components/labelled-cell-input';
import LabelledCellPhoneInput from '../general-components/labelled-cell-phone-input';

import {checkEmail, checkPhone} from '../user/user-validate';
import siteSvc from './site-service';
import {
  COUNTRY_OPTIONS,
  getStateLabelForCountry,
  getCityLabelForCountry,
  getZipCodeLabelForCountry,
  checkZipCode
} from '../util/l10n-util';

import type {SiteFormType} from '../types';

type PropsType = {
  onChange: Function,
  // ESlint missed that this is used in getDerivedStateFromProps
  // eslint-disable-next-line react/no-unused-prop-types
  editSite: SiteFormType,
  startingName: string,
  disabled: boolean
};

type MyStateType = {
  emailErrors: string[],
  phoneErrors: string[],
  zipErrors: string[],
  nameErrors: string[]
};

export default class EditSiteInfo extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      emailErrors: [],
      phoneErrors: [],
      zipErrors: [],
      nameErrors: []
    };
  }

  static getDerivedStateFromProps(props: PropsType, state: MyStateType) {
    const {editSite} = props;
    return {
      emailErrors: checkEmail(editSite.email),
      phoneErrors: checkPhone(editSite.phone),
      zipErrors: checkZipCode(editSite.country, editSite.zipcode),
      nameErrors: state.nameErrors
    };
  }

  nameChanged = async () => {
    const {startingName, editSite} = this.props;
    const {name} = editSite;

    let nameErrors = [];

    if (startingName !== name) {
      const nameCheck = await siteSvc.checkSiteName(name);
      nameErrors = nameCheck.valid ? [] : [nameCheck.message];
    }

    this.setState({nameErrors});
    if (nameErrors.length === 0) {
      setTimeout(this.somethingChanged);
    }
  };

  somethingChanged = () => {
    const {onChange} = this.props;
    const {emailErrors, phoneErrors, zipErrors, nameErrors} = this.state;

    const errorCount =
      emailErrors.length +
      phoneErrors.length +
      zipErrors.length +
      nameErrors.length;

    if (errorCount === 0) {
      onChange();
    }
  };

  render() {
    const {disabled, editSite} = this.props;
    const {emailErrors, phoneErrors, zipErrors, nameErrors} = this.state;

    const stateLabel = getStateLabelForCountry(editSite.country);
    const cityLabel = getCityLabelForCountry(editSite.country);
    const zipLabel = getZipCodeLabelForCountry(editSite.country);

    return (
      <TitledPanel
        title="Site Information"
        className="edit-site-info-panel"
        contentClassName="grid-x grid-padding-x"
      >
        <LabelledCellInput
          className="cell medium-4 small-12"
          label="Name"
          path="forms.editSite.name"
          disabled={disabled}
          onBlur={this.nameChanged}
          errors={nameErrors}
          maxLength={30}
        />
        <div className="cell medium-8 small-12" />
        <LabelledCellInput
          className="cell medium-4 small-12"
          label="Address 1"
          path="forms.editSite.address1"
          disabled={disabled}
          onBlur={this.somethingChanged}
          maxLength={35}
        />
        <LabelledCellInput
          className="cell medium-4 small-12"
          label="Address 2"
          path="forms.editSite.address2"
          disabled={disabled}
          onBlur={this.somethingChanged}
          maxLength={35}
        />
        <div className="cell medium-4 small-12" />
        <LabelledCellInput
          className="cell medium-4 small-12"
          label={cityLabel}
          path="forms.editSite.city"
          disabled={disabled}
          onBlur={this.somethingChanged}
          maxLength={35}
        />
        <LabelledCellInput
          className="cell medium-4 small-12"
          label={stateLabel}
          path="forms.editSite.state"
          disabled={disabled}
          onBlur={this.somethingChanged}
          maxLength={35}
        />
        <LabelledCellInput
          className="cell medium-4 small-12"
          label={zipLabel}
          path="forms.editSite.zipcode"
          disabled={disabled}
          errors={zipErrors}
          onBlur={this.somethingChanged}
          maxLength={11}
        />
        <LabelledCell label="Country" className="cell medium-4 small-12">
          <Select
            className="country-select"
            path="forms.editSite.country"
            disabled={disabled}
            onChange={this.somethingChanged}
            options={COUNTRY_OPTIONS}
          />
        </LabelledCell>
        <div className="cell medium-8 small-12" />
        <LabelledCellPhoneInput
          className="cell medium-4 small-12"
          label="Phone"
          path="forms.editSite.phone"
          errors={phoneErrors}
          disabled={disabled}
          onBlur={this.somethingChanged}
        />
        <LabelledCellInput
          className="cell medium-4 small-12"
          label="Email"
          path="forms.editSite.email"
          errors={emailErrors}
          disabled={disabled}
          onBlur={this.somethingChanged}
          maxLength={50}
        />
        <LabelledCellInput
          className="cell medium-4 small-12"
          label="Website"
          path="forms.editSite.website"
          disabled={disabled}
          onBlur={this.somethingChanged}
          maxLength={100}
        />
      </TitledPanel>
    );
  }
}

// @flow

import {isEqual} from 'lodash/lang';
import {addReducer} from 'redux-easy';

import {groupSystemsBySite} from './sites/site-util';

import type {
  EditAccountType,
  EditOwnAccountType,
  NotificationType,
  OrgType,
  OrgMemberType,
  StateType,
  SystemType,
  ToastMessageType,
  UserType
} from './types';

import initialState from './initial-state';

type UpdateErrorsPayloadType = {
  oldMsgs: string[],
  newMsgs: string[]
};

addReducer('clearErrors', (state: StateType) => {
  const {errors} = state;
  return errors.size ? {...state, errors: new Set()} : state;
});

addReducer('login', (state: StateType, user: UserType) => {
  if (!user.confirmEmail) user.confirmEmail = user.email;
  user.authenticated = true;

  const userHasOrg = Boolean(user.organization);
  const userIsOrgAdmin =
    user.organization && user.organization.userRoles.includes('Administrator');

  const userIsOrgServicer =
    user.organization && user.organization.userRoles.includes('Servicer');

  const userHasTempOrgRelationship =
    user.organization && user.organization.isTemporary;

  return {
    ...state,
    sessionInfo: {
      userLoggedIn: true,
      userHasOrg,
      userIsOrgAdmin,
      userIsOrgServicer,
      userHasTempOrgRelationship
    },
    user: {
      ...user,
      lastViewedNotifications: new Date(user.lastViewedNotifications)
    }
  };
});

addReducer('logout', () => ({...initialState}));

// This is used by a test.
addReducer('noOp', (state: StateType): StateType => state);

addReducer(
  'setRegistrationCode',
  (state: StateType, value: number[]): StateType => ({
    ...state,
    registrationCode: [...value]
  })
);

addReducer(
  'clearRegistrationCode',
  (state: StateType): StateType => ({
    ...state,
    registrationCode: []
  })
);

addReducer(
  'setSelectedSystem',
  (state: StateType, system: SystemType): StateType => ({
    ...state,
    selectedSystem: system
  })
);

addReducer(
  'setStoreName',
  (state: StateType, storeName: string): StateType => ({...state, storeName})
);

addReducer(
  'setSystems',
  (state: StateType, systems: SystemType[]): StateType => ({
    ...state,
    systems,
    systemsBySite: groupSystemsBySite(systems, state.user)
  })
);

addReducer(
  'updateErrors',
  (state: StateType, {oldMsgs = [], newMsgs}: UpdateErrorsPayloadType) => {
    const {errors} = state;
    const newErrors = new Set([...errors]);
    for (const msg of oldMsgs) newErrors.delete(msg);
    for (const msg of newMsgs) newErrors.add(msg);
    return isEqual(errors, newErrors) ? state : {...state, errors: newErrors};
  }
);

addReducer('clearInviteUserToOrg', (state: StateType) => ({
  ...state,
  forms: {
    ...state.forms,
    inviteUserToOrg: {
      emailToInvite: '',
      inviteAsSpectator: true,
      inviteAsServicer: false,
      inviteAsAdmin: false
    }
  }
}));

addReducer('setCreatedUser', (state: StateType, createdUser: UserType) => ({
  ...state,
  createdUser
}));

addReducer(
  'updateOrgMembers',
  (state: StateType, orgMembers: OrgMemberType[]) => ({
    ...state,
    orgMembers
  })
);

addReducer('clearOrgMembers', (state: StateType) => ({
  ...state,
  orgMembers: []
}));

addReducer('addToast', (state: StateType, toast: ToastMessageType) => ({
  ...state,
  toasts: state.toasts.concat(toast)
}));

addReducer('addErrorToast', (state: StateType, message: string) => ({
  ...state,
  toasts: state.toasts.concat({
    type: 'error',
    title: 'Error!',
    message
  })
}));

addReducer(
  'removeToast',
  (state: StateType, toastToRemove: ToastMessageType) => ({
    ...state,
    toasts: state.toasts.filter(toast => toast !== toastToRemove)
  })
);

addReducer('clearToasts', (state: StateType) => ({
  ...state,
  toasts: []
}));

addReducer(
  'updateNotifications',
  (state: StateType, notifications: NotificationType[]) => {
    let unreadNotifications;
    let notificationsToAdd;

    if (state.user.authenticated) {
      const lastViewedNotifications = new Date(
        state.user.lastViewedNotifications
      );
      unreadNotifications = notifications.filter(
        n => new Date(n.created) > lastViewedNotifications
      ).length;
      notificationsToAdd = notifications;
    } else {
      unreadNotifications = 0;
      notificationsToAdd = [];
    }
    return {
      ...state,
      notifications: notificationsToAdd,
      unreadNotifications
    };
  }
);

addReducer('clearNotifications', (state: StateType) => ({
  ...state,
  notifications: [],
  unreadNotifications: 0
}));

addReducer('populateOrgInputs', (state: StateType, org: OrgType) => ({
  ...state,
  forms: {
    ...state.forms,
    createOrg: {
      ...org
    }
  }
}));

addReducer('clearCreateOrg', (state: StateType) => ({
  ...state,
  forms: {
    ...state.forms,
    createOrg: {
      ...initialState.forms.createOrg
    }
  }
}));

addReducer('clearChangePassword', (state: StateType) => ({
  ...state,
  forms: {
    ...state.forms,
    changePassword: {
      currentPassword: '',
      password: '',
      confirmPassword: ''
    }
  }
}));

addReducer('setOrgAccountToEdit', (state: StateType, user: UserType) => {
  const editInputs: EditAccountType = {
    id: user.id,
    hasOrganization: Boolean(user.organization),
    base: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone
    },
    organization: {
      id: 0,
      isSpectator: false,
      isServicer: false,
      isAdmin: false
    }
  };

  const userOrg = user.organization;

  if (userOrg) {
    editInputs.organization = {
      id: userOrg.id,
      isSpectator: userOrg.userRoles.includes('Spectator'),
      isServicer: userOrg.userRoles.includes('Servicer'),
      isAdmin: userOrg.userRoles.includes('Administrator')
    };
  }

  return {
    ...state,
    forms: {
      ...state.forms,
      editOrgAccount: {
        ...editInputs,
        base: {
          ...editInputs.base
        },
        organization: {
          ...editInputs.organization
        }
      }
    }
  };
});

addReducer('populateEditProfile', (state: StateType, user: UserType) => {
  const editInputs: EditOwnAccountType = {
    id: user.id,
    hasOrganization: Boolean(user.organization),
    base: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone
    },
    notificationPreferences: {},
    organization: {
      id: 0,
      isSpectator: false,
      isServicer: false,
      isAdmin: false
    }
  };

  const userOrg = user.organization;

  if (userOrg) {
    editInputs.organization = {
      id: userOrg.id,
      isSpectator: userOrg.userRoles.includes('Spectator'),
      isServicer: userOrg.userRoles.includes('Servicer'),
      isAdmin: userOrg.userRoles.includes('Administrator')
    };
  }

  return {
    ...state,
    forms: {
      ...state.forms,
      editProfile: {
        ...editInputs,
        base: {
          ...editInputs.base
        },
        organization: {
          ...editInputs.organization
        },
        notificationPreferences: {
          ...editInputs.notificationPreferences
        }
      }
    }
  };
});

// @flow
import {dispatch} from 'redux-easy';
import {fetchJson, userUrl} from '../util/rest-util';

import type {Store} from 'redux';

let currentUserId = 0;
let cancelToken = null;
let store = null;
let errorCount = 0;

const POLL_INTERVAL = 3000;
const GET_OPTIONS = {
  method: 'GET'
};

let unsubscribe = () => {};

async function updateNotifications() {
  const url = userUrl(`users/${currentUserId}/notifications`);
  const result = await fetchJson(url, GET_OPTIONS);

  if (result.status === 200) {
    errorCount = 0;
    const notifications = await result.json();
    dispatch('updateNotifications', notifications);
  } else {
    errorCount++;
    if (errorCount >= 3) {
      dispatch('logout');
      dispatch('addToast', {
        type: 'error',
        title: 'Error',
        message:
          'There seems to be a problem.  Please log back in.  If issues persist, please contact support.'
      });
    }
  }
}

function stopPolling() {
  if (cancelToken) {
    clearInterval(cancelToken);
    cancelToken = null;
  }
  currentUserId = 0;
  errorCount = 0;
}

function startPolling(userId: number) {
  if (!cancelToken) {
    currentUserId = userId;
    updateNotifications();
    cancelToken = setInterval(updateNotifications, POLL_INTERVAL);
  } else {
    stopPolling();
    startPolling(userId);
  }
}

function storeChanged() {
  if (store) {
    const {user} = store.getState();
    const userId = user ? user.id : 0;
    if (userId !== currentUserId) {
      stopPolling();
      if (userId > 0) {
        startPolling(userId);
      }
    }
  }
}

function destroyListener() {
  stopPolling();
  unsubscribe();
  store = null;
}

function setupListener(reduxStore: Store<Object, string>) {
  if (reduxStore !== store) {
    destroyListener();
    store = reduxStore;
    unsubscribe = store.subscribe(storeChanged);
  }
}

export default {
  setupListener,
  updateNotifications,
  startPolling,
  stopPolling
};

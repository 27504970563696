// @flow

import React, {Component} from 'react';

import {Block, Breakpoints} from 'react-foundation';

import CollapsibleGridHeaderLabel from './collapsible-grid-header-label';

import {buildClassName} from '../util/component-util';

import './collapsible-grid-row.css';

import type {ColumnDescriptorType} from './collapsible-grid';

type PropsType = {
  columns: ColumnDescriptorType[],
  cells: any[],
  className?: ?string
};

export default class CollapsibleGridRow extends Component<PropsType> {
  render() {
    const {columns, cells, className} = this.props;

    const myClassName = buildClassName(
      'collapsible-grid-row',
      'grid-x',
      'grid-padding-x',
      className
    );

    return (
      <div className={myClassName}>
        {columns.map((column, i) => (
          <div
            key={i}
            className={buildClassName(
              'collapsible-grid-row-cell',
              'cell',
              'small-12',
              `medium-${column.size}`,
              column.largeSize ? `large-${column.largeSize}` : null,
              'small-cell-block',
              'medium-cell-block',
              'large-cell-block',
              column.dataClassName
            )}
          >
            <Block
              className="collapsible-grid-header text-uppercase"
              showOnlyFor={Breakpoints.SMALL}
            >
              <CollapsibleGridHeaderLabel>
                {column.label}
              </CollapsibleGridHeaderLabel>
            </Block>
            <div className="collapsible-grid-row-data">{cells[i]}</div>
          </div>
        ))}
      </div>
    );
  }
}

// @flow

import React, {Component} from 'react';
import {dispatch, watch} from 'redux-easy';

import Select from '../general-components/select';
import GeneralPage from '../general-components/general-page';
import TitledPanel from '../general-components/titled-panel';
import LabelledCell from '../general-components/labelled-cell';
import LabelledCellInput from '../general-components/labelled-cell-input';
import LabelledCellPhoneInput from '../general-components/labelled-cell-phone-input';

import {changeRoute} from '../route';
import {checkEmail, checkPhone} from '../user/user-validate';
import {
  checkZipCode,
  COUNTRY_OPTIONS,
  getStateLabelForCountry,
  getCityLabelForCountry,
  getZipCodeLabelForCountry
} from '../util/l10n-util';
import * as orgService from './organization-service';
import * as userService from '../user/user-service';

import './organization-profile.css';

import type {NewOrgType, UserType, UserOrgType, OrgStatsType} from '../types';

type PropsType = {
  user: UserType,
  userOrg: UserOrgType,
  inputs: NewOrgType,
  userIsOrgAdmin: boolean,
  userHasOrg: boolean,
  orgStats: OrgStatsType
};

type MyStateType = {
  busy: boolean,
  errors: string[],
  nameInvalid: boolean,
  emailInvalid: boolean,
  phoneInvalid: boolean,
  zipInvalid: boolean,
  canSave: boolean,
  changeMade: boolean
};

class OrgProfile extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      busy: false,
      errors: [],
      nameInvalid: false,
      emailInvalid: false,
      phoneInvalid: false,
      zipInvalid: false,
      canSave: true,
      changeMade: false
    };
  }

  validate(inputs: NewOrgType) {
    let nameInvalid = false;
    let emailInvalid = false;
    let phoneInvalid = false;
    let zipInvalid = false;

    let errors = [];

    if (!inputs.name) {
      nameInvalid = true;
      errors.push('Name is required.');
    }

    if (inputs.zipcode) {
      const zipErrors = checkZipCode(inputs.country, inputs.zipcode);
      zipInvalid = zipErrors.length > 0;
      errors = errors.concat(zipErrors);
    }

    if (inputs.email) {
      const emailErrors = checkEmail(inputs.email);
      emailInvalid = emailErrors.length > 0;
      errors = errors.concat(emailErrors);
    }

    if (inputs.phone) {
      const phoneErrors = checkPhone(inputs.phone);
      phoneInvalid = phoneErrors.length > 0;
      errors = errors.concat(phoneErrors);
    }

    this.setState({
      nameInvalid,
      emailInvalid,
      phoneInvalid,
      zipInvalid,
      canSave: !nameInvalid && !emailInvalid && !phoneInvalid && !zipInvalid,
      errors
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    this.validate(nextProps.inputs);
  }

  async UNSAFE_componentWillMount() {
    const {userHasOrg, userOrg} = this.props;
    this.setState({
      busy: true
    });
    if (!userHasOrg) {
      changeRoute('create-org');
    } else {
      await orgService.refreshOrgStats(userOrg.id);
      const orgData = await orgService.getOrg(userOrg.id);
      dispatch('populateOrgInputs', orgData);
    }

    this.setState({busy: false});
  }

  changeMade = () => {
    this.setState({
      changeMade: true
    });
  };

  doSave = async () => {
    const {canSave, changeMade} = this.state;
    const {inputs, user, userOrg, userIsOrgAdmin} = this.props;
    const orgId = userOrg.id;

    if (changeMade && userIsOrgAdmin && canSave) {
      this.setState({
        busy: true
      });

      try {
        await orgService.updateOrg(orgId, inputs);
        await userService.updateUser(user.id);
      } catch (e) {
        console.error(e);
        dispatch(
          'addErrorToast',
          'Encountered an error while saving company information'
        );
      }

      this.setState({
        busy: false,
        changeMade: false
      });
    }
  };

  render() {
    const {
      errors,
      nameInvalid,
      emailInvalid,
      phoneInvalid,
      zipInvalid
    } = this.state;
    const {userOrg, userIsOrgAdmin, orgStats, inputs} = this.props;
    const inputsDisabled = !userIsOrgAdmin;

    const orgName = userOrg ? userOrg.name : 'unknown';

    const stateLabel = getStateLabelForCountry(inputs.country);
    const cityLabel = getCityLabelForCountry(inputs.country);
    const zipLabel = getZipCodeLabelForCountry(inputs.country);

    return (
      <GeneralPage
        title="Company Profile"
        className="org-profile"
        contentClassName="grid-x grid-padding-x org-profile-content"
      >
        <div className="cell small-12 org-name-header">{orgName}</div>
        <div className="cell small-12 stat-box">
          <div className="org-stat-keys text-uppercase">
            Serviced Systems:
            <br />
            Sites:
            <br />
            Users:
            <br />
          </div>
          <div className="org-stat-vals">
            {orgStats.systemCount}
            <br />
            {orgStats.siteCount}
            <br />
            {orgStats.userCount}
            <br />
          </div>
        </div>
        <div className="cell small-12">
          <TitledPanel title="Company Information" className="org-profile-info">
            <div className="grid-x grid-padding-x">
              <div className="cell large-4 medium-6 small-12">
                <LabelledCellInput
                  label="Company Name*"
                  path="forms.createOrg.name"
                  inputClassName="name-input"
                  disabled={inputsDisabled}
                  invalid={nameInvalid}
                  onBlur={this.doSave}
                  onChange={this.changeMade}
                  maxLength={30}
                />
              </div>
              <div className="cell large-8 medium-6 small-12" />
              <div className="cell large-4 medium-6 small-12">
                <LabelledCellInput
                  label="Address 1"
                  path="forms.createOrg.address1"
                  disabled={inputsDisabled}
                  onBlur={this.doSave}
                  onChange={this.changeMade}
                  maxLength={35}
                />
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCellInput
                  label="Address 2"
                  path="forms.createOrg.address2"
                  disabled={inputsDisabled}
                  onBlur={this.doSave}
                  onChange={this.changeMade}
                  maxLength={35}
                />
              </div>
              <div className="cell large-4 medium-12 small-12" />
              <div className="cell large-4 medium-6 small-12">
                <LabelledCellInput
                  label={cityLabel}
                  path="forms.createOrg.city"
                  disabled={inputsDisabled}
                  onBlur={this.doSave}
                  onChange={this.changeMade}
                  maxLength={35}
                />
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCellInput
                  label={stateLabel}
                  path="forms.createOrg.state"
                  disabled={inputsDisabled}
                  onBlur={this.doSave}
                  onChange={this.changeMade}
                  maxLength={35}
                />
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCellInput
                  label={zipLabel}
                  path="forms.createOrg.zipcode"
                  invalid={zipInvalid}
                  disabled={inputsDisabled}
                  onBlur={this.doSave}
                  onChange={this.changeMade}
                  maxLength={11}
                />
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCell label="Country" className="country-input-cell">
                  <Select
                    className="country-select"
                    path="forms.createOrg.country"
                    disabled={inputsDisabled}
                    onChange={this.doSave}
                    options={COUNTRY_OPTIONS}
                  />
                </LabelledCell>
              </div>
              <div className="cell large-8 medium-12 small-12" />
              <div className="cell large-4 medium-6 small-12">
                <LabelledCellPhoneInput
                  label="Phone"
                  path="forms.createOrg.phone"
                  disabled={inputsDisabled}
                  invalid={phoneInvalid}
                  onBlur={this.doSave}
                  onChange={this.changeMade}
                />
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCellInput
                  label="Email"
                  path="forms.createOrg.email"
                  disabled={inputsDisabled}
                  invalid={emailInvalid}
                  onBlur={this.doSave}
                  onChange={this.changeMade}
                  maxLength={50}
                />
              </div>
              <div className="cell large-4 medium-6 small-12">
                <LabelledCellInput
                  label="Website"
                  path="forms.createOrg.website"
                  disabled={inputsDisabled}
                  onBlur={this.doSave}
                  onChange={this.changeMade}
                  maxLength={100}
                />
              </div>
              <div className="cell large-8 medium-6 small-12" />
              <div className="cell small-12 mandatory-cell">*Mandatory</div>
            </div>
          </TitledPanel>
        </div>
        <div className="cell large-6 large-offset-6 medium-12 small-12 padding-top-1 text-right">
          {errors.map(error => (
            <div className="text-alert" key={error}>
              {error}
            </div>
          ))}
        </div>
      </GeneralPage>
    );
  }
}

export default watch(OrgProfile, {
  inputs: 'forms.createOrg',
  orgStats: 'orgStats',
  user: 'user',
  userHasOrg: 'sessionInfo.userHasOrg',
  userIsOrgAdmin: 'sessionInfo.userIsOrgAdmin',
  userOrg: 'user.organization'
});

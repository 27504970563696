// @flow

import React, {Component} from 'react';

import {joinExistingThings} from '../util/component-util';

import type {SiteType} from '../types';

import './notification-item.css';

type PropsType = {
  site: ?SiteType
};

export default class NotificationItemAddressBlock extends Component<PropsType> {
  render() {
    const {site} = this.props;

    return site ? (
      <div className="notification-item-address-block">
        <span className="name">{site.name}</span>
        <br />
        <span className="address">
          {joinExistingThings([site.address1, site.address2], ' ')}
        </span>
        {site.city || site.state || site.zipcode ? (
          <span className="lastLine">
            <br />
            {joinExistingThings(
              [joinExistingThings([site.city, site.state], ', '), site.zipcode],
              ' '
            )}
          </span>
        ) : (
          <span className="no-site-location">-</span>
        )}
      </div>
    ) : null;
  }
}

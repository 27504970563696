// @flow

import React, {Component} from 'react';
import {dispatch, dispatchSet, watch} from 'redux-easy';
import {Button} from 'react-foundation';

import CheckBox from '../general-components/check-box';
import TitledPanel from '../general-components/titled-panel';
import LabelledCell from '../general-components/labelled-cell';
import * as usrSvc from './user-service';

import SmsPhoneModal from './sms-phone-modal';

import './user-sms-preferences-panel.css';

import type {UserType} from '../types';

type PropsType = {
  disabled: boolean,
  user: UserType,
  smsEnabled: boolean,
  changePhoneSuccess: Function,
  onChange: Function,
  onToggle: Function
};

type MyStateType = {
  changePhoneOpen: boolean
};

class UserSmsPreferencePanel extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      changePhoneOpen: false
    };
  }

  cancelRequest = () => { 
    dispatchSet('forms.smsPhone', {
      phoneNumber: '',
      verificationCode: ''
    });
    this.setState({
      changePhoneOpen: false
    });

    dispatchSet('userPreferences.notifications.sms.enabled', false);
    this.props.onToggle(false);
  };

  toggleSMSPreference = () => {
    const {onChange, user, smsEnabled} = this.props;
    if(!user.smsVerified && smsEnabled) {
      dispatchSet('forms.smsPhone', {
        phoneNumber: '',
        verificationCode: ''
      });
      this.setState({
        changePhoneOpen: true
      });
    } else {
      onChange();
    }
  }

  changeCellPhoneNumber = () => {
    dispatchSet('forms.smsPhone', {
      phoneNumber: '',
      verificationCode: ''
    });
    this.setState({
      changePhoneOpen: true
    });
  };

  internalChangePhoneSuccess = async () => {
    const {user, changePhoneSuccess} = this.props;
    const updatedUser = await usrSvc.getUser(user.id);
    dispatch('login', updatedUser);
    this.setState({
      changePhoneOpen: false
    });
    changePhoneSuccess();
  };

  render() {
    const {disabled, user, smsEnabled} = this.props;
    const {changePhoneOpen} = this.state;
    const {smsVerified} = user;

    return (
      <TitledPanel
        title="SMS Preferences"
        className="user-sms-preferences-panel"
        contentClassName="grid-x grid-padding-x"
      >
        <SmsPhoneModal
          isOpen={changePhoneOpen}
          onRequestClose={this.cancelRequest}
          onSuccess={this.internalChangePhoneSuccess}
        />
        <div className="cell small-12 enable-cell">
          <LabelledCell label="SMS notifications are sent when alarms and errors are raised and cleared.">
            <CheckBox
              path="userPreferences.notifications.sms.enabled"
              name="sms-enabled"
              disabled={disabled}
              label="Enable SMS Notifications"
              styleName="switch"
              onChange={this.toggleSMSPreference}
            />
          </LabelledCell>
        </div>
        {smsEnabled ? (
          <div className="cell small-12 spacer-cell">
            <div className="grid-x grid-padding-x">
              <div className="cell small-12 medium-6 large-4 small-only-text-center">
                {smsVerified ? (
                  <LabelledCell label="Phone">
                    <input
                      type="text"
                      className="sms-number"
                      disabled
                      value={user.smsPhoneNumber}
                    />
                  </LabelledCell>
                ) : null}
              </div>
              <div className="cell small-12 medium-6 large-4 small-only-text-center">
                {smsVerified ? (
                  <LabelledCell label="">
                    <Button
                      isHollow
                      className="text-uppercase add-number-btn"
                      disabled={disabled}
                      onClick={this.changeCellPhoneNumber}
                      color="little-blue"
                    >
                      Change Cell Phone Number
                    </Button>
                  </LabelledCell>
                ) : null}
              </div>
              <div className="cell small-12 medium-4" />
            </div>
          </div>
        ) : null}
      </TitledPanel>
    );
  }
}

export default watch(UserSmsPreferencePanel, {
  smsEnabled: 'userPreferences.notifications.sms.enabled',
  user: ''
});

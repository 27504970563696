// @flow
import './pollyfills';

import React from 'react';
import {routeSetup} from 'react-hash-route';
import {reduxSetup, getStore} from 'redux-easy';

import * as routeUtil from './route';
import notifService from './notification/notification-service';
import tabMngr from './system-detail/system-tab-manager';

import initialState from './initial-state';
import './reducers';
import './util/foundation-test-setup';

import App from './app/app';

import './theme/theme.css';

const render = reduxSetup({component: <App />, initialState});

const store = getStore();
notifService.setupListener(store);

routeSetup(render);

routeUtil.initialize();
tabMngr.initialize();

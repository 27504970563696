// @flow

import {fetchJson, userUrl} from '../util/rest-util';
import {logErrorIfNotTest} from '../util/test-util';

export async function sendConfirmEmail(email: string): Promise<string> {
  const body = {email};
  const options = {
    method: 'POST',
    body: JSON.stringify(body)
  };

  try {
    const res = await fetchJson(userUrl('sendConfirmEmail/'), options);
    const {status} = res;
    return status === 204 ?
      '' :
      `bad status ${status} from sending greeting email`;
  } catch (e) {
    // istanbul ignore next
    logErrorIfNotTest('confirm-email.js sendConfirmEmail:', e.message);
    // istanbul ignore next
    return `error sending greeting email: ${e}`;
  }
}

// @flow

import { fetchJson, userUrl } from '../util/rest-util';
import { dispatch, dispatchSet } from 'redux-easy';

import notificationService from '../notification/notification-service';
import systemService from '../systems/system-service';
import tabMngr from '../system-detail/system-tab-manager';

import { changeRoute } from '../route';

import type {
  UserType,
    UserPreferencesType,
    EditAccountBaseType,
    UpdateLastViewedNotificationsType
} from '../types';

async function getUser(userId: number): Promise<UserType> {
  const result = await fetchJson(userUrl(`users/${userId}`));

  if (result.status === 200) {
    const user = await result.json();
    return user;
  }

  dispatch(
    'addErrorToast',
    'Unable to retrieve user information.  If this issue persists, please contact support'
  );

  return Promise.reject();
}

async function loadUserPreferences(userId: number): Promise<void> {
  const result = await fetchJson(userUrl(`users/${userId}/preferences`));

  if (result.status === 200) {
    const preferences = await result.json();
    dispatchSet('userPreferences', preferences);
  } else {
    dispatch(
      'addErrorToast',
      'Unable to retrieve user preferences.  If this issue persists, please contact support'
    );
  }
}

async function saveUserPreferences(
  user: UserType,
  preferences: UserPreferencesType
): Promise<void> {
  let sysEmailDelayMinutes = Number(
    preferences.notifications.email.systemEvent.delayMinutes
  );
  if (!sysEmailDelayMinutes) {
    sysEmailDelayMinutes = 0;
  } else if (sysEmailDelayMinutes < 5 && sysEmailDelayMinutes !== 0) {
    sysEmailDelayMinutes = 5;
  } else if (sysEmailDelayMinutes > 60) {
    sysEmailDelayMinutes = 60;
  }

  const prefsToSave = {
    ...preferences,
    notifications: {
      ...preferences.notifications,
      email: {
        ...preferences.notifications.email,
        systemEvent: {
          ...preferences.notifications.email.systemEvent,
          // System events appear to always be linked to the general enabled setting in the UI.
          enabled: preferences.notifications.email.enabled,
          delayMinutes: sysEmailDelayMinutes
        }
      },
      sms: {
        enabled: preferences.notifications.sms.enabled,
        systemEvent: {
          ...preferences.notifications.sms.systemEvent,
          // System events appear to always be linked to the general enabled setting in the UI.
          enabled: preferences.notifications.sms.enabled,
          // Delay is tied to email
          delayMinutes: sysEmailDelayMinutes
        }
      },
      application: {
        enabled: preferences.notifications.application.enabled,
        systemEvent: {
          ...preferences.notifications.application.systemEvent,
          // System events appear to always be linked to the general enabled setting in the UI.
          enabled: preferences.notifications.application.enabled,
          // Delay is tied to email
          delayMinutes: sysEmailDelayMinutes
        }
      }
    }
  };

  const options = {
    method: 'PUT',
    body: JSON.stringify(prefsToSave)
  };

  const result = await fetchJson(
    userUrl(`users/${user.id}/preferences`),
    options
  );

  if (result.status !== 200) {
    dispatch('addErrorToast', 'Encountered an error while saving preferences');
  }
}

async function setSmsPhone(userId: number, phone: string): Promise<boolean> {
  const url = userUrl(`users/${userId}/setSmsPhoneNumber`);
  const options = {
    method: 'POST',
    body: JSON.stringify({
      smsPhoneNumber: phone
    })
  };

  const result = await fetchJson(url, options);

  if (result.status !== 200) {
    dispatch(
      'addErrorToast',
      'Encountered an error while saving SMS Phone Number'
    );
  }

  return result.status === 200;
}

async function verifySmsPhone(
  userId: number,
  verificationCode: string
): Promise<boolean> {
  const url = userUrl(`users/${userId}/confirmSmsPhoneNumber`);
  const options = {
    method: 'POST',
    body: JSON.stringify({
      verificationCode
    })
  };

  const result = await fetchJson(url, options);

  return result.status === 200;
}

async function updateUser(userId: number): Promise<UserType> {
  const user = await getUser(userId);
  dispatch('login', user);
  dispatch('populateEditProfile', user);
  await loadUserPreferences(userId);
  return user;
}

async function loadOrgUserToEdit(userId: number): Promise<UserType> {
  const user = await getUser(userId);
  dispatch('setOrgAccountToEdit', user);
  return user;
}

async function saveUserEmail(userId: number, newEmail: string): Promise<void> {
  const url = userUrl(`users/${userId}/changeEmail`);
  const options = {
    method: 'POST',
    body: JSON.stringify({ newEmail })
  };

  const result = await fetchJson(url, options);

  const genericMessage =
    'Unable to update user email.  If this issue persists, please contact support';

  if (result.status === 409) {
    const body = await result.json();
    const message = body && body.message ? body.message : genericMessage;
    dispatch('addErrorToast', message);
    return Promise.reject();
  } else if (result.status !== 204) {
    dispatch('addErrorToast', genericMessage);
    return Promise.reject();
  }
}

async function updateUserProfileInfo(
  userId: number,
  updatedInfo: EditAccountBaseType | UpdateLastViewedNotificationsType
): Promise<void> {
  const url = userUrl(`users/${userId}`);
  const options = {
    method: 'PATCH',
    body: JSON.stringify(updatedInfo)
  };

  const result = await fetchJson(url, options);

  if (result.status !== 200) {
    dispatch(
      'addErrorToast',
      'Unable to update user information.  If this issue persists, please contact support'
    );
    return Promise.reject();
  }
}

export type ChangePwResultType = {
  successful: boolean,
  message: string
};

async function changeUserPassword(
  userId: number,
  currentPassword: string,
  newPassword: string
): Promise<ChangePwResultType> {
  const url = userUrl(`users/${userId}/changePassword`);
  const options = {
    method: 'POST',
    body: JSON.stringify({ currentPassword, newPassword })
  };

  const result = await fetchJson(url, options, true);

  if (result.status === 204) {
    return {
      successful: true,
      message: ''
    };
  } else if (result.status === 403) {
    return {
      successful: false,
      message: 'Current password is incorrect.  Please try again.'
    };
  }

  const body = await result.json();
  const message = body.message || 'Unexpected error occurred.';

  return {
    successful: false,
    message
  };
}

async function logout(): Promise<void> {
  await logoutWithError(null);
}

async function logoutWithError(error?: ?string): Promise<void> {
  notificationService.stopPolling();
  systemService.stopPolling();
  tabMngr.closeAll();

  const url = userUrl('auth/logout');
  changeRoute('login');
  dispatch('logout');
  if (error) {
    dispatch('addErrorToast', error);
  }
  await fetchJson(url, { method: 'POST' }, true);
}

const deleteUser = async (id) => {
  try {
    const url = userUrl(`users/${id}`);
    const result = await fetchJson(url, { method: 'DELETE' }, true);
    if (result.status === 409) {
      const json = await result.json();
      dispatch('addErrorToast', json.message);
    } else {
      dispatch('logout');
    } 
  } catch (e) {
    console.error(e);
  }
}

export {
  getUser,
  updateUser,
  deleteUser,
  loadOrgUserToEdit,
  saveUserEmail,
  updateUserProfileInfo,
  changeUserPassword,
  loadUserPreferences,
  saveUserPreferences,
  setSmsPhone,
  verifySmsPhone,
  logout,
  logoutWithError
};

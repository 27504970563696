// @flow

import {sortBy} from 'lodash';

import type {SiteSystemsType, SystemType, UserType} from '../types';
import {SYSTEM_FILTERS} from '../systems/systems';

export function groupSystemsBySite(
  input: SystemType[],
  user: UserType
): SiteSystemsType {
  const siteMap = {};
  const siteSystemMap = {};
  const noSiteSystems = [];
  const {owner: isOwner, access: isPartner} = SYSTEM_FILTERS;

  input.forEach(system => {
    const isUserSystemOwner = isOwner(system, user);
    const isUserSystemPartner = isPartner(system, user);

    if (isUserSystemOwner && system.organizations.length > 0) {
      const userSystemOrg = system.organizations.find(
        org => org.type === 'Owner'
      );
      system.sortOrder = userSystemOrg.sortOrder;
      system.sortOrgId = userSystemOrg.id;
    } else if (isUserSystemPartner && system.organizations.length > 0) {
      const userSystemOrg = system.organizations.find(
        org => (org.type === 'Partner' && org.id === user.organization.id)
      );
      system.sortOrder = userSystemOrg.sortOrder;
      system.sortOrgId = userSystemOrg.id;
    }

    if (!system.site) {
      noSiteSystems.push(system);
    } else {
      const {site} = system;
      if (siteSystemMap[site.id]) {
        siteSystemMap[site.id].push(system);
      } else {
        siteMap[site.id] = site;
        siteSystemMap[site.id] = [system];
      }
    }
  });

  const siteSystems = Object.keys(siteMap).map(siteId => ({
    site: siteMap[siteId],
    systems: siteSystemMap[siteId]
  }));

  return {
    siteSystems: sortBy(siteSystems, 'site.name'),
    noSiteSystems: sortBy(noSiteSystems, 'name')
  };
}

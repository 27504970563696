// @flow

import React, {Component} from 'react';
import {dispatch, watch} from 'redux-easy';
import {Button} from 'react-foundation';

import GeneralPage from '../general-components/general-page';
import UserList from './user-list';
import OrgInvitationPanel from './org-invitation-panel';
import AddUserModal from './add-user-modal';

import {changeRoute} from '../route';
import {checkEmail} from '../user/user-validate';
import * as orgService from '../organization/organization-service';

import './user-mgmt.css';

import type {
  InviteUserToOrgFormType,
  UserOrgType,
  OrgMemberType,
  OrgInvitationType
} from '../types';

type PropsType = {
  organization: UserOrgType,
  userIsOrgAdmin: boolean,
  inviteInputs: InviteUserToOrgFormType,
  orgMembers: OrgMemberType[],
  orgInvitations: OrgInvitationType[]
};

type MyStateType = {
  inviteBusy: boolean,
  inviteUserModalOpen: boolean,
  inviteValidationMessages: string[]
};

class UserMgmt extends Component<PropsType, MyStateType> {
  state: MyStateType = {
    inviteBusy: false,
    inviteUserModalOpen: false,
    inviteValidationMessages: []
  };

  toggleInviteUserModal = () => {
    dispatch('clearInviteUserToOrg');
    this.setState({
      inviteUserModalOpen: !this.state.inviteUserModalOpen
    });
  };

  refreshData = () => {
    const {id: orgId} = this.props.organization;
    orgService.refreshOrgMemberList(orgId);
    orgService.refreshOrgInviteList(orgId);
  };

  UNSAFE_componentWillMount() {
    const {userIsOrgAdmin, inviteInputs} = this.props;

    if (!userIsOrgAdmin) {
      changeRoute('systems');
    } else {
      this.refreshData();
      this.setState({
        inviteValidationMessages: checkEmail(inviteInputs.emailToInvite)
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {inviteInputs} = nextProps;
    this.setState({
      inviteValidationMessages: checkEmail(inviteInputs.emailToInvite)
    });
  }

  inviteUser = async () => {
    const {id: myOrgId} = this.props.organization;
    const {
      emailToInvite: email,
      inviteAsSpectator,
      inviteAsServicer,
      inviteAsAdmin
    } = this.props.inviteInputs;

    this.setState({
      inviteBusy: true
    });

    const result = await orgService.inviteUserToOrg(
      myOrgId,
      email,
      inviteAsSpectator,
      inviteAsServicer,
      inviteAsAdmin
    );

    if (result.success) {
      dispatch('addToast', {
        type: 'success',
        title: 'Success!',
        message: `Sent invitation to: ${email}`
      });
      this.setState({
        inviteBusy: false,
        inviteUserModalOpen: false
      });
      dispatch('clearInviteUserToOrg');
      this.refreshData();
    } else if (result.message) {
      this.setState({
        inviteBusy: false,
        inviteUserModalOpen: true,
        inviteValidationMessages: [result.message]
      });
    } else {
      dispatch('addToast', {
        type: 'error',
        title: 'Error!',
        message: `Unable to invite ${email}`
      });
      this.setState({
        inviteBusy: false,
        inviteUserModalOpen: false
      });
      dispatch('clearInviteUserToOrg');
      this.refreshData();
    }
  };

  render() {
    const {inviteInputs, orgMembers, orgInvitations} = this.props;
    const {
      inviteBusy,
      inviteUserModalOpen,
      inviteValidationMessages
    } = this.state;

    const memberCount = orgMembers.length;
    const s = memberCount === 1 ? '' : 's';

    return (
      <GeneralPage
        title="Manage Users"
        subText={`Total of ${memberCount} member${s}`}
        className="user-mgmt"
        contentClassName="user-mgmt-content grid-x"
      >
        <AddUserModal
          isOpen={inviteUserModalOpen}
          onRequestClose={this.toggleInviteUserModal}
          emailErrors={inviteValidationMessages}
          emailValid={inviteValidationMessages.length === 0}
          disabled={inviteBusy}
          inviteInputs={inviteInputs}
          onSave={this.inviteUser}
        />
        <div className="cell small-12 button-grid">
          <div className="grid-x grid-padding-x">
            <div className="cell small-12 medium-3 medium-offset-9 large-2 large-offset-10">
              <Button
                className="add-user-button text-uppercase"
                isHollow
                isExpanded
                onClick={this.toggleInviteUserModal}
              >
                Invite User
              </Button>
            </div>
          </div>
        </div>
        {orgInvitations.length > 0 ? (
          <OrgInvitationPanel
            orgInvitations={orgInvitations}
            onInformationChanged={this.refreshData}
          />
        ) : null}
        <UserList />
      </GeneralPage>
    );
  }
}

export default watch(UserMgmt, {
  inviteInputs: 'forms.inviteUserToOrg',
  organization: 'user.organization',
  orgInvitations: '',
  orgMembers: '',
  userIsOrgAdmin: 'sessionInfo.userIsOrgAdmin'
});

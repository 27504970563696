// @flow

import React, { Component } from 'react';
import { Button } from 'react-foundation';
import { dispatch } from 'redux-easy';

import TitledPanel from '../general-components/titled-panel';
import YesOrNo from '../general-components/yes-or-no';

import sysSvc from './system-service';
import { changeRoute } from '../route';
import { RotatingLines } from "react-loader-spinner";
import TitledModal from '../general-components/titled-modal';
import './system-profile-unregister-panel.css';

class SystemProfileUnregisterPanel extends Component {
  constructor() {
    super();
    this.state = {
      unregisterOpen: false,
      unregisterBusy: false
    };
  }

  openUnregisterModal = () => {
    this.setState({
      unregisterOpen: true
    });
  };

  toggleUnregisterModal = () => {
    this.setState({
      unregisterOpen: !this.state.unregisterOpen
    });
  };

  doUnregisterSystem = async () => {
    const { system } = this.props;
    this.setState({
      unregisterBusy: true,
      unregisterOpen: false
    });
    const result = await sysSvc.unregisterSystem(system.id);

    if (!result.success) {
      const message = result.message ?
        result.message :
        'Unable to unregister the system.';
      dispatch('addErrorToast', message);
    } else {
      dispatch('addToast', {
        type: 'success',
        title: 'Success!',
        message: 'Unregistration Successful!'
      });

    }
    this.setState({
      unregisterBusy: false
    });

    changeRoute('systems');
  };

  render() {
    const {
      system,
    } = this.props;
    const {
      unregisterOpen,
      unregisterBusy,
    } = this.state;

    const title = 'REGISTRATION';
    const unregisterSystemDisabled = false;
    const unregisterConfirmationMessage = (
      <div> This action will unregister the system <strong>{system.name}</strong>.
        <div class="unregister-detailed-list-section">
          Unregistering this system have the following effects:
          <ul class="unregister-detailed-list">
            <li>the system is reset to the <strong>UNREGISTERED</strong> state</li>
            <li>the system <strong>Owner organization</strong> is removed</li>
            <li>all system <strong>Access Partner organizations</strong> are removed</li>
            <li>the system is not accessible from the remote portal Dashboard</li>
            <li>the system is removed from its <strong>Site</strong> (if applicable)</li>
            <li>the system is removed from its <strong>Multi-System Group</strong> (if applicable)</li>
            <li>the system is removed from its <strong>MSG Section</strong> (if applicable)</li>
            <li>the system is removed from its <strong>MSG Lead Lag group</strong> (if applicable)</li>
          </ul>
        </div>
        Are you sure you want to unregister the system <strong>{system.name}</strong>?
      </div>
    );

    const loadingIndicator = (<div>
      <div className="text-center"> Unregistering system <strong>{system.name}</strong>.</div>
      <div className="half-circle-spinner">
        <RotatingLines
          strokeColor="#197dc2"
          strokeWidth="3"
          animationDuration="1"
          width="86"
          visible={true}
        />
      </div>
      <div className="text-center">Please wait.</div>
    </div>);

    return (
      <TitledPanel title={title} className="system-profile-unregister-panel">
        <YesOrNo
          title="Unregister System"
          message={unregisterConfirmationMessage}
          onYes={this.doUnregisterSystem}
          onNo={this.toggleUnregisterModal}
          yesText="YES"
          disabled={unregisterBusy}
          isOpen={unregisterOpen}
          className="system-profile-unregister-panel minor"
        />
        <TitledModal
          title="Unregister System"
          isOpen={unregisterBusy}
          closeDisabled
          className="system-profile-unregister-panel minor"
        >
          {loadingIndicator}
        </TitledModal>
        <div className="grid-x grid-padding-x">
          <div className="cell small-12 medium-4">
            <Button
              isHollow
              isExpanded
              color="little-blue"
              className="text-uppercase"
              onClick={this.openUnregisterModal}
              disabled={unregisterSystemDisabled}
            >
              Unregister System
            </Button>
          </div>
        </div>
      </TitledPanel>
    );
  }
}

export default SystemProfileUnregisterPanel;

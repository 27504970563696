// @flow

import React, {Component} from 'react';

import {cleanProps} from '../util/component-util';

export default class BaseInput extends Component<Object> {
  render() {
    const cleanedProps = cleanProps(this.props, []);

    return <input {...cleanedProps} />;
  }
}

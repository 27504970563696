// @flow

import React, {Component} from 'react';
import {Button} from 'react-foundation';
import {dispatch, watch} from 'redux-easy';

import GeneralPage from '../general-components/general-page';
import ProveItModal from '../general-components/prove-it-modal';
import {checkEmail, checkPhone} from '../user/user-validate';
import UserInfoPanel from '../user/user-info-panel';
import UserRolePanel from '../user/user-role-panel';
import * as orgService from './organization-service';
import * as userService from '../user/user-service';

import {changeRoute} from '../route';

import './edit-member.css';

import type {EditAccountType, OrgType} from '../types';

type PropsType = {
  organization: OrgType,
  currentData: EditAccountType
};

type MyStateType = {
  busy: boolean,
  isValid: boolean,
  emailErrors: string[],
  firstNameErrors: string[],
  lastNameErrors: string[],
  phoneErrors: string[],
  removeModalOpen: boolean
};

export class EditMember extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      busy: false,
      isValid: true,
      emailErrors: [],
      firstNameErrors: [],
      lastNameErrors: [],
      phoneErrors: [],
      removeModalOpen: false
    };
  }

  onToggleRemoveModal = () => {
    this.setState({
      removeModalOpen: !this.state.removeModalOpen
    });
  };

  onRemoveFromOrg = async () => {
    const {currentData} = this.props;
    const name = `${currentData.base.firstName} ${currentData.base.lastName}`;
    const memberId = currentData.id;
    const orgId = currentData.organization.id;

    this.setState({
      busy: true
    });

    try {
      await orgService.removeUserFromOrg(orgId, memberId);
      dispatch('addToast', {
        type: 'success',
        title: 'Success!',
        message: `${name} was removed from your Company`
      });
    } catch (err) {
      console.error(err);
    }

    changeRoute('manage-users');

    this.setState({
      busy: false
    });
  };

  validate = (current: EditAccountType) => {
    const newBase = current.base;

    const emailErrors = checkEmail(newBase.email);
    if (!newBase.email) {
      emailErrors.push('Email is required');
    }

    const firstNameErrors = !newBase.firstName ?
      ['First name is required'] :
      [];
    const lastNameErrors = !newBase.lastName ? ['Last name is required'] : [];

    const phoneErrors = !newBase.phone ?
      ['Phone is required'] :
      checkPhone(newBase.phone);

    const isValid =
      emailErrors.length === 0 &&
      firstNameErrors.length === 0 &&
      lastNameErrors.length === 0 &&
      phoneErrors.length === 0;

    this.setState({
      emailErrors,
      firstNameErrors,
      lastNameErrors,
      phoneErrors,
      isValid
    });
  };

  componentDidMount() {
    const {currentData} = this.props;
    this.validate(currentData);
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropsType) {
    const {currentData} = nextProps;
    this.validate(currentData);
  }

  saveEmail = async () => {
    const {currentData} = this.props;
    await userService.saveUserEmail(currentData.id, currentData.base.email);
    await userService.loadOrgUserToEdit(currentData.id);
  };

  saveRoles = async () => {
    const {currentData} = this.props;
    const orgData = currentData.organization;
    const roles = [];
    if (orgData.isSpectator) {
      roles.push('Spectator');
    }
    if (orgData.isServicer) {
      roles.push('Servicer');
    }
    if (orgData.isAdmin) {
      roles.push('Administrator');
    }

    await orgService.updateUserRoles(orgData.id, currentData.id, roles);
    await userService.loadOrgUserToEdit(currentData.id);
  };

  saveBaseInformation = async () => {
    const {currentData} = this.props;
    const {isValid} = this.state;
    if (isValid) {
      await userService.updateUserProfileInfo(currentData.id, currentData.base);

      await userService.loadOrgUserToEdit(currentData.id);
    }
  };

  render() {
    const {organization, currentData} = this.props;
    const {base} = currentData;
    const baseOrg = currentData.organization;
    const roles = [];

    const name = `${base.firstName} ${base.lastName}`;

    const orgName = organization ? organization.name : '';

    if (baseOrg.isSpectator) {
      roles.push('Spectator');
    }

    if (baseOrg.isServicer) {
      roles.push('Servicer');
    }

    if (baseOrg.isAdmin) {
      roles.push('Administrator');
    }

    const {
      busy,
      emailErrors,
      firstNameErrors,
      lastNameErrors,
      phoneErrors,
      removeModalOpen
    } = this.state;

    const removeUserMessage = (
      <span>
        Are you sure you want to remove {name} from your Company?
        <br />
        <br />
        To continue, enter the word REMOVE:
      </span>
    );

    return (
      <GeneralPage
        title={name}
        className="edit-member"
        contentClassName="edit-member-content grid-x"
      >
        <ProveItModal
          title="Remove User"
          valueToMatch="REMOVE"
          message={removeUserMessage}
          onMatchedConfirmed={this.onRemoveFromOrg}
          onCancel={this.onToggleRemoveModal}
          isOpen={removeModalOpen}
          cancelText="Cancel"
          okText="Yes, Remove"
          className="remove-user-confirm-modal"
          isUpperCase
        />
        <div className="cell small-12 org-name-header">{orgName}</div>
        <div className="cell small-12 org-roles-header text-uppercase">
          Roles: {roles.join(' + ')}
        </div>
        <div className="cell small-12 button-grid">
          <div className="grid-x grid-padding-x">
            <div className="cell small-12 medium-4 medium-offset-8 large-2 large-offset-10">
              <Button
                className="remove-btn text-uppercase"
                onClick={this.onToggleRemoveModal}
                isHollow
                isExpanded
                disabled={busy}
              >
                Remove User
              </Button>
            </div>
          </div>
        </div>
        <div className="cell small-12 profile-panel">
          <UserInfoPanel
            disabled={busy}
            basePath="forms.editOrgAccount.base"
            emailErrors={emailErrors}
            firstNameErrors={firstNameErrors}
            lastNameErrors={lastNameErrors}
            phoneErrors={phoneErrors}
            onChange={this.saveBaseInformation}
            onEmailChange={this.saveEmail}
          />
        </div>
        <div className="cell small-12 profile-panel">
          <UserRolePanel
            disabled={busy}
            basePath="forms.editOrgAccount.organization"
            onChange={this.saveRoles}
          />
        </div>
      </GeneralPage>
    );
  }
}

export default watch(EditMember, {
  currentData: 'forms.editOrgAccount',
  organization: 'user.organization'
});

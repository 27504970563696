// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';

import {Button} from 'react-foundation';

import GeneralPage from '../general-components/general-page';
import GeneralPageEmptyMessage from '../general-components/general-page-empty-message';
import SortedSiteGroup from './sorted-site-group';
import AddSiteModel from './add-site-modal';

import siteService from './site-service';
import {buildCondensedAddress} from '../util/component-util';

import type {SiteType} from '../types';

import './manage-sites.css';

export type SortedSiteType = {
  id: number,
  name: string,
  systemCount: number,
  address: string
};

export type SortedSiteGroupType = {
  locationLabel: string,
  sites: SortedSiteType[]
};

type PropsType = {
  sites: SiteType[]
};

type MyStateType = {
  sortedSites: SortedSiteGroupType[],
  addSiteOpen: boolean
};

export class ManageSites extends Component<PropsType, MyStateType> {
  constructor() {
    super();

    this.state = {
      sortedSites: [],
      addSiteOpen: false
    };
  }

  static buildLocationLabel(input: SiteType): string {
    const parts = [input.city, input.state].filter(part => Boolean(part));

    return parts.length === 0 ? 'LOCATION UNSPECIFIED' : parts.join(', ');
  }

  static getDerivedStateFromProps(props: PropsType): Object {
    const {sites} = props;

    const siteLocationMap = {};

    sites.forEach(site => {
      const locationLabel = ManageSites.buildLocationLabel(site);
      const sortedSite = {
        id: site.id,
        name: site.name,
        systemCount: site.systemCount,
        address: buildCondensedAddress(site)
      };

      if (Array.isArray(siteLocationMap[locationLabel])) {
        siteLocationMap[locationLabel].push(sortedSite);
      } else {
        siteLocationMap[locationLabel] = [sortedSite];
      }
    });

    const sortedLocations = Object.keys(siteLocationMap).sort();
    
    return {
      sortedSites: sortedLocations.map(location => ({
        locationLabel: location,
        sites: siteLocationMap[location]
      }))
    };
  }

  doRefresh = async () => {
    await siteService.refreshSites();
  };

  componentDidMount() {
    this.doRefresh();
  }

  openAddSite = () => {
    this.setState({
      addSiteOpen: true
    });
  };

  closeAddSite = () => {
    this.doRefresh();
    this.setState({
      addSiteOpen: false
    });
  };

  render() {
    const {sites, userIsOrgAdmin} = this.props;
    const {sortedSites, addSiteOpen} = this.state;

    const numOfSites = sites.length;
    const s = numOfSites === 1 ? '' : 's';

    return (
      <GeneralPage
        title="Manage Sites"
        className="manage-sites"
        contentClassName="manage-sites-content grid-x grid-padding-x"
        subText={`Total of ${numOfSites} site${s}`}
      >
        <AddSiteModel
          isOpen={addSiteOpen}
          onRequestClose={this.closeAddSite}
          onSuccess={this.closeAddSite}
        />
        <div className="cell large-3 large-offset-9 medium-4 medium-offset-8 small-6 small-offset-6 btn-cell">
        {userIsOrgAdmin ? 
        <Button
            isHollow
            isExpanded
            className="new-site-btn text-uppercase"
            onClick={this.openAddSite}
          >
            Add New Site
          </Button> : null }
        </div>
        {sortedSites.length === 0 ? (
          <div className="cell small-12">
            <GeneralPageEmptyMessage>
              <div>Your company does not have any sites.</div>
              <div>
                Please go to Add New Site or ask another company to grant you
                access to one.
              </div>
            </GeneralPageEmptyMessage>
          </div>
        ) : null}
        {sortedSites.map(siteGroup => (
          <div className="cell small-12" key={siteGroup.locationLabel}>
            <SortedSiteGroup siteGroup={siteGroup} />
          </div>
        ))}
      </GeneralPage>
    );
  }
}

export default watch(ManageSites, {
  sites: 'sites',
  userIsOrgAdmin: 'sessionInfo.userIsOrgAdmin'
});

// @flow

import React, { Component } from 'react';

import { Button } from 'react-foundation';

import TitledModal from '../general-components/titled-modal';
import siteSvc from './site-service';
import Radio from '../general-components/radio';

import type { SiteType, SiteOrgType, SystemType } from '../types';


import './add-system-modal.css';

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onSuccess: Function,
  site: SiteType,
  // Eslint didn't see that this is used to derive state in a static method.
  // eslint-disable-next-line react/no-unused-prop-types
  allSystems: SystemType[],
  owner: ?SiteOrgType,
  partner: ?SiteOrgType
};

type StateType = {
  currentStep: number,
  selectedSystemId: String,
  busy: boolean
};

const STARTING_STATE = {
  currentStep: 1,
  selectedSystemId: undefined,
  busy: false
};

export default class AddSystemModal extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      ...STARTING_STATE
    };
  }

  onSelectSystem = (value) => {
    this.setState({
      selectedSystemId: value
    });
  };

  doCancel = () => {
    const { onRequestClose } = this.props;
    this.setState({
      ...STARTING_STATE
    });
    onRequestClose();
  };

  goToStep = (step: number) => {
    this.setState({
      currentStep: step
    });
  };

  addSystems = async () => {
    const { site, onSuccess } = this.props;
    const { selectedSystemId } = this.state;

    this.setState({
      busy: true
    });
    if (selectedSystemId) {
      await siteSvc.addSystems(site.id, [selectedSystemId]);
    }
    this.setState({
      ...STARTING_STATE
    });

    onSuccess();
  };

  render() {
    const { isOpen, site, owner, partner, userSiteOrg, eligibleSystems } = this.props;

    const {
      currentStep,
      busy,
      selectedSystemId
    } = this.state;
    const selectedSystem = selectedSystemId ? eligibleSystems.find(sys => sys.id === selectedSystemId) : undefined;
    const selectedSysPartner = selectedSystem && selectedSystem.organizations ?
      selectedSystem.organizations.filter(org => org.type === 'Partner') : undefined;
      const selectedSysOwner = selectedSystem && selectedSystem.organizations ?
      selectedSystem.organizations.find(org => org.type === 'Owner') : undefined;
      const selectedSysOwnerName = selectedSysOwner && selectedSysOwner.name ? selectedSysOwner.name : 'UNKNOWN';
    const selectedSystemName = selectedSystem && selectedSystem.name ? selectedSystem.name : 'UNKNOWN';
    const siteName = site && site.name ? site.name : 'UNKNOWN';
    const ownerName = owner && owner.name ? owner.name : 'UNKNOWN';
    const userSiteOrgName = userSiteOrg && userSiteOrg.name ? userSiteOrg.name : 'UNKNOWN';

    return (
      <TitledModal
        title="Add Systems To Site"
        isOpen={isOpen}
        closeDisabled={busy}
        onRequestClose={this.doCancel}
        className="add-systems-modal"
      >
        {currentStep === 1 ? (
          <div className="grid-x grid-padding-x step-1">
            <div className="cell small-12 instructions">
              <p>
                Select a system to add to site <strong>{siteName}</strong>:
              </p>
            </div>
            <div className="cell small-12">
              <fieldset className="system-radio-section">
                <ul className="system-radio-list">
                  {eligibleSystems.map(sys => (
                    <li key={sys.id}>
                      <Radio
                        label={sys.name}
                        name={sys.name}
                        value={sys.id}
                        className="keep-rel-radio"
                        inputClassName="keep-rel-yes"
                        labelClassName="partner-radio"
                        checked={selectedSystemId === sys.id}
                        onChange={this.onSelectSystem}
                      />
                    </li>
                  ))}
                </ul>
              </fieldset>
            </div>
            <div className="cell small-12 buttons">
              <div className="grid-x grid-padding-x">
                <div className="cell small-6 medium-3 medium-offset-3">
                  <Button
                    className="text-uppercase cancel-btn"
                    color="black"
                    disabled={busy}
                    isExpanded
                    isHollow
                    onClick={this.doCancel}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="cell small-6 medium-3">
                  <Button
                    disabled={busy || !selectedSystem}
                    isExpanded
                    isHollow
                    className="text-uppercase add-btn"
                    color="black"
                    onClick={this.goToStep.bind(this, 2)}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : currentStep === 2 ? (
          <div className="grid-x grid-padding-x step-2">
            <div className="cell small-12 text-cell instructions">
              <p>
                <strong>{userSiteOrgName}</strong> is adding <strong>{selectedSystemName}</strong> to site <strong>{siteName}</strong>.
              </p>
            </div>
            <div className="cell small-12 text-cell instructions">
              <p>
                <strong>{selectedSystemName}</strong>‘s system access configuration is:
              </p>
              <div className="cell small-11 small-offset-1">
                <p>System Owner:<span className='sys-org-align'><strong>{selectedSysOwnerName}</strong></span></p>
                {selectedSysPartner && selectedSysPartner.length > 0 && selectedSysPartner.map(org => <p>Access Partner:<span className='sys-org-align'><strong>{org.name}</strong></span></p>)}
              </div>
            </div>
            <div className="cell small-12 text-cell instructions">
              <p>
                <strong>{siteName}‘</strong>s site access configuration is:
              </p>
              <div className="cell small-11 small-offset-1">
                <p>Site Owner:<span className='site-owner-align'><strong>{ownerName}</strong></span></p>
                {partner && partner.length > 0 && partner.map(org => <p>Access Partner:<span className='sys-org-align'><strong>{org.name}</strong></span></p>)}
              </div>
            </div>
            <div className="cell small-12 text-cell instructions">
              <p>When <strong>{selectedSystemName}</strong> is added to <strong>{siteName}</strong> the system’s
                access configuration will be changed to match the site’s access configuration.</p>
            </div>
            <div className="cell small-12 text-cell instructions">
              <p>
                Are you sure you want to add <strong>{selectedSystemName}</strong> to <strong>{siteName}</strong>?.
              </p>
            </div>
            <div className="cell small-12 divider-cell" />
            <div className="cell small-6 medium-3 medium-offset-3 text-right">
              <Button
                className="text-uppercase cancel-btn"
                color="black"
                disabled={busy}
                isExpanded
                isHollow
                onClick={this.doCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="cell small-6 medium-3 text-left">
              <Button
                disabled={busy}
                isExpanded
                isHollow
                className="text-uppercase ok-btn"
                color="black"
                onClick={this.addSystems}
              >
                OK
              </Button>
            </div>
          </div>
        ) : (
          <div>We seem to have encountered an error</div>
        )}
      </TitledModal>
    );
  }
}

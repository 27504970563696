// @flow

import React, {Component} from 'react';
import {dispatchSet} from 'redux-easy';

import AddSiteStep1Modal from './add-site-step1-modal';
import AddSiteStep2Modal from './add-site-step2-modal';

type PropsType = {
  isOpen: boolean,
  onRequestClose: Function,
  onSuccess: Function
};

type StateType = {
  nameErrors: string[],
  atStepOne: boolean
};

const STARTING_STATE = {
  nameErrors: [],
  atStepOne: true
};

export default class AddSiteModal extends Component<PropsType, StateType> {
  constructor() {
    super();
    this.state = {
      ...STARTING_STATE
    };
  }

  reset = () => {
    this.setState({
      ...STARTING_STATE
    });
    dispatchSet('forms.addSite', {
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: 'US',
      phone: '',
      email: '',
      website: ''
    });
  };

  nameValid = () => {
    this.setState({
      nameErrors: [],
      atStepOne: false
    });
  };

  nameHasErrors = (errorMessage: string) => {
    this.setState({
      nameErrors: [errorMessage],
      atStepOne: true
    });
  };

  returnToStepOne = () => {
    this.setState({
      atStepOne: true
    });
  };

  siteAdded = () => {
    const {onSuccess} = this.props;
    this.reset();
    onSuccess();
  };

  requestClose = () => {
    const {onRequestClose} = this.props;
    this.reset();
    onRequestClose();
  };

  render() {
    const {isOpen} = this.props;
    const {nameErrors, atStepOne} = this.state;

    const stepOneOpen = isOpen && atStepOne;
    const stepTwoOpen = isOpen && !atStepOne;

    return (
      <div className="add-site-modal-container">
        <AddSiteStep1Modal
          isOpen={stepOneOpen}
          nameErrors={nameErrors}
          onRequestClose={this.requestClose}
          onSuccess={this.nameValid}
          onNameError={this.nameHasErrors}
        />
        <AddSiteStep2Modal
          isOpen={stepTwoOpen}
          onRequestClose={this.requestClose}
          onSuccess={this.siteAdded}
          onNameError={this.nameHasErrors}
          onBackToStepOne={this.returnToStepOne}
        />
      </div>
    );
  }
}

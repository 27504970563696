// @flow

import React, {Component} from 'react';

import CollapsibleGridContainer from '../general-components/collapsible-grid-container';
import CollapsibleGrid from '../general-components/collapsible-grid';

import SortedSiteRow from './sorted-site-row';

import type {SortedSiteGroupType} from './manage-sites';
// eslint-disable-next-line no-duplicate-imports
import type {ColumnDescriptorType} from '../general-components/collapsible-grid';

import './sorted-site-group.css';

type PropsType = {
  siteGroup: SortedSiteGroupType
};

const COLUMNS: ColumnDescriptorType[] = [
  {
    label: 'Site Name',
    size: 3,
    className: 'site-name-header',
    dataClassName: 'site-name'
  },
  {
    label: 'Site Systems',
    size: 2,
    className: 'system-count-header',
    dataClassName: 'system-count'
  },
  {
    label: 'Site Address',
    size: 5,
    className: 'address-header',
    dataClassName: 'address'
  },
  {
    label: '',
    size: 2,
    className: 'edit-btn-header',
    dataClassName: 'edit-btn'
  }
];

export default class SortedSiteGroup extends Component<PropsType> {
  render() {
    const {siteGroup} = this.props;

    return (
      <div className="sorted-site-group">
        <div className="location-label">{siteGroup.locationLabel}</div>
        <CollapsibleGridContainer className="sorted-site-group-sites">
          <CollapsibleGrid className="sorted-site-group-grid" columns={COLUMNS}>
            {siteGroup.sites.map(site => (
              <SortedSiteRow key={site.id} columns={COLUMNS} site={site} />
            ))}
          </CollapsibleGrid>
        </CollapsibleGridContainer>
      </div>
    );
  }
}

//@flow

type MaybeStringType = string | typeof undefined | null;

export function buildClassName(...inputs: MaybeStringType[]): string {
  const definedNames = inputs.filter(input => Boolean(input));

  return definedNames.length > 0 ? definedNames.join(' ') : '';
}

export function cleanProps(props: Object, propsToRemove: string[]): Object {
  const result = {
    ...props
  };

  propsToRemove.forEach(propName => {
    delete result[propName];
  });

  return result;
}

export type HasPartialAddressType = {
  address1: ?string,
  address2: ?string,
  city: ?string,
  state: ?string,
  zipcode: ?string
};

export function joinExistingThings(things: any[], separator: string): string {
  return things.filter(thing => Boolean(thing)).join(separator);
}

export function buildCondensedAddress(input: HasPartialAddressType): string {
  const parts = [
    input.address1,
    input.address2,
    input.city ? `${input.city},` : null,
    input.state,
    input.zipcode
  ];

  return joinExistingThings(parts, ' ');
}

// @flow

import type {StateType} from './types';

const TESTING = false;

const initialState: StateType = {
  errors: new Set(),
  sessionInfo: {
    userLoggedIn: false,
    userHasOrg: false,
    userIsOrgAdmin: false,
    userIsOrgServicer: false,
    userHasTempOrgRelationship: false
  },
  forms: {
    createOrg: {
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: 'US',
      email: '',
      phone: '',
      website: ''
    },
    inviteUserToOrg: {
      emailToInvite: '',
      inviteAsSpectator: true,
      inviteAsServicer: false,
      inviteAsAdmin: false
    },
    createAccount: {
      acceptEula: false,
      confirmEmail: '',
      confirmPassword: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      phone: ''
    },
    editOrgAccount: {
      id: 0,
      hasOrganization: false,
      base: {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      },
      organization: {
        id: 0,
        isSpectator: false,
        isServicer: false,
        isAdmin: false
      }
    },
    editProfile: {
      id: 0,
      hasOrganization: false,
      base: {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      },
      organization: {
        id: 0,
        isSpectator: false,
        isServicer: false,
        isAdmin: false
      },
      notificationPreferences: {}
    },
    changePassword: {
      currentPassword: '',
      password: '',
      confirmPassword: ''
    },
    smsPhone: {
      phoneNumber: '',
      verificationCode: ''
    },
    editSite: {
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: 'US',
      phone: '',
      email: '',
      website: ''
    },
    addSite: {
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: 'US',
      phone: '',
      email: '',
      website: ''
    }
  },
  registrationCode: [],
  notifications: [],
  unreadNotifications: 0,
  selectedSystem: {},
  selectedSystemInvitations: [],
  storeName: '',
  systems: [],
  systemsBySite: {
    siteSystems: [],
    noSiteSystems: []
  },
  ui: {
    confirmed: false,
    hash: '',
    linkExpired: false,
    shouldShowNotificationToast: true,
    systemsFilterName: 'all',
    sitesFilterName: 'all',    
    recaptchaSiteKey: 'NOT LOADED',
    buildid: 'Loading...',
    buildInfoLoaded: false
  },
  user: {
    acceptEula: false,
    active: false,
    authenticated: false,
    confirmEmail: '',
    confirmPassword: '',
    email: TESTING ? 'test@test.test' : '',
    firstName: '',
    id: 0,
    lastName: '',
    organization: null,
    password: TESTING ? 'testtest' : '',
    phone: '',
    smsPhoneNumber: null,
    smsVerified: false,
    lastViewedNotifications: new Date(0)
  },
  userPreferences: {
    userId: -1,
    temperatureDisplayUnit: 'F',
    notifications: {
      email: {
        enabled: false,
        systemEvent: {
          enabled: false,
          delayMinutes: 0
        },
        summary: {
          timezone: 'UTC'
        },
        dailySummary: {
          enabled: false,
          startingHour: 0
        },
        weeklySummary: {
          enabled: false,
          startingDay: 0,
          startingHour: 0
        }
      },
      sms: {
        enabled: false,
        systemEvent: {
          enabled: false,
          delayMinutes: 0
        }
      },
      application: {
        enabled: false,
        systemEvent: {
          enabled: false,
          delayMinutes: 0
        }
      }
    }
  },
  orgMembers: [],
  orgInvitations: [],
  orgStats: {
    userCount: 0,
    siteCount: 0,
    systemCount: 0
  },
  createdUser: null,
  toasts: [],
  sites: [],
  selectedSite: {
    id: -1,
    name: '',
    systemCount: 0,
    organizations: []
  },
  selectedSiteInvites: [],
  selectedSiteSystems: []
};

export default initialState;

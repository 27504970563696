// @flow

import React, {Component} from 'react';
import {Button} from 'react-foundation';
import {dispatch} from 'redux-easy';

import TitledPanel from '../general-components/titled-panel';
import LabelledCell from '../general-components/labelled-cell';
import TransferOwnerModal from './transfer-owner-modal';
import SwapSystemModal from './swap-system-modal';
import sysSvc from './system-service';

import {getDate} from '../util/moment-util';
import {buildCondensedAddress} from '../util/component-util';

import type {
  SystemType,
  SystemOrgRelType,
  SystemInvitationType,
  QuestionableSystemOrgRelNameType
} from '../types';

import './system-profile-owner-panel.css';

type PropsType = {
  system: SystemType,
  owner: ?SystemOrgRelType,
  partner: ?SystemOrgRelType,
  partnerInvite: ?SystemInvitationType,
  ownerInvite: ?SystemInvitationType,
  userRelType: QuestionableSystemOrgRelNameType,
  onInformationChanged: Function
};

type MyStateType = {
  transferOwnerOpen: boolean,
  swapOwnerOpen: boolean,
  cancelOwnerInvitationBusy: boolean
};

class SystemProfileOwnerPanel extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      transferOwnerOpen: false,
      swapOwnerOpen: false,
      cancelOwnerInvitationBusy: false
    };
  }

  cancelOwnerInvitation = async () => {
    const {system, onInformationChanged} = this.props;
    if (this.props.ownerInvite && this.props.ownerInvite.id) {
      this.setState({
        cancelOwnerInvitationBusy: true
      });
      await sysSvc.cancelSystemInvite(system.id, this.props.ownerInvite.id);
      this.setState({
        cancelOwnerInvitationBusy: false,
      });
      onInformationChanged();
    }
  };

  openTransferOwnerModalConfirm = () => {
    this.setState({
      transferOwnerOpen: true
    });
  };

  toggleTransferOwnerModal = () => {
    this.setState({
      transferOwnerOpen: !this.state.transferOwnerOpen
    });
  };

  transferOwnerComplete = () => {
    const {onInformationChanged} = this.props;
    this.setState({
      transferOwnerOpen: false
    });
    onInformationChanged();
  };

  openSwapOwnerModalConfirm = () => {
    this.setState({
      swapOwnerOpen: true
    });
  };

  cancelSwapOwnerModal = () => {
    this.setState({
      swapOwnerOpen: false
    });
  };

  doSwapOwnerComplete = async (result) => {
    const { onInformationChanged} = this.props;

    if (!result.success) {
      const message = result.message ?
        result.message :
        'Unable to perform swap operation.';
      dispatch('addErrorToast', message);
    }

    this.setState({
      swapOwnerOpen: false
    });

    onInformationChanged();
  };

  render() {
    const {
      owner,
      partner,
      partnerInvite,
      ownerInvite,
      userRelType,
      system
    } = this.props;
    const {
      transferOwnerOpen,
      swapOwnerOpen,
      cancelOwnerInvitationBusy   
    } = this.state;

    const ownerToDisplay = owner ?
      owner :
      {
        name: 'unknown',
        email: '',
        phone: ''
      };
      const ownerInviteStatus = ownerInvite &&  ownerInvite.swapRelationships ? 'Swap Pending' : 'Transfer Pending';
      const ownerInviteCancelButton = ownerInvite &&  ownerInvite.swapRelationships ? 'Cancel Swap Request' : 'Cancel Transfer Request';
      const OwnerInviteeOrgName = ownerInvite &&  ownerInvite.swapRelationships ? 'Owner Swap Requested' : 'New Owner Requested';

    const activeInvite = Boolean(ownerInvite);
    const transferOwnerDisabled = partnerInvite.length > 0 || activeInvite || Boolean(system.site);
    const swapOwnerDisabled = transferOwnerDisabled || !partner.length > 0 || Boolean(system.site);
    const cancelInvitationButtonDisabled =  cancelOwnerInvitationBusy || Boolean(system.site);
    const invitaionExpirationDisplay =
      ownerInvite && ownerInvite.invitationExpires ?
        getDate(ownerInvite.invitationExpires).format('MM/DD/YYYY hh:mm a') :
        '';

    const userIsOwner = userRelType === 'Owner';

    const title = 'System Owner';

    return (
      <TitledPanel title={title} className="system-profile-owner-panel">

        <TransferOwnerModal
          isOpen={transferOwnerOpen}
          onRequestClose={this.toggleTransferOwnerModal}
          onTransferSuccess={this.transferOwnerComplete}
          systemId={system.id}
          systemName={system.name}
          partner={partner}
          owner={owner}
        />
        <SwapSystemModal
          isOpen={swapOwnerOpen}
          onRequestClose={this.cancelSwapOwnerModal}
          onSuccess={this.doSwapOwnerComplete}
          system={system}
          owner={owner}
          partner={partner}
        />
        <div>
          <div className="grid-x grid-padding-x top-part">
           <div className="cell small-12 medium-4 text-left">
             <LabelledCell label="Owner">
                {ownerToDisplay.name}
              </LabelledCell>
            </div>
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label="Email">{ownerToDisplay.email}</LabelledCell>
            </div>
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label="Website">{ownerToDisplay.website}</LabelledCell>
            </div>
            <div className="cell small-12 medium-4 text-left">
                <LabelledCell label="Address">{buildCondensedAddress(ownerToDisplay)}</LabelledCell>
            </div>
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label="Phone">{ownerToDisplay.phone}</LabelledCell>
            </div>
            </div>
            { activeInvite ? (            
            <div className="grid-x grid-padding-x bottom-part">
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label={OwnerInviteeOrgName}>
                {ownerInvite.inviteeOrgName}
              </LabelledCell>
            </div>
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label="Email">{ownerInvite.inviteeEmail}</LabelledCell>
            </div>
            <div className="cell small-12 medium-4"></div>
            <div className="cell small-12 medium-4 text-left">
              <LabelledCell label="status">{ownerInviteStatus}</LabelledCell>
            </div>
            <div className="cell small-12 medium-4">
                <LabelledCell label="Expires On">
                  {invitaionExpirationDisplay}
                </LabelledCell>
            </div>
            {userIsOwner ? (
              <div className="cell small-12 medium-4">
                <div className="partner-buttons">
                    <Button
                      isHollow
                      isExpanded
                      color="little-blue"
                      className="text-uppercase"
                      onClick={this.cancelOwnerInvitation}
                      disabled={cancelInvitationButtonDisabled}
                    >
                      {ownerInviteCancelButton}
                    </Button>
                </div>    
              </div> 
                ) : null} 
            </div>
             ) : null }
            {userIsOwner ? (
            <div className="grid-x grid-padding-x bottom-part">
              <div className="cell small-12 medium-4">
                <LabelledCell label="">
                  <Button
                    isHollow
                    isExpanded
                    color="little-blue"
                    className="swap-owner-btn text-uppercase"
                    disabled={swapOwnerDisabled}
                    onClick={this.openSwapOwnerModalConfirm}
                  >
                    Swap Owner
                  </Button>
                </LabelledCell>
              </div>              
              <div className="cell small-12 medium-4">
                <LabelledCell label="">
                  <Button
                    isHollow
                    isExpanded
                    color="little-blue"
                    className="transfer-owner-btn text-uppercase"
                    disabled={transferOwnerDisabled}
                    onClick={this.openTransferOwnerModalConfirm}
                  >
                    Transfer Owner
                  </Button>
                </LabelledCell>
              </div>
            </div>
           ) : null} 
        </div>
      </TitledPanel>
    );
  }
}

export default SystemProfileOwnerPanel;

// @flow

import React, {Component} from 'react';
import TitledPanel from './titled-panel';
import Modal from 'react-modal';

import './titled-modal.css';

type PropsType = {
  title: string,
  isOpen: boolean,
  closeDisabled?: ?boolean,
  onRequestClose?: ?Function,
  className?: string,
  contentClassName?: string
};

class TitledModal extends Component<PropsType> {
  internalRequestClose = () => {
    const {closeDisabled, onRequestClose} = this.props;

    if (!closeDisabled && typeof onRequestClose === 'function') {
      onRequestClose();
    }
  };

  render() {
    // ESLint and Flow really don't like me using the 'children'
    // property.
    /* eslint-disable react/prop-types */
    // $FlowFixMe -- props.children is provided by React
    const {children} = this.props;
    const {title, isOpen, className, contentClassName} = this.props;

    const modalClassName =
      'titled-modal reveal' + (className ? ` ${className}` : '');
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.internalRequestClose}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
        overlayClassName="reveal-overlay"
        className={modalClassName}
      >
        <TitledPanel title={title} contentClassName={contentClassName}>
          {children}
        </TitledPanel>
      </Modal>
    );
  }
}

export default TitledModal;

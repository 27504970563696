// @flow

import React, {Component} from 'react';
import Modal from 'react-modal';
import {HalfCircleSpinner} from 'react-epic-spinners';
import {dispatch, watch} from 'redux-easy';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import WizardBox from '../general-components/wizard-box';
import PinInput from '../digit-input/pin-input';
import CaptchaCatcher from '../captcha/captcha-catcher';

import sysSvc from './system-service';

import './register-system-modal.css';

import closeIcon from './images/close-register-modal-icon.svg';
import invalidIcon from './images/register-modal-error-icon.svg';
import exclamationIcon from '@fortawesome/fontawesome-free-solid/faExclamationTriangle';

type PropsType = {
  isOpen: boolean,
  onRequestClose?: ?Function,
  onSuccess?: ?Function,
  registrationCode: number[]
};

type MyStateType = {
  busy: boolean,
  pinIncorrect: boolean,
  errorMessage: string,
  captchaOpen: boolean
};

class RegisterSystemModal extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      busy: false,
      pinIncorrect: false,
      errorMessage: '',
      captchaOpen: false
    };
  }

  componentDidMount() {
    const {registrationCode} = this.props;
    this.validatePin(registrationCode);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {registrationCode} = nextProps;
    this.validatePin(registrationCode);
  }

  validatePin(registrationCode) {
    const validNums = registrationCode.filter(
      num => typeof num === 'number' && num >= 0 && num < 10
    );

    if (validNums.length === 6) {
      this.captureCaptcha();
    }
  }

  captureCaptcha = () => {
    this.setState({
      captchaOpen: true
    });
  };

  captchaSuccess = async captchaToken => {
    const {registrationCode} = this.props;
    this.setState({
      captchaOpen: false
    });
    await this.claimSystem(registrationCode.join(''), captchaToken);
  };

  captchaFailed = message => {
    this.setState({
      captchaOpen: false,
      errorMessage: message
    });

    dispatch('clearRegistrationCode');
  };

  async claimSystem(pin, captchaToken) {
    const {onSuccess} = this.props;

    this.setState({
      busy: true,
      errorMessage: ''
    });

    const result = await sysSvc.claimSystem(pin, captchaToken);

    this.setState({
      busy: false,
      pinIncorrect: !result.successful,
      errorMessage: result.message
    });

    dispatch('clearRegistrationCode');
    if (result.successful && onSuccess) {
      onSuccess();
    }
  }

  render() {
    const {isOpen, onRequestClose} = this.props;

    const {busy, pinIncorrect, errorMessage, captchaOpen} = this.state;

    const safeRequestClose = onRequestClose || (() => {});

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={safeRequestClose}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
        overlayClassName="reveal-overlay"
        className="register-system-modal reveal full"
      >
        <CaptchaCatcher
          isOpen={captchaOpen}
          onSuccess={this.captchaSuccess}
          onFail={this.captchaFailed}
        />
        <WizardBox className="register-system-wizard-box">
          <div className="grid-container fluid">
            <div className="grid-x align-center">
              <div className="cell small-12 top">
                <div className="close-icon">
                  <a onClick={onRequestClose}>
                    <img src={closeIcon} alt="Close" />
                  </a>
                </div>
              </div>
              <div className="cell small-12 title">Register New System</div>
              <div className="cell small-12 instructions">
                <p>
                  Enter the 6-digit registration PIN generated on the system’s
                  evaporator:
                </p>
              </div>
              <div className="cell small-12 digits">
                <PinInput disabled={busy} invalid={pinIncorrect} />
                {pinIncorrect ? (
                  <div className="invalid-icon">
                    <img src={invalidIcon} alt="PIN Invalid" />
                  </div>
                ) : null}
              </div>
              <div className="cell small-12 error-box">
                {busy ? (
                  <div className="spinner-container">
                    <HalfCircleSpinner
                      className="spinner"
                      size={50}
                      color="#ffffff"
                    />
                  </div>
                ) : null}
                {errorMessage ? (
                  <div className="register-system-error callout">
                    <div className="error-container">
                      <div className="error-icon">
                        <FontAwesomeIcon icon={exclamationIcon} size="2x" />
                      </div>
                      <div className="error-message">{errorMessage}</div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="cell small-12 help">
                <p>
                  If you have any issues, call us at{' '}
                  <span className="text-not-as-light-blue">
                    <strong>(800) 537-7775</strong>
                  </span>{' '}
                  or visit us at
                  <br />
                  <a
                    href="https://www.intelligencontrols.com/homepage/resources"
                    target="_support"
                  >
                    <strong>intelliGencontrols.com</strong>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </WizardBox>
      </Modal>
    );
  }
}

export default watch(RegisterSystemModal, {
  registrationCode: ''
});

// @flow

import React, {Component} from 'react';
import {watch, dispatch} from 'redux-easy';

import LabelledInput from '../labelled-input/labelled-input';
import MarketingBox from '../general-components/marketing-box';
import {Button} from 'react-foundation';
import {changeRoute} from '../route';
import type {UserType} from '../types';
import {fetchJson, userUrl} from '../util/rest-util';
import {checkEmail} from '../user/user-validate';

import './forgot-password.css';

type PropsType = {
  user: UserType
};

type MyStateType = {
  errors: string[]
};

class ForgotPassword extends Component<PropsType, MyStateType> {
  constructor() {
    super();
    this.state = {
      errors: []
    };
  }

  onKeyPress = event => {
    const isEnter = event.key === 'Enter' || event.keyCode === 13;
    if (isEnter) this.onSendEmail();
  };

  validate = email => {
    this.setState({
      errors: checkEmail(email)
    });
  };

  componentDidMount() {
    const {email} = this.props.user;
    this.validate(email);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {email} = nextProps.user;
    this.validate(email);
  }

  /**
   * Sends a email to the user containing a magic link
   * that can be clicked to change their password.
   */
  onSendEmail = async () => {
    // Prepare to send request for magic link email.
    const {user} = this.props;
    const {email} = user;

    // The UI doesn't allow onSend to be called
    // when no email address is entered,
    // so we can't cover this in a test.
    if (!email) return;

    const options = {
      method: 'POST',
      body: JSON.stringify({email})
    };

    try {
      // Send request for magic link email.
      const url = userUrl('users/forgotPassword');
      const res = await fetchJson(url, options);
      const {status} = res;
      if (status === 204) {
        dispatch('addToast', {
          type: 'successs',
          title: 'Email Sent',
          message:
            'Reset Link sent in email.'
        });
      } else if (status === 404) {
        this.setState({
          errors: [
            'This Email Address is not associated with any intelliGen™ Account.'
          ]
        });
      } else {
        dispatch('addToast', {
          type: 'error',
          title: 'Error',
          message:
            'Unable to proceed.  Please check your email address and try again.'
        });
      }
    } catch (e) {
      // istanbul ignore next
      console.error('forgot-password: onSendEmail: e =', e);
    }
  };

  render() {
    const {errors} = this.state;
    const {user} = this.props;
    const inputInvalid = errors.length > 0;
    const sendEnabled = Boolean(user.email) && !inputInvalid;
    const sendBtnClasses = 'send-btn text-uppercase';

    const links = [
      {
        label: 'Create Account',
        route: 'create-account'
      },
      {
        label: 'Log In',
        route: 'login'
      }
    ];

    return (
      <MarketingBox className="forgot-password" links={links}>
        <div className="marketing-left">
          <h6 className="page-heading">
            <strong>Did you forget your password?</strong>
          </h6>
          <div className="forgot-instructions">
            Enter your Email Address to receive a link to reset your password.
          </div>
          <div className="inputs">
            <LabelledInput
              autoFocus
              className="email-input"
              label="EMAIL*"
              onKeyPress={this.onKeyPress}
              path="user.email"
              value={user.email}
              isInvalid={inputInvalid}
              maxLength={50}
            />
          </div>
          <div className="having-trouble">
            Having trouble? Contact{' '}
            <a
              href="https://www.intelligencontrols.com/homepage/resources"
              target="_support"
            >
              Customer Support
            </a>
            .
          </div>
          <div className="button-box">
            <Button
              className={sendBtnClasses}
              disabled={!sendEnabled}
              onClick={this.onSendEmail}
              color="light-blue"
              isHollow
            >
              Recover
            </Button>
          </div>
          <div className="error-box">
            {[...errors].map(msg => (
              <div className="error text-alert" key={msg}>
                <strong>{msg}</strong>
              </div>
            ))}
          </div>
        </div>
        <div className="marketing-spacer" />
        <div className="marketing-right" />
      </MarketingBox>
    );
  }
}

export default watch(ForgotPassword, {
  user: 'user'
});

// @flow

import React, {Component} from 'react';

import './wizard-box.css';

type PropsType = {
  className: ?string,
  id?: ?string
};

class WizardBox extends Component<PropsType> {
  render() {
    // ESLint and Flow really don't like me using the 'children'
    // property.
    /* eslint-disable react/prop-types */
    // $FlowFixMe -- props.children is provided by React
    const {id, children} = this.props;
    const extraClass = this.props.className ? ` ${this.props.className}` : '';

    return (
      <div id={id} className="wizard-box">
        <div className={`wizard-box-content${extraClass}`}>{children}</div>
      </div>
    );
  }
}

export default WizardBox;

// @flow

import React, {Component} from 'react';
import {dispatchSet, watch} from 'redux-easy';

import {changeRoute} from '../route';
import TopNav from '../top-nav/top-nav';

import sysSvc from '../systems/system-service';

import './system-detail.css';

import type {SystemType, UserType} from '../types';

type PropsType = {
  system: ?SystemType,
  user: UserType
};

class SystemDetail extends Component<PropsType> {
  componentWillUnmount() {
    dispatchSet('selectedSystem', {});
  }

  render() {
    const {system, user} = this.props;

    if (!user.authenticated) return changeRoute('login');

    if (!system) return null;

    const iframeUrl = sysSvc.getSystemUrl(system);

    return (
      <div className="system-detail">
        <TopNav />
        <div className="proxy-frame-container">
          <iframe
            className="proxy-frame"
            src={iframeUrl}
            title="system detail"
          />
        </div>
      </div>
    );
  }
}

export default watch(SystemDetail, {
  system: 'selectedSystem',
  user: ''
});

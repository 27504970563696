// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';
import Modal from 'react-modal';
import {HalfCircleSpinner} from 'react-epic-spinners';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import readyIcon from '@fortawesome/fontawesome-free-solid/faUserSecret';

import './captcha-catcher.css';

type PropsType = {
  isOpen: boolean,
  siteKey: string,
  buildInfoLoaded: boolean,
  onFail: Function,
  onSuccess: Function
};

type StateType = {
  status: string,
  isLoaded: boolean,
  isReady: boolean,
  inProgress: boolean,
  widgetId: number
};

const INIT_POLL_TIME = 500;
const INIT_POLL_ATTEMPTS = 10;
const INITIAL_STATE = {
  status: 'Uninitialized',
  isLoaded: false,
  isReady: false,
  inProgress: false,
  widgetId: -1
};

const ELEMENT_ID = 'recaptcha-container';

export class CaptchaCatcher extends Component<PropsType, StateType> {
  pollAttempts: number;
  grecaptcha: any;

  constructor(props: PropsType) {
    super(props);
    this.pollAttempts = 0;
    this.grecaptcha = null;
    this.state = {
      ...INITIAL_STATE
    };
  }

  componentDidMount() {
    this.initialize();
  }

  onSuccessfulResponse = (captchaToken: string) => {
    const {onSuccess} = this.props;
    onSuccess(captchaToken);
    this.setState({
      status: 'Success!',
      inProgress: false
    });
  };

  onExpired = () => {
    this.props.onFail('Captcha Expired');
    this.setState({
      status: 'Error',
      inProgress: false
    });
  };

  onError = () => {
    this.props.onFail('Captcha unexpectedly failed.  Please try again.');
    this.setState({
      status: 'Error',
      inProgress: false
    });
  };

  onReady = () => {
    const {onFail, siteKey} = this.props;

    try {
      const widgetId = this.grecaptcha.render(ELEMENT_ID, {
        sitekey: siteKey,
        size: 'invisible',
        callback: this.onSuccessfulResponse,
        'expired-callback': this.onExpired,
        'error-callback': this.onError
      });
      this.setState({
        status: 'Ready',
        isReady: true,
        widgetId
      });
    } catch (e) {
      this.setState({
        status: 'Error'
      });
      onFail(e.toString());
    }
  };

  doExecute = () => {
    const {widgetId} = this.state;

    this.setState({
      status: 'Checking...',
      inProgress: true
    });

    this.grecaptcha.execute(widgetId);
  };

  doReset = () => {
    const {widgetId, isReady} = this.state;

    if (this.grecaptcha) this.grecaptcha.reset(widgetId);

    if (isReady) {
      this.setState({
        status: 'Ready'
      });
    }

    this.setState({
      inProgress: false
    });
  };

  componentDidUpdate(prevProps: PropsType) {
    const {isOpen: wasOpen} = prevProps;
    const {isOpen} = this.props;
    const {isReady, inProgress, widgetId} = this.state;

    if (wasOpen && !isOpen) {
      this.doReset();
    } else if (isOpen && isReady && widgetId > -1 && !inProgress) {
      this.doExecute();
    }
  }

  initialize = () => {
    const {onFail, buildInfoLoaded} = this.props;
    this.pollAttempts = this.pollAttempts + 1;
    this.setState({
      status: 'Initializing...',
      isLoaded: false,
      isReady: false,
      inProgress: false
    });
    if (window.RECAPTCHA_LOADED && buildInfoLoaded) {
      this.grecaptcha = window.grecaptcha;
      this.setState({
        isLoaded: true
      });
      this.grecaptcha.ready(this.onReady);
    } else if (this.pollAttempts < INIT_POLL_ATTEMPTS) {
      setTimeout(this.initialize, INIT_POLL_TIME);
    } else {
      this.setState({
        status: 'Error',
        isLoaded: false,
        isReady: false,
        inProgress: false
      });
      onFail('Unable to load RE-CAPTCHA');
    }
  };

  internalCancelClose = () => {
    const {onFail} = this.props;

    onFail('Captcha Cancelled');
  };

  render() {
    const {isOpen} = this.props;
    const {status, isLoaded, isReady, inProgress} = this.state;

    return (
      <div className="captcha-catcher-container">
        <Modal
          isOpen={isOpen}
          onRequestClose={this.internalCancelClose}
          shouldCloseOnOverlayClick={false}
          shouldFocusAfterRender={false}
          overlayClassName="reveal-overlay"
          className="captcha-catcher reveal"
        >
          {!isLoaded || !isReady || inProgress ? (
            <div className="spinner-container">
              <HalfCircleSpinner
                className="spinner"
                size={50}
                color="#4a4a4a"
              />
            </div>
          ) : (
            <div className="spinner-container">
              <FontAwesomeIcon className="ready-icon" icon={readyIcon} />
            </div>
          )}
          <div className="captcha-status-container">
            <div className="captcha-status">{status}</div>
          </div>
        </Modal>
        <div id={ELEMENT_ID} />
      </div>
    );
  }
}

export default watch(CaptchaCatcher, {
  buildInfoLoaded: 'ui.buildInfoLoaded',
  siteKey: 'ui.recaptchaSiteKey'
});

// @flow

import React, {Component} from 'react';
import {watch} from 'redux-easy';

import './build-info.css';

type PropsType = {
  buildid: string
};

class BuildInfo extends Component<PropsType> {
  render() {
    const {buildid} = this.props;
    return <div className="build-info">{buildid}</div>;
  }
}

export default watch(BuildInfo, {
  buildid: 'ui.buildid'
});
